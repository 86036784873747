import { Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-fee-payment-widget',
  templateUrl: './fee-payment-widget.component.html',
  styleUrls: ['./fee-payment-widget.component.scss']
})
export class FeePaymentWidgetComponent implements OnInit,DoCheck {
  paymentDetails: any;
  isLeasedSchool: any;
  IsHybrid:any;
  today = new Date();
day:number;
count=0;
  // Extract day, month, and year components
  isDayGreaterThan10:boolean=false;
  currency: string;
  constructor(private dashboardService: DashboardService,public router:Router) { }
  ngDoCheck(): void {
    
    let data:any = localStorage.getItem('leasemodelschool')
   this.currency= localStorage.getItem('bsu_currency')
    this.isLeasedSchool = data
    if(this.isLeasedSchool == 0 && this.count==0){
      this.getPaymentStatusDetails();
      this.count++;
    }
  }

  ngOnInit(): void {
    this.day = this.today.getDate();
    this.IsHybrid=localStorage.getItem('IsHybrid');
    if(this.day>10){
      this.isDayGreaterThan10=true;
    }
    else
    {
      this.isDayGreaterThan10=false;
    }
    
  }

  gotoFee()
  {
    this.router.navigate(['/payment-detail'])
  }
  getPaymentStatusDetails() {
    
    let data: any = {};
    setTimeout(() => {                           //<<<---using ()=> syntax
     
    let studentDetails = JSON.parse(localStorage.getItem('parentChildrens'))
    data = {
      StudentNo: studentDetails[0]?.studentId,
      SchoolId: studentDetails[0]?.bsuId,
    }

    this.dashboardService.getPaymentStatusDetails(data).subscribe((res: any) => {
      this.paymentDetails = res?.data;
      let content= this.paymentDetails.length == 0 ? false :true;
      this.dashboardService.isPaymentContent.next(content)
    })
    }, 1000);
    
  }

  
}
