import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class encryptionService {
  private IV = CryptoJS.enc.Hex.parse('1234567890ABCDEF');
  private SALT = CryptoJS.enc.Hex.parse('4976616E204D65647665646576');
  private SHA256_key = '12341544566467678798791237868947';

  constructor() { }

  // DES Encryption
  encryptDES(message: string, key: string = '!#$a54?W'): string {
    const keyHex = CryptoJS.enc.Utf8.parse(key.substring(0, 8));
    const encrypted = CryptoJS.DES.encrypt(message, keyHex, { iv: this.IV });
    return encrypted.toString();
  }

  // DES Decryption
  decryptDES(ciphertext: string, key: string = '!#$a54?W'): string {
    const keyHex = CryptoJS.enc.Utf8.parse(key.substring(0, 8));
    const decrypted = CryptoJS.DES.decrypt(ciphertext, keyHex, { iv: this.IV });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  // SHA-512 Hashing
  hashSHA512(message: string): string {
    return CryptoJS.SHA512(message).toString(CryptoJS.enc.Hex);
  }

  // AES Encryption
  encryptAES(textToEncrypt: string): string {
    const keyBytes = CryptoJS.enc.Hex.parse(this.SHA256_key);
    const dataBytes = CryptoJS.enc.Utf8.parse(textToEncrypt);
    const encrypted = CryptoJS.AES.encrypt(dataBytes, keyBytes, { iv: this.deriveKey(), mode: CryptoJS.mode.CBC });
    const resultBytes = this.IV.concat(encrypted.ciphertext);
    return CryptoJS.enc.Base64.stringify(resultBytes);
  }

  // AES Decryption
  decryptAES(cipherText: string): string {
    const keyBytes = CryptoJS.enc.Hex.parse(this.SHA256_key);
    const cipherBytes = CryptoJS.enc.Base64.parse(cipherText);
    const initVector = CryptoJS.lib.WordArray.create(cipherBytes.words.slice(0, 4));
    const encryptedData = CryptoJS.lib.WordArray.create(cipherBytes.words.slice(4));
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedData }, keyBytes, { iv: initVector, mode: CryptoJS.mode.CBC });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  private deriveKey(): CryptoJS.lib.WordArray {
    const keyBytes = CryptoJS.enc.Hex.parse(this.SHA256_key);
    const key = CryptoJS.PBKDF2(keyBytes.toString(CryptoJS.enc.Hex), this.SALT, { keySize: 128 / 32, iterations: 10 });
    return CryptoJS.lib.WordArray.create(key.words.slice(0, 4));
  }
}