import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer,SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { PaymentService } from 'src/app/service/payment.service';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {
  displayedColumns = ['receiptDate', 'status', 'refNo','recNo', 'decription', 'debit'];
  fromdate: string;
  todate: string;
  historyList: any;
  studentId: any;
  dataSource: any;
  recDialog: any;
  htmlContent: any;
  content: SafeHtml;

  constructor(private dialog: MatDialog,private datePipe: DatePipe, private paymentService:PaymentService, private router:ActivatedRoute,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    let date= new Date();
    date = new Date(date.getFullYear(), new Date(date).getMonth(), new Date(date).getDate() - 90);
    this.fromdate = this.datePipe.transform(new Date(date), 'yyyy-MM-dd');
    this.todate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.router.queryParams.subscribe(params=>{
      this.studentId = params?.id
    })
    this.getHistoryDetails()
  }
  changeServiceDate(event, value) {
    let serviceDate = event.value._d;
    let data: any;
    if (value == 'fdate') {
      this.fromdate = this.datePipe.transform(serviceDate, 'yyyy-MM-dd');
    } else {
      this.todate = this.datePipe.transform(serviceDate, 'yyyy-MM-dd');
    }
  }

  clear() {
    this.todate = '';
    this.fromdate = '';
  }

  getReceipt(recNo:any,template:any)
  {
    let data={
      receiptNo:recNo,
      studNo:this.studentId
    }
    this.paymentService.getReceipt(data).subscribe(res=>{
      this.htmlContent=res?.data
      this.receiptDialog(template);
    })
  }

  get sanitizedPdfHtml(): SafeHtml { 
    this.content=this.sanitizer.bypassSecurityTrustHtml(this.htmlContent ?? '');
    return this.content }

  receiptDialog(template: TemplateRef<any>) {
    this.recDialog= this.dialog.open(template, {
       disableClose: true,
       width: '700px',
       panelClass: 'custom-modalbox'
     });
   }

  getHistoryDetails(){
    let data:any;
    data={
     stuid:this.studentId,
     fromDate:this.fromdate,
     toDate:this.todate
    }
     this.paymentService.getPaymentHistory(data).subscribe(
       res=>{
         this.historyList = res;
         this.dataSource = this.historyList;
         console.log('dataSource', this.dataSource)
       }
     )
   }
}
