import { Component, OnInit } from '@angular/core';
import { SupportService } from 'src/app/service/support.service';

@Component({
  selector: 'app-contact-sde',
  templateUrl: './contact-sde.component.html',
  styleUrls: ['./contact-sde.component.scss']
})
export class ContactSdeComponent implements OnInit {
  contactDetails: any;
  primaryContact: string;
  contactBusDetails: any;
  busContact: string;

  constructor(private supportService:SupportService) { }

  ngOnInit(): void {
    this.supportService.getSDEContact().subscribe(
      (res:any)=>{
        this.contactDetails = res?.data;
        this.primaryContact = [this.contactDetails.mobileNumber.slice(0, 3)+" "+this.contactDetails.mobileNumber.slice(3,5) +" "+this.contactDetails.mobileNumber.slice(5,8)+" "+this.contactDetails.mobileNumber.slice(8,12)].join('');
        this.supportService.getBusContact().subscribe((resp:any)=>{
          this.contactBusDetails = resp?.data[0];
          if((this.contactBusDetails.diverMobile.length >= 12 && (this.contactBusDetails.diverMobile.slice(0, 3) == "971")) || (this.contactBusDetails.diverMobile.length >= 11 && (this.contactBusDetails.diverMobile.slice(0, 3) == "974")))
          {
            this.busContact = "+"+[this.contactBusDetails.diverMobile.slice(0, 3)+" "+this.contactBusDetails.diverMobile.slice(3,5) +" "+this.contactBusDetails.diverMobile.slice(5,8)+" "+this.contactBusDetails.diverMobile.slice(8,12)].join('');
          }
          else{
            this.busContact = this.contactBusDetails.diverMobile
            }
        })
      }
    )
  }
 
}
