import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingRoutingModule } from './landing-page-routing.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from "@angular/material/icon";
import { CarouselModule } from 'ngx-owl-carousel-o';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// import { ChatModule } from '@progress/kendo-angular-conversational-ui';
// register Swiper custom elements
register();

@NgModule({
  declarations: [],
  exports: [LandingRoutingModule],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    CarouselModule,
    MatSnackBarModule,
    Ng2TelInputModule,
    NgxMatIntlTelInputModule,
    // ChatModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LandingModule { }
