import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { HomeService } from 'src/app/service/home.service';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { TokenService } from 'src/app/service/token.service';

@Component({
  selector: 'app-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss']
})
export class NewsWidgetComponent implements OnInit, AfterViewInit {
  @ViewChild('owlElement') owlElement:any;
  carouselVisible: boolean = true;
  latestNews: any[] = [];
  defaultToken: any;
  constructor(public service:HomeService,public tokenService:TokenService) { }

  ngOnInit(): void {
    this.getToken();
  }
  carouselOptions: OwlOptions = {
    loop: false,
    items: 2,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    margin: 20,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      400: {
        items: 2,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      740: {
        items: 2,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      940: {
        items: 2,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      }
    },
    nav: false
  };
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateCarousel();
    }, 1000);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void{
    this.updateCarousel();
  }
  updateCarousel() {
    if (this.owlElement) {
      this.carouselVisible = false;
      setTimeout(() => {
        this.carouselVisible = true;
      }, 0)
    }
  }

// -------------------------------------------------------------------------------

  getToken() {
    this.tokenService.GetToken(null).subscribe(
      res => {
        this.defaultToken = res?.access_token;
        this.getLatestNews();
      })
    }
  getLatestNews() {
    this.service.getNews(this.defaultToken).subscribe(
      res => {
        if(res.length <=3)
        this.latestNews = res
        else this.latestNews =res.slice(0,3)
      })
  }

  // carouselOffersOptions: OwlOptions = {
  //   stagePadding: 170,
  //   loop:false,
  //   margin:15,
  //   nav:false,
  //   dots: true,
  //   items:2,
  //   responsive: {
  //     0: {
  //       items: 1
  //     },
  //     768: {
  //       items: 2,
  //       stagePadding: 0,
  //       loop: false
  //     },
  //     992: {
  //       items: 2,
  //       stagePadding: 0,
  //       loop: false
  //     },
  //     1024: {
  //       items: 2,
  //       stagePadding: 0,
  //       loop: false
  //     },
  //     1300: {
  //       items: 2,
  //       stagePadding: 170,
  //       loop: false
  //     },
  //   }
  // };

  // customOptions: OwlOptions = {
  //   loop: false,
  //   mouseDrag: true,
  //   touchDrag: true,
  //   pullDrag: true,
  //   dots: true,
  //   margin: 20,
  //   navSpeed: 700,
  //   responsive: {
  //     0: {
  //       items: 1,
  //       mouseDrag: true,
  //       touchDrag: true,
  //       pullDrag: true,
  //     },
  //     400: {
  //       items: 2,
  //       mouseDrag: true,
  //       touchDrag: true,
  //       pullDrag: true,
  //     },
  //     740: {
  //       items: 2,
  //       mouseDrag: true,
  //       touchDrag: true,
  //       pullDrag: true,
  //     },
  //     940: {
  //       items: 2,
  //       mouseDrag: true,
  //       touchDrag: true,
  //       pullDrag: true,
  //     }
  //   },
  //   nav: false
  // }

  redirectUrl(url:any)
  {
    if(url)
    window.open(url, '_blank')
  }
}
