import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-terms-and-conditions',
  templateUrl: './app-terms-and-conditions.component.html',
  styleUrls: ['./app-terms-and-conditions.component.scss']
})
export class AppTermsAndConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
