import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SupportService } from 'src/app/service/support.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  inProgressList: any[]=[];
  closeList: any[]=[];
  feedbackList: any[]=[];
  digopen: any;
  Imagename: any;
  ext: string;
  isDataNotAvailable: boolean = false;

  constructor( private router: Router, private supportService:SupportService, private _sanitizer: DomSanitizer, private dialog:MatDialog) { }

  ngOnInit(): void {
    
    this.getProgressDetails()
    this.getCloseDetails()

    setTimeout(() => {                           //<<<---using ()=> syntax
      if (this.feedbackList.length == 0) {
        this.isDataNotAvailable = true;
      }
    }, 3000);
  }
  navigatetolink(lnk) {
    this.router.navigate([lnk]);
  }
 getProgressDetails(){
  this.supportService.getFeedbackList('-3').subscribe(res=>{
    this.inProgressList = res?.data;
    if(res?.data == null){
      this.inProgressList = []
    }
    console.log(this.inProgressList) 
    
    this.inProgressList.forEach(ele=>{
      ele.status = "Inprogress"
    })
    this.getFeedbackList();
  })
 

  let array :any[]=[];
  console.log(array);
 }
 getCloseDetails(){
  this.supportService.getFeedbackList('-4').subscribe(res=>{
    this.closeList = res?.data;
    if(res?.data == null){
      this.closeList = []
    }
    console.log(this.closeList) 
    
    this.closeList.forEach(ele=>{
      ele.status = "Closed"
    })
    this.getFeedbackList();
  })
 }
 getFeedbackList(){
  this.feedbackList=[];
  this.feedbackList=[...this.inProgressList,...this.closeList];

  //console.log(this.feedbackList);
  // this.feedbackList.forEach(ele=>{
  //   ele.image_data = this.getImage(ele.image_data)
  // });
  // console.log(this.feedbackList);
  
 }
getImage(image){
  
 // imagedata = image.split("''");
  return btoa(image.toString());
  console.log();
  
}
public b64toBlob(b64Data, contentType) {
  contentType = contentType || '';
  let sliceSize = 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
getPdf(base64:any,extension:any) {
  var blob = this.b64toBlob(base64, extension);
  let a = document.createElement("a");
  document.body.appendChild(a);
  var url = window.URL.createObjectURL(blob);
  window.open(url, '_blank')
}
openDialog(templateRef: TemplateRef<any>, imagename) 
{
  this.ext=imagename.imgname.split('.')
  this.Imagename='';
  this.Imagename = imagename.image_data
  if(this.ext[1] != "pdf" && this.ext[1] != "doc" && this.ext[1] != "docx")
  {
  this.digopen = this.dialog.open(templateRef, {
    width: '500px',
    panelClass: 'custom-modalbox'
  });
}else{
  let extension=''
  if(this.ext[1] == "doc")
  extension="application/msword"
  else if(this.ext[1] == "docx")
  extension="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  else extension="application/pdf"
  this.getPdf(this.Imagename,extension);
}
}
}
