export class PreLoginSupport {
    constructor(
        public dtime: string = '',
        public details: string = '',
        public imgdata: any = '',
        public imgname: any = '',
        public imgtype: any = '',
        public email: string = '',
        
        public titleID: any = null,
        public studentNo: string = "0",

        public refID: any = 1,
        public comments: any = null,
        public contactID: any = 2,
        public feedbackID: any = null,
        public smiley: any = null
    ) { }
} 

export class ErrorSupportForm{
    constructor(
        public IsDescription :boolean=false,
        public IsTitleID:boolean=false,
        public IsEmail:boolean=false
    ){}
}