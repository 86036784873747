import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ConfigService } from './config.service';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private http: HttpClient, private configService:ConfigService) { }
  isNotifications:Subject<any>=new Subject()
  getToken()
  {
    let token: any;
    token = JSON.parse(localStorage.getItem('currentToken'))
   return token?.access_token;
  }
  getUsername()
  {
    let username:any;
    username=localStorage.getItem('parent')
    return username;
  }
  getNotifications()
  {
   const header = {Authorization:`Bearer ${this.getToken()}`,Parentid:this.getUsername(),Source: this.configService.source,language:"en"};
   return this.http.get(`${this.configService.baseUrl}/api/Notification/PARENT_NOTIFICATIONS`,{headers:header});
  }
  postNotifications(data)
  {
   const header = {Authorization:`Bearer ${this.getToken()}`,language:"en",source:this.configService.source};
   return this.http.post(`${this.configService.baseUrl}/api/Notification/NOTIFICATION_UPDATE`,data,{headers:header});
  }
  getCount(){
    const header = {Authorization:`Bearer ${this.getToken()}`,Parentid:this.getUsername(),Source: this.configService.source, type:''};
   return this.http.get(`${this.configService.baseUrl}/api/Notification/GetNotificationCount`,{headers:header});
  }
}
