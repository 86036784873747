import { AfterContentInit, AfterViewInit, Component, DoCheck, ElementRef, Inject, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ReregistrationService } from 'src/app/service/reregistration.service';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { RegistrationService } from 'src/app/service/registration.service';
import { ConfigService } from 'src/app/service/config.service';
import { MessageComponent } from 'src/app/message/message.component';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ServiceinfoService } from 'src/app/service';
import { DashboardService } from 'src/app/service/dashboard.service';
import { forkJoin } from 'rxjs';
import { TransportserviceService } from 'src/app/transportservice.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-auto-promotion',
  templateUrl: './auto-promotion.component.html',
  styleUrls: ['./auto-promotion.component.scss']
})

export class AutoPromotionComponent implements OnInit {
  constructor(private dialog: MatDialog, private router: Router, public reRegService: ReregistrationService, private configService: ConfigService,
    private sanitizer: DomSanitizer, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private registrationService: RegistrationService, private transportInfoService: TransportserviceService, private serviceinfoService: ServiceinfoService, private dashboardService: DashboardService) { }

  defaultToken: any;
  pdfContent: any;
  pdfHtml: string;
  contentTitle: string;
  schoolDetails: any;
  city: any;
  disableSave: boolean = true;
  contentViewer: any;
  isTerms: boolean = false;
  isParentTaking: boolean = false;
  isBusBehavior: boolean = false;
  showParentUndertaking: boolean = false;
  isLeasedSchool: boolean = false;
  isHybridSchool: boolean = false;
  enableAgreeTemplate: boolean = false;

  textPdf: any = '';
  textPdf2: any = '';
  textPdf3: any = '';
  textPdf4: any = '';
  textPdf5: any = '';
  textPdf6: any = '';
  textPdf7: any = '';
  textPdf8: any = '';
  textPdf9: any = '';
  sign: any = '';
  sign2: any = '';
  sign3: any = '';
  sign4: any = '';
  sign5: any = '';
  checkbox1: boolean = false;
  checkbox2: boolean = false;

  @ViewChild('pdf') pdf: ElementRef;
  @ViewChild('header') header: ElementRef;
  @ViewChild('headerLogo') headerLogo: ElementRef;
  @ViewChild('signature') signature: ElementRef;
  pdfType: string = '';
  stuSchoolId: string = '';
  studentData: any;
  PaymentRefNo: any;
  PaymentRedirectURL: any;
  paymentTypeID: any;
  transportTypes: any[] = [];
  autoPromotionForm: any[] = [];
  checkedStudentNo: any;
  termsAndConditionArr: any[] = [];
  autoPromoDetails: any;
  dataPopulated: boolean = false;
  isGrade: boolean = false;

  ngOnInit(): void {
    this.defaultToken = localStorage.getItem('currentToken');

    this.renderer.addClass(this.document.body, 'rm-body-height');
    var root = document.getElementsByTagName('html')[0];
    this.renderer.addClass(root, 'rm-body-height');

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    let bsuId = localStorage.getItem("bsU_ID");
    let schoolList = JSON.parse(localStorage.getItem("schoolList"))

    this.schoolDetails = schoolList.find(x => x.bsU_ID == bsuId);
    this.city = this.schoolDetails?.city;

    this.isLeasedSchool = this.schoolDetails?.isLeasedSchool;
    this.isHybridSchool = this.schoolDetails?.ishybrid;

    let selectedStudentId = localStorage.getItem('selectedstudentid');

    this.stuSchoolId = selectedStudentId;

    this.getSiblingDetails();

  }

  getSiblingDetails() {

    this.dashboardService.getAutoPromotionDetails().subscribe((autoRes: any) => {
      if (autoRes?.data?.length > 0) {
        this.autoPromoDetails = autoRes?.data;
        let studentIds = autoRes?.data?.map((y: any) => y.stU_NO);

        if (studentIds?.length > 0) {
          // let obj1: any = {
          //   headers: {
          //     "master": "TPT_TYPE",
          //     "bsuid": localStorage.getItem("bsU_ID"),
          //     "Authorization": "Bearer " + this.defaultToken
          //   }
          // }

          // this.serviceinfoService.getMaster(obj1).subscribe(ttRes => {

          //   this.transportTypes = ttRes["data"]?.filter(x => x.id != -1).map(x => ({ descr: x.descr, id: x.id.toString() }));
          //   this.transportTypes.unshift({
          //     descr: 'Select',
          //     id: null
          //   });
          // });

          this.transportInfoService.getTransportInfoOfChildren().subscribe((res: any) => {
            this.termsAndConditionArr = [];

            res.data = res.data.filter((x: any) => studentIds.includes(x.studentNo));

            this.studentData = res.data;
            res.data?.forEach((data: any) => {
              let pdfs = this.autoPromoDetails.filter((x:any) => x.stU_NO == data.studentNo);

              let isTerms = false, isTermsAgreed = false;
              let isBBC = false, isBBCAgreed = false;
              let isPUT = false, isPUTAgreed = false, bShowSiblingInput = false;

              let termsAgree = autoRes.data.filter((x: any) => x.stU_NO == data.studentNo && x.doctype == "TNC");

              if (termsAgree?.length > 0) {
                isTerms = true
              } else { isTerms = false; isTermsAgreed = true; }

              let bbcAgree = autoRes.data.filter((x: any) => x.stU_NO == data.studentNo && x.doctype == "BBC");

              if (bbcAgree?.length > 0) {
                isBBC = true
              } else { isBBC = false; isBBCAgreed = true; }

              let putAgree = autoRes.data.filter((x: any) => x.stU_NO == data.studentNo && x.doctype == "PUT");
              if (putAgree?.length > 0) {
                isPUT = true;
                bShowSiblingInput = putAgree[0]?.bShowSiblingInput == 1 ? true : false;
              } else {
                isPUT = false;
                isPUTAgreed = true;
              }

              if (this.autoPromotionForm.indexOf((x: any) => x.studentNo == data.studentNo) == -1) {
                this.autoPromotionForm.push({
                  studentPhoto: data.photoPath,
                  studentName: data.studentName,
                  studentNo: data.studentNo,
                  stuSchoolId: data.stU_SCHOOL_ID,
                  schoolName: data.school,
                  studentGrade: data.grade,
                  nextYearAcdId: data.acdid,
                  pickupArea: data.pickupLocation, // this.pickupdropdata(data.stU_PICKUP_DROPOFF_LOCATION, 'pickuparea'),
                  pickupPoint: data.pickuppoint, // this.pickupdropdata(data.stU_PICKUP_DROPOFF_POINT, 'pickuppoint'),
                  dropoffArea: data.dropOffLocation, // this.pickupdropdata(data.stU_PICKUP_DROPOFF_LOCATION, 'dropoffarea'),
                  dropoffPoint: data.dropoffpoint, // this.pickupdropdata(data.stU_PICKUP_DROPOFF_POINT, 'dropoffpoint'),
                  transportType: data.servicetype, // this.transportTypes.find(x => x.id == data.reT_ROUNDTRIP)?.descr,
                  serviceStartDate: data.fromDate,
                  isPUTAvailable: isPUT,
                  isTermsAvailable: isTerms,
                  isBBCAvailable: isBBC,
                  isPUTAgreed: isPUTAgreed,
                  isTermsAgreed: isTermsAgreed,
                  isBBCAgreed: isBBCAgreed,
                  pdfs: pdfs,
                  bShowSiblingInput: bShowSiblingInput
                });
              }
            });
            this.dataPopulated = true;
          });
        }
      }
    });
  }

  pickupdropdata(value, type) {
    value = value.split('/');
    if (type == 'pickuparea') {
      return value[0]
    }
    if (type == 'dropoffarea') {
      return value[1]
    }
    if (type == 'pickuppoint') {
      return value[0]
    }
    if (type == 'dropoffpoint') {
      return value[1]
    }
  }

  checked(isChecked: any, type: any, formData:any) {
    this.checkedStudentNo = formData?.studentNo;

    if(isChecked){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

    if (type == 'PUT') {
      formData.isPUTAgreed = isChecked;
      if (isChecked) {
        this.isGrade = formData?.bShowSiblingInput;

        let primaryContactName = localStorage.getItem('primaryContactName')
        let payload = {
          stuNo: formData?.studentNo,
          oluName: primaryContactName
        }

        this.registrationService.getParentUndertakingData(this.defaultToken, payload).subscribe((content: any) => {
          let data: any
          if (content.data?.ttcD_DATA) {
            data = JSON.parse(content.data?.ttcD_DATA);
          }

          this.textPdf = data?.input1 ? data?.input1 : primaryContactName;
          this.textPdf2 = data?.input2 ? data?.input2 : formData?.stU_NAME;
          this.textPdf3 = data?.input3 ? data?.input3 : formData?.studentNo;
          this.textPdf4 = data?.input4 ? data?.input4 : formData?.studentGrade;
          this.textPdf5 = data?.input5 ? data?.input5 : formData?.schoolName;
          this.textPdf6 = formData?.dropoffPoint ? formData?.dropoffPoint : '';
          this.textPdf7 = data?.input7 ? data?.input7 : '';
          this.textPdf8 = data?.input8 ? data?.input8 : '';
          this.textPdf9 = data?.input9 ? data?.input9 : '';
          this.sign = data?.sign1 ? data?.sign1 : '';
          this.sign2 = data?.sign2 ? data?.sign2 : '';
          this.sign3 = data?.sign3 ? data?.sign3 : '';
          this.sign4 = data?.sign4 ? data?.sign4 : '';
          this.sign5 = data?.sign5 ? data?.sign5 : '';
          this.checkbox1 = data?.checkbox1 ? data?.checkbox1 : false;
          this.checkbox2 = data?.checkbox2 ? data?.checkbox2 : false;

          let putObj = formData.pdfs.find((item:any) => item.doctype === "PUT");
          this.pdfHtml = putObj.pdF_HTML;
          this.contentTitle = putObj.urL_TEXT;
          this.pdfType = "PUT";
          this.enableAgreeTemplate = true;
        });
      }
    }
    else if (type == 'TERMS') {

      if(!isChecked){
        if(formData.isTermsAvailable){
          formData.isTermsAgreed = isChecked;  
        }
        if(formData.isBBCAvailable){
          formData.isBBCAgreed = isChecked;  
        }
      }

      if(isChecked){
        if(formData.isTermsAvailable){
          formData.isTermsAgreed = isChecked;
  
          let tncObj = formData.pdfs.find((item:any) => item.doctype === "TNC");
          this.pdfHtml = tncObj.pdF_HTML;
          this.contentTitle = tncObj.urL_TEXT;
          this.pdfType = "TNC";
          this.enableAgreeTemplate = true;
        }else if(formData.isBBCAvailable){
          formData.isBBCAgreed = isChecked;
  
          let bbcObj = formData.pdfs.find((item:any) => item.doctype === "BBC");
          this.pdfHtml = bbcObj.pdF_HTML;
          this.contentTitle = bbcObj.urL_TEXT;
          this.pdfType = "BBC";
          this.enableAgreeTemplate = true;
        }
      }
    }
    this.checkSubmitValidation();
  }

  get sanitizedPdfHtml(): SafeHtml {
    this.pdfContent = this.sanitizer.bypassSecurityTrustHtml(this.pdfHtml ?? '');
    return this.pdfContent
  }
  openContent(type: any, template: any, pdfs: any) {
    if (type == 't&c') {
      let tncObj = pdfs.find((item:any) => item.doctype === "TNC");
      this.pdfHtml = tncObj.pdF_HTML;
      this.contentTitle = tncObj.urL_TEXT;
    }
    else if (type == 'busbehaviour') {
      let bbcObj = pdfs.find((item:any) => item.doctype === "BBC");
      this.pdfHtml = bbcObj.pdF_HTML;
      this.contentTitle = bbcObj.urL_TEXT;
    }
    else if (type == 'parentUndertaking') {
      let putObj = pdfs.find((item:any) => item.doctype === "PUT");
      this.pdfHtml = putObj.pdF_HTML;
      this.contentTitle = putObj.urL_TEXT;
    }
    this.contentViewer = this.dialog.open(template, {
      disableClose: true,
      width: '850px',
      height: '500px',
      panelClass: ['custom-modalbox', 'rm-padding']
    });
  }
  closeContent() {
    this.contentViewer?.close();
  }

  pdfDownload() {
    var domain = this.reRegService.appUrl;
    var pdfLogo = (this.city == "Dubai" || this.city == "Qatar") ? domain + "/assets/images/sts-logo.png" : domain + "/assets/images/bbt-logo.png";
    //this.convertToBase64(pdfLogo);
    var content: any;
    var headerOption = {
      tableAutoSize: true,
      imagesByReference: true
    }
    var pdftitle = (this.city == "Dubai" || this.city == "Qatar") ? "School Transport Services LLC" : "Bright Bus Transport"
    var headerContent = `<table style="width: 100%">
      <tr>
        <td style="width:80px;height:40px;border-right:1px solid black;text-align:center"><img style="width:40px;height:40px" src=${pdfLogo}></td>
        <td style="font-weight:bold;font-size: 18px;text-align:center;margin-top:10px">${pdftitle}</td>
      </tr>
      <tr>
        <td style="border-right:1px solid #fff"></td>
      <td style="font-weight:bold;font-size: 18px;text-align:center;margin-top:5px;margin-bottom:5px;">${this.contentTitle}</td>
      </tr>
      </table>`
    if (this.header && this.header.nativeElement && this.pdfType == 'PUT') {
      content = headerContent + this.removeMetadata(this.header.nativeElement.innerHTML) + this.removeMetadata(this.pdf.nativeElement.innerHTML);

    }
    else {
      content = headerContent + this.removeMetadata(this.pdf.nativeElement.innerHTML);
    }

    var html = htmlToPdfmake(content, headerOption);
    // else var html = htmlToPdfmake(this.pdf.nativeElement.innerHTML);
    const documentDefinition = { content: html.content, images: html.images };
    pdfMake.createPdf(documentDefinition).download(this.contentTitle);
  }

  removeMetadata(html: string): string {
    const metadataRegex = /<\s*(meta|title|link|style|script)[^>]*>.*?<\/\s*\1\s*>/gi;
    const nonContentRegex = /<\s*(head|script|style)[^>]*>.*?<\/\s*\1\s*>/gi;

    html = html.replace(metadataRegex, '');
    html = html.replace(nonContentRegex, '');
    return html;
  }

  agree() {
    if (this.autoPromotionForm?.length > 0) {
      let index = this.autoPromotionForm.findIndex((x: any) => x.studentNo == this.checkedStudentNo);
      if (index != -1) {
        if (this.pdfType == "TNC") {
          this.autoPromotionForm[index].isTermsAgreed = true;

          if (this.autoPromotionForm[index].isBBCAvailable) {
            this.autoPromotionForm[index].isBBCAgreed = true;

            let bbcObj = this.autoPromotionForm[index].pdfs.find((item:any) => item.doctype === "BBC");
            this.pdfHtml = bbcObj.pdF_HTML;
            this.contentTitle = bbcObj.urL_TEXT;
            this.pdfType = "BBC";

            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }
          else {
            this.enableAgreeTemplate = false;
          }
        }
        else if (this.pdfType == "BBC") {
          this.autoPromotionForm[index].isBBCAgreed = true;
          this.enableAgreeTemplate = false;
        }
        else if (this.pdfType == "PUT") {
          let jsonstring = JSON.stringify({
            "input1": this.textPdf,
            "input2": this.textPdf2,
            "input3": this.textPdf3,
            "input4": this.textPdf4,
            "input5": this.textPdf5,
            "input6": this.textPdf6,
            "input7": this.textPdf7,
            "input8": this.textPdf8,
            "input9": this.textPdf9,
            "sign1": this.sign,
            "sign2": this.sign2,
            "sign3": this.sign3,
            "sign4": this.sign4,
            "sign5": this.sign5,
            "checkbox1": this.checkbox1,
            "checkbox2": this.checkbox2
          })
          let data = {
            "TTCUL_ID": 0,
            "OLU_NAME": localStorage.getItem('primaryContactName'),
            "STU_NO": this.autoPromotionForm[index].studentNo,
            "TTCD_DATA": jsonstring,
            "TTCD_MOBILE": localStorage.getItem("primaryMobileNo"),
            "TTCD_ACD_ID": this.autoPromotionForm[index].nextYearAcdId,
            "TTCD_SUBMIT_FROM": "ReRegistration",
            "TTCD_SOURCE": "STS_WEB"
          }
          this.registrationService.saveParentUndertakingData(this.defaultToken, data).subscribe(res => {
            this.autoPromotionForm[index].isPUTAgreed = true;
            this.enableAgreeTemplate = false;
          });
        }
      }
    }

    this.checkSubmitValidation();
  }

  checkSubmitValidation() {
    this.disableSave = false;

    this.autoPromotionForm.forEach((x:any) => {
      if(!this.disableSave){
        if (!x.isPUTAgreed || !x.isTermsAgreed || !x.isBBCAgreed) {
          this.disableSave = true;
        }
      }
    });
  }

  navigateToHome() {
    this.router.navigate(['/home']);
  }

  gotoAutoPromotion() {

    if(this.autoPromotionForm?.length > 0){
      let index = this.autoPromotionForm.findIndex((x:any) => x.studentNo == this.checkedStudentNo);

      if(index != -1){
        if (this.pdfType == "TNC" || this.pdfType == "BBC") {
          if(this.autoPromotionForm[index].isTermsAvailable){
            this.autoPromotionForm[index].isTermsAgreed = false;
          }
          if(this.autoPromotionForm[index].isBBCAvailable){
            this.autoPromotionForm[index].isBBCAgreed = false;
          }
        }
        if (this.pdfType == "PUT") {
          this.autoPromotionForm[index].isPUTAgreed = false;
        }
      }
    }
    
    this.checkSubmitValidation();
    this.enableAgreeTemplate = false;
  }

  agreeAutoPromotion() {
    let httpCalls=[];
    this.studentData.forEach((ele:any) => {
      let autoPromo = this.autoPromoDetails?.filter((x:any) => x.stU_NO == ele.studentNo);

      if(autoPromo != null){
        let applicableDocs = autoPromo.map((x:any) => x.doctype).join(',');
        let tncPath = autoPromo.find((m:any) => m.doctype === "TNC")?.urL_PATH;
        let bbcPath = autoPromo.find((m:any) => m.doctype === "BBC")?.urL_PATH;

        let bsuId = ele.studentNo.substring(0,6);
        let tncPayload: any;
        tncPayload = {
          "STNC_BSU_ID": bsuId,
          "STNC_ACD_ID": ele.acdid,
          "STNC_SOURCE": "AUTOPROMOTION",
          "STNC_REF_ID": autoPromo[0]?.ssV_ID,
          "STNC_APPLICABLE_DOCS": applicableDocs,
          "STNC_TTC_ID": autoPromo[0]?.ttC_ID ?? 0,
          "STNC_STU_NO": ele.studentNo,
          "STNC_TNC_PATH": tncPath != "" ? tncPath : "",
          "STNC_BBC_PATH": bbcPath != "" ? bbcPath : "",
          "STNC_REQUEST_SOURCE": "STS_WEB"
        };

        httpCalls.push(this.reRegService.agreeAutoPromotion(tncPayload));
      }

    });

    if (httpCalls.length > 0) {
      forkJoin(httpCalls).subscribe((res: any) => {
        let autoPromoSuccess = true;
        res.forEach(obj => {
          if (obj.success !== "true") {
            autoPromoSuccess = false;
            this.openMessageDialog(obj.message, true, '');
          }
        });

        if (autoPromoSuccess) {
          if (!this.isLeasedSchool && !this.isHybridSchool)
            this.goToPaymentPage();
          else
            this.navigateToHome();
        }
      });
    }
  }

  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  goToPaymentPage() {
    this.router.navigate(['/payment-detail']);
  }

  goToLanding() {
    this.router.navigate(['/']);
  }
}
