import { Component, OnInit, HostListener, TemplateRef, ViewChild, ElementRef, Pipe, Renderer2, ViewChildren, QueryList } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../service/home.service';
import { Observable, ReplaySubject } from 'rxjs';
import { TokenService } from '../service/token.service';
import { ErrorSupportForm, PreLoginSupport } from '../models/landing.model';
import { DatePipe } from '@angular/common';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {
  MatDialog, MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { TransportScheduleChangeWidgetComponent } from '../features/home/dashboard-widgets/transport-schedule-change-widget/transport-schedule-change-widget.component';
import { FormControl, FormGroup } from 'ngx-typesafe-forms';
import { requestCallback } from '../models/requestCallback.model';
import { Validators } from '@angular/forms';
import { MessageComponent } from '../message/message.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { RegistrationService } from '../service/registration.service';
import { ServiceinfoService } from '../service';
import { ConfigService } from '../service/config.service';
import { ChatMessage } from '../models/chatbot.model';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  providers: [DatePipe]
})
@Pipe({
  name: 'safeHtml'
})
export class LandingPageComponent implements OnInit {
  @ViewChild('requestCallback', { static: false }) requestCallback: TemplateRef<any>;
  name = 'Angular 6';
  public selected = false;
  openchatBot: any;
  public sections = 4;

  public scroll;

  digopen: any;
  digSucessopen: any;
  mailErr: boolean = false;
  carouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    autoWidth: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 400,
    nav: true,
    navText: ['<div class="left-nav"><img src="assets/images/landing-page/left-arrow-carousel.svg"/></div>', '<div class="right-nav"><img src="assets/images/landing-page/right-arrow-carousel.svg"/></div>'],
    center: false,
    startPosition: 0,
    items: 2.50,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2.50,
      },
      992: {
        items: 2.50
      },
      1200: {
        items: 2.50
      },
    }
  };
  carouselNewsOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    autoWidth: true,
    pullDrag: false,
    dots: false,
    navSpeed: 400,
    nav: true,
    navText: ['<div class="left-nav"><img src="assets/images/landing-page/left-arrow-white.svg"/></div>', '<div class="right-nav"><img src="assets/images/landing-page/right-arrow-white.svg"/></div>'],
    center: false,
    startPosition: 0,
    items: 2.50,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2.50,
      },
      992: {
        items: 2.50
      },
      1200: {
        items: 2.50
      },
    }
  };
  carouselOffersOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoWidth: true,
    dots: false,
    navSpeed: 400,
    nav: true,
    navText: ['<div class="left-nav"><img src="assets/images/landing-page/left-arrow-white.svg"/></div>', '<div class="right-nav"><img src="assets/images/landing-page/right-arrow-white.svg"/></div>'],
    center: false,
    startPosition: 0,
    items: 2.50,
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      768: {
        items: 2.50,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      992: {
        items: 2.50,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      1024: {
        items: 2.50,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      1200: {
        items: 2.50,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
    }
  };

  carouselserviceOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    autoWidth: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 400,
    nav: false,
    navText: ['<div class="left-nav"><img src="assets/images/landing-page/left-arrow-white.svg"/></div>', '<div class="right-nav"><img src="assets/images/landing-page/right-arrow-white.svg"/></div>'],
    center: false,
    startPosition: 0,
    items: 2.50,
    stagePadding: 150,
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        autoWidth: true,
        touchDrag: true,
        pullDrag: true,
      },
      768: {
        items: 2,
        stagePadding: 0,
        loop: false,
        mouseDrag: true,
        autoWidth: true,
        touchDrag: true,
        pullDrag: true,
      },
      992: {
        items: 2,
        stagePadding: 0,
        loop: false,
        mouseDrag: true,
        autoWidth: true,
        touchDrag: true,
        pullDrag: true,
      },
      1024: {
        items: 2,
        stagePadding: 0,
        loop: false,
        mouseDrag: true,
        autoWidth: true,
        touchDrag: true,
        pullDrag: true,
      },
      1200: {
        items: 2,
        stagePadding: 0,
        loop: false,
        mouseDrag: true,
        autoWidth: true,
        touchDrag: true,
        pullDrag: true,
      },
      1400: {
        items: 2,
        stagePadding: 0,
        loop: false,
        autoWidth: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
    }
  };

  carouselLocationOptions: OwlOptions;

  carouselServiceOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 400,
    autoWidth: true,
    nav: false,
    navText: ['<div class="left-nav"><img src="assets/images/landing-page/left-arrow-white.svg"/></div>', '<div class="right-nav"><img src="assets/images/landing-page/right-arrow-white.svg"/></div>'],
    center: false,
    startPosition: 0,
    items: 1,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 1
      },
      992: {
        items: 1
      },
      1024: {
        items: 1
      },
      1200: {
        items: 1
      },
    }
  };
  products: any[] = [
    { class: 'red' },
    { class: 'green' },
    { class: 'blue' },
    { class: 'pink' },
    { class: 'gray' },
    { class: 'violet' },
    { class: 'brown' },
  ];
  offersImage: any[] = [];
  latestNews: any[] = [];
  defaultToken: any = '';
  postSupportData: PreLoginSupport = new PreLoginSupport();
  errorModel: ErrorSupportForm = new ErrorSupportForm()
  categoryList: any[] = [];
  date: any = new Date();
  imageName: any = '';
  pattern = '^[a-zA-Z]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,}$';
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  awards: any[] = [];
  accreditation: any[] = [];
  partnership: any[] = [];
  location: any[] = [];
  isPrev: boolean = true; //set to false for disable/enable function
  isNext: boolean = true;
  isNewsPrev: boolean = true; //set to false for disable/enable function
  isNewsNext: boolean = true;
  isAccPrev: boolean = true; //set to false for disable/enable function
  isAccNext: boolean = true;
  isPartnerPrev: boolean = true; //set to false for disable/enable function
  isPartnerNext: boolean = true;
  isAccreditation: boolean = false;
  isAwards: boolean = true;
  isPartnership: boolean = false;
  partnershipLoop: boolean = true;
  accLoop: boolean = true;
  awardLoop: boolean = true;
  callBackForm = new FormGroup<requestCallback>({
    name: new FormControl<string>('', [Validators.required]),
    city: new FormControl<string>('', [Validators.required]),
    description: new FormControl<string>('', [Validators.required]),
    phone: new FormControl<string>('', [Validators.required]),
    captcha: new FormControl<any>('', [Validators.required]),
  })
  count: number = 0;
  city: any[] = [];
  @ViewChild('carousel') carousel: CarouselComponent;
  @ViewChild('newsCarousel') newsCarousel: CarouselComponent;
  @ViewChild('Accreditcarousel') Accreditcarousel: CarouselComponent;
  @ViewChild('partnershipcarousel') partnershipcarousel: CarouselComponent;
  @ViewChild('sectionContainer') sectionContainer: ElementRef;
  msg: string = "";
  isError: boolean = false;
  invalidFile: boolean = false;
  invalidFileSize: boolean = false;
  mdsimage: string;
  mdsdescription: string;
  mdstitle: string;
  disableSubscribe: boolean = true;
  mail: any;
  emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  offersForm = new FormGroup<any>({
    school: new FormControl<any>('', [Validators.required]),
    name: new FormControl<any>('', [Validators.required]),
    startDate: new FormControl<any>('', [Validators.required]),
    grade: new FormControl<any>('', [Validators.required]),
    dob: new FormControl<any>('', [Validators.required]),
    schoolId: new FormControl<any>('', [Validators.required]),
    phone: new FormControl<any>('', [Validators.required]),
    parentName: new FormControl<any>('', [Validators.required]),
    area: new FormControl<any>('', [Validators.required]),
    emailId: new FormControl<any>('', [Validators.required])
  })
  nationalnumber: any;
  schoolList: any[] = [];
  gradeList: any[] = [];
  countries = ['ae', 'qa'];
  selectedCountries: string = 'ae';
  pickUpPoints: any[] = [];
  bsuId: any;
  academicYrList: any[] = [];
  startDate: any;
  acdId: any;
  maxDate: Date = new Date();
  maxDobDate:Date=new Date(new Date().getFullYear()-1, null,null);
  emailErr: boolean = false;
  schoolDropdownList: any[] = [];
  selectedOffer: any;
  isUAENoValid: boolean = false;
  isUAENoValidCallback: boolean = false;
  token: any
  dubaiCountryId = '172';
  qatarCountryId = '97';
  siteKey: any;
  templateMap: { [key: string]: TemplateRef<any> } = {};

  constructor(private homeService: HomeService, private router: Router, private tokenService: TokenService, private datePipe: DatePipe, private _snackBar: MatSnackBar,
    private dialog: MatDialog, public registerService: RegistrationService, private serviceinfoService: ServiceinfoService
    , private sanitizer: DomSanitizer, private renderer: Renderer2, private configService: ConfigService,private route:ActivatedRoute) {
    this.getToken();
  }
  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  ngOnInit() {

    localStorage.clear();
    this.postSupportData.dtime = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    this.renderer.listen(window, 'scroll', ($event) => {
      this.scroll = (window.scrollY / this.sections);
      console.log(this.scroll)
    })
    this.siteKey = this.configService.captchaAPIKey;
  }

  ngAfterViewInit() {
    this.templateMap['requestCallback'] = this.requestCallback;
    this.route.queryParams.subscribe(param=>{
      if(param['type']){
        let type=param['type'];
        let message={redirectUrl:type,redirectType:""};;
        if(type=='requestCallback'){
          message.redirectType='open-modal';
        }
        else if(type=='new-offers'){
          message.redirectType='scroll';
        }
        this.redirectEvent(message);
      }
    });
  }
  
  public navigateToSection(section: string) {
    window.location.hash = '';
    window.location.hash = section;
  }



  openDialog(template: TemplateRef<any>) {
    this.digopen = this.dialog.open(template, {
      disableClose: true,
      width: '550px',
      panelClass: ['custom-modalbox', 'rm-padding-matcontainer']
    });
  }
  successMsgPopup(template: TemplateRef<any>) {
    this.digSucessopen = this.dialog.open(template, {
      disableClose: true,
      width: '500px',
      panelClass: 'custom-modalbox'
    });
  }
  scrolled: boolean = false;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.scrolled = window.scrollY > 0;
  }
  getLatestNews() {
    this.homeService.getNews(this.defaultToken).subscribe(
      res => {
        this.latestNews = res
        // this.latestNews = [
        //   { class: 'red', title:'red' },
        //   { class: 'green',title:'green' },
        //   { class: 'blue',title:'blue' },
        //   { class: 'pink',title:'pink' },
        //   { class: 'gray',title:'pink' },
        //   { class: 'violet',title:'pink' },
        //   { class: 'brown',title:'pink' },
        // ]
      },
      (error) => {

      }
    )
  }
  goToLogin() {
    this.router.navigate(['/login']);
  }
  goToLanding() {
    this.router.navigate(['/']);
  }
  getToken() {
    this.tokenService.GetToken(null).subscribe(
      res => {
        this.defaultToken = res?.access_token;
        this.getAwards();
        this.getAccreditation();
        this.getPartnership();
        this.getLocation();
        this.getMdsMessage();
        this.getLatestNews();
        this.getCategoriesData();
        this.getCity(this.dubaiCountryId);
        this.getOffers()
      }
    )
  }
  handleFileInputChange(files: any) {
    //alert(files.target.files[0]);
    console.log(files.target.files[0]);

    let fileData: any;

    fileData = files.target.files[0];
    let type: any = '';
    type = fileData?.type.split('/')[1];
    let extName: any = '';
    extName = fileData?.name.split('.')[1];
    let filesize: any = '';
    filesize = fileData?.size;
    //alert(filesize);
    if ((type == 'png' || type == 'jpg' || type == 'jpeg') || (extName == 'doc' || extName == 'docx' || extName == 'pdf')) {
      this.invalidFile = false;

    }
    else {
      this.invalidFile = true;
      this.imageName = '';
      this.postSupportData.imgname = '';
      this.postSupportData.imgtype = '';
    }
    if (filesize <= 5242880) {
      this.invalidFileSize = false;
    } else {
      this.invalidFileSize = true;
      this.imageName = '';
      this.postSupportData.imgname = '';
      this.postSupportData.imgtype = '';
    }
    if (!this.invalidFile && !this.invalidFileSize) {
      this.imageName = fileData?.name;
      this.postSupportData.imgname = fileData?.name;
      this.postSupportData.imgtype = fileData?.type;

      this.convertFile(files.target.files[0]).subscribe(base64 => {
        this.postSupportData.imgdata = base64;
      });
    }

  }
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  getCategoriesData() {
    this.homeService.getCategoriesData(this.defaultToken).subscribe(
      res => {
        this.categoryList = res?.data[0]?.appSubDetails
      }
    )
  }

  getAwards() {
    this.homeService.getAwards(this.defaultToken).subscribe(
      res => {
        this.awards = res;
        this.awardLoop = this.awards.length == 1 ? false : true;
        this.carouselOptions2 = {
          loop: false,
          autoplay: false,
          //autoWidth:this.awardLoop,
          mouseDrag: true,
          touchDrag: true,
          pullDrag: true,
          nav: true,
          navText: ['<div class="left-nav"><img src="assets/images/landing-page/left-arrow-carousel.svg"/></div>', '<div class="right-nav"><img src="assets/images/landing-page/right-arrow-carousel.svg"/></div>'],
          dots: false,
          items: 2.5,
          stagePadding: 150,
          responsive: {
            0: {
              items: 1,
              nav: true,
              stagePadding: 0,
              autoWidth: true,
              mouseDrag: true,
              touchDrag: true,
              pullDrag: true,
            },
            600: {
              items: 2,
              stagePadding: 0,
              loop: false,
              mouseDrag: true,
              touchDrag: true,
              pullDrag: true,
            },
            1000: {
              items: 2,
              stagePadding: 150,
              loop: false,
              mouseDrag: true,
              touchDrag: true,
              pullDrag: true,
            }
          }
        };

      })
  }
  getAccreditation() {
    this.homeService.getAccreditation(this.defaultToken).subscribe(
      res => {
        this.accreditation = res;
        this.accLoop = this.accreditation.length == 1 ? false : true
        this.carouselOptions21 = {
          loop: false,
          autoplay: false,
          // autoWidth:this.accLoop,
          nav: true,
          navText: ['<div class="left-nav"><img src="assets/images/landing-page/left-arrow-carousel.svg"/></div>', '<div class="right-nav"><img src="assets/images/landing-page/right-arrow-carousel.svg"/></div>'],
          dots: false,
          items: 2.5,
          stagePadding: 150,
          responsive: {
            0: {
              items: 1,
              loop: false,
              stagePadding: 0,
              nav: true,
            },
            600: {
              items: 2,
              stagePadding: 0,
              loop: false
            },
            1000: {
              items: 2,
              stagePadding: 150,
              loop: false
            }
          }
        };
      })
  }
  getPartnership() {
    this.homeService.getPartnership(this.defaultToken).subscribe(
      res => {
        this.partnership = res;
        this.partnershipLoop = this.partnership.length == 1 ? false : true
        this.carouselOptions22 = {
          loop: false,
          autoplay: false,
          // autoWidth:this.partnershipLoop,
          nav: true,
          navText: ['<div class="left-nav"><img src="assets/images/landing-page/left-arrow-carousel.svg"/></div>', '<div class="right-nav"><img src="assets/images/landing-page/right-arrow-carousel.svg"/></div>'],
          dots: false,
          items: 2.5,
          stagePadding: 150,
          responsive: {
            0: {
              items: 1,
              loop: false,
              stagePadding: 0,
              nav: true,
            },
            600: {
              items: 2,
              stagePadding: 0,
              loop: false
            },
            1000: {
              items: 2,
              stagePadding: 150,
              loop: false
            }
          }
        };
      })
  }

  // news carousel option setting
  carouselOptions1: OwlOptions = {
    loop: false,
    autoplay: false,
    nav: true,
    navText: ['<div class="left-nav"><img src="assets/images/landing-page/left-arrow-white.svg"/></div>', '<div class="right-nav"><img src="assets/images/landing-page/right-arrow-white.svg"/></div>'],
    dots: false,
    items: 2.5,
    stagePadding: 150,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    responsive: {
      0: {
        items: 1,
        stagePadding: 30,
        nav: false,
        dots: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      600: {
        items: 2,
        stagePadding: 0,
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      1000: {
        items: 2,
        stagePadding: 150,
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      }
    }
  };

  // Location
  carouselOptionsLoc: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    autoplay: false,
    nav: false,
    dots: true,
    items: 2.5,
    stagePadding: 150,
    responsive: {
      0: {
        items: 1,
        stagePadding: 30,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      600: {
        items: 2,
        stagePadding: 0,
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      1000: {
        items: 2,
        stagePadding: 150,
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      }
    }
    // loop: false,
    // mouseDrag: false,
    // touchDrag: false,
    // pullDrag: false,
    // dots: true,
    // navSpeed: 400,
    // nav: false,
    // center: false,
    // startPosition: 0,
    // items: 2.50,
    // responsive: {
    //   0: {
    //     items: 1.25
    //   },
    //   768: {
    //     items: 2.50
    //   },
    //   992: {
    //     items: 2.50
    //   },
    //   1200: {
    //     items: 2.50
    //   },
    // }
  };

  // For News
  newsCaruoselSlide(button: any) {
    if (button == 'prev' && this.isNewsPrev) {
      this.newsCarousel.prev();
    }
    else if (button == 'next' && this.isNewsNext) {
      this.newsCarousel.next();
    }
  }

  // For Awards
  caruoselSlide(button: any) {
    if (button == 'prev' && this.isPrev) {
      this.carousel.prev();
    }
    else if (button == 'next' && this.isNext) {
      this.carousel.next();
    }
    //  setTimeout(()=>{
    //   this.caruoselEvent();
    //  },100)
  }

  // For Accreditation
  accreditCaruoselSlide(button: any) {
    if (button == 'prev' && this.isAccPrev) {
      this.Accreditcarousel.prev();
    }
    else if (button == 'next' && this.isAccNext) {
      this.Accreditcarousel.next();
    }
  }

  // For Partnership
  partnerCaruoselSlide(button: any) {
    if (button == 'prev' && this.isPartnerPrev) {
      this.partnershipcarousel.prev();
    }
    else if (button == 'next' && this.isPartnerNext) {
      this.partnershipcarousel.next();
    }
  }

  tabChanges(type: any) {
    if (type?.index == 0) {
      this.isAwards = true;
      this.isAccreditation = false;
      this.isPartnership = false;
    }
    else if (type?.index == 1) {
      this.isAwards = false;
      this.isAccreditation = true;
      this.isPartnership = false;
    }
    else if (type?.index == 2) {
      this.isAwards = false;
      this.isAccreditation = false;
      this.isPartnership = true;
    }
  }

  //For disable/enable carousel -> Awards
  caruoselEvent() {
    if (!this.carousel.slidesData[0].isActive && !this.carousel.slidesData[this.carousel.slidesData.length - 1].isActive) {
      this.isPrev = true;
      this.isNext = true;
    }
    else if (this.carousel.slidesData[0].isActive && this.carousel.slidesData[this.carousel.slidesData.length - 1].isActive) {
      this.isPrev = false;
      this.isNext = false;
    }
    else if (!this.carousel.slidesData[0].isActive && this.carousel.slidesData[this.carousel.slidesData.length - 1].isActive) {
      this.isPrev = true;
      this.isNext = false;
    }
    else if (this.carousel.slidesData[0].isActive && !this.carousel.slidesData[this.carousel.slidesData.length - 1].isActive) {
      this.isPrev = false;
      this.isNext = true;
    }
  }
  // awards carousel option setting
  carouselOptions2: OwlOptions
  carouselOptions21: OwlOptions

  carouselOptions22: OwlOptions

  getLocation() {
    this.homeService.getLocation(this.defaultToken).subscribe(
      res => {
        this.location = res;
        this.location.forEach(loc => {
          let address = loc.address.split('||')
          loc.splitAddress = address;
        })
        this.carouselLocationOptions = {
          loop: false,
          mouseDrag: true,
          touchDrag: true,
          pullDrag: true,
          dots: false,
          navSpeed: 400,
          nav: false,
          navText: ['<div class="left-nav"><img src="assets/images/landing-page/left-arrow-white.svg"/></div>', '<div class="right-nav"><img src="assets/images/landing-page/right-arrow-white.svg"/></div>'],
          center: false,
          startPosition: 0,
          items: 2.50,
          responsive: {
            0: {
              items: 1
            },
            768: {
              items: 2.50
            },
            992: {
              items: 2.50
            },
            1024: {
              items: 2.50
            },
            1200: {
              items: 2.50
            },
          }
        };
      })
  }

  getOffers() {
    this.homeService.getOffers(this.defaultToken, "PRELOGIN", 0).subscribe(res => {
      this.offersImage = res?.data;
    })
  }

  submitSupport() {
    if (this.postSupportData.titleID == null || this.postSupportData.titleID == '') {
      this.errorModel.IsTitleID = true
    } else {
      this.errorModel.IsTitleID = false
      if (this.postSupportData.details == null || this.postSupportData.details == '') {
        this.errorModel.IsDescription = true
      } else {
        this.errorModel.IsDescription = false

      }
      if (this.postSupportData.email == null || this.postSupportData.email == '') {
        this.errorModel.IsEmail = true
      } else {
        this.errorModel.IsEmail = false

      }
      this.postSupportData.dtime = this.datePipe.transform(this.date, 'yyyy-MM-dd');
      if (!this.errorModel.IsTitleID && !this.errorModel.IsDescription && !this.errorModel.IsEmail) {
        this.homeService.postComplaint(this.postSupportData, this.defaultToken).subscribe(
          res => {
            if (res.Success == "true") {
              // this._snackBar.open('Request send successfully.', '', {
              //   horizontalPosition: this.horizontalPosition,
              //   verticalPosition: this.verticalPosition,
              //   duration: 2000
              // });
              this.postSupportData = new PreLoginSupport();
              this.imageName = '';
              this.invalidFile = false;
              this.invalidFileSize = false;
              this.openMessageDialog("Request submitted successfully.", false, "");
            } else {
              // this._snackBar.open('Something went wrong.', '', {
              //   horizontalPosition: this.horizontalPosition,
              //   verticalPosition: this.verticalPosition,
              //   duration: 2000
              // });
              this.openMessageDialog(res?.Message, false, "");

            }

          }
        )
      }

    }
  }
  trackedValue(index, item) {
    return item.id;
  }

  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      // minWidth: '500px',
      panelClass: 'custom-modalbox',
      width: '550px',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.goToLogin()
      this.cancel();
    });
  }
  cancelComplaint() {
    this.postSupportData = new PreLoginSupport();
    this.imageName = '';
    this.invalidFile = false;
    this.invalidFileSize = false;
  }

  //Request callback

  getCity(cityId:any) {
    this.homeService.getCity('preLogin', this.defaultToken, cityId).subscribe(
      res => {
        this.city = res?.data;
      })


  }

  checkName() {
    if (this.callBackForm.controls.name?.value?.trim() == "") {
      this.callBackForm.patchValue({ name: '' })
    }
  }
  onDescChange() {
    if (this.callBackForm.controls.description.value && this.callBackForm.controls.description.value.trim() != "") {
      this.count = this.callBackForm.controls.description.value.length;
    }
    else {
      this.count = 0
      this.callBackForm.patchValue({ description: this.callBackForm.controls.description.value.trim() == "" ? "" : this.callBackForm.controls.description.value })
    }
  }

  cancel() {
    this.digopen?.close();
    this.callBackForm.reset();
    this.callBackForm.controls.captcha.markAsUntouched();
    this.count = 0;
    this.isUAENoValidCallback = false;
  }

  navigateToTargetPage(pagelink: string) {
    this.router.navigate([pagelink]);
  }
  onSubmit(template: any) {
    this.callBackForm.markAllAsTouched();
    if (this.callBackForm.valid) {
      let formData = {
        name: this.callBackForm.controls.name.value,
        city: this.callBackForm.controls.city.value,
        description: this.callBackForm.controls.description.value,
        phone: this.callBackForm.controls.phone.value
      }
      this.homeService.postCallback(formData, 'preLogin', this.defaultToken).subscribe(res => {
        console.log(res)
        if (res.Success == "true") {
          // this._snackBar.open('Request send successfully.', '', {
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          //   duration: 2000
          // });
          this.cancel();
          this.isError = false;
          this.msg = "Request submitted successfully.";
          this.successMsgPopup(template)

        }
        else {
          this.cancel();
          this.isError = true;
          this.msg = "Something went wrong.";
          this.successMsgPopup(template)
          // this._snackBar.open('Something went wrong.', '', {
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          //   duration: 2000
          // });

        }
      })
    }
  }
  currentSection = 'homesection';
  scrollToSection(sectionId: string) {

    const sectionElement = this.sectionContainer.nativeElement.querySelector(`#${sectionId}`);

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.currentSection = sectionId;
    }
  }

  getMdsMessage() {
    this.homeService.getMdsMessage(this.defaultToken).subscribe(
      res => {

        this.mdsimage = res[0].imageURL;
        this.mdsdescription = res[0].descriprtion;
        this.mdstitle = res[0].title;
        // this.latestNews = [
        //   { class: 'red', title:'red' },
        //   { class: 'green',title:'green' },
        //   { class: 'blue',title:'blue' },
        //   { class: 'pink',title:'pink' },
        //   { class: 'gray',title:'pink' },
        //   { class: 'violet',title:'pink' },
        //   { class: 'brown',title:'pink' },
        // ]
      },
      (error) => {

      }
    )
  }

  clearErr() {
    if (this.mail == "") {
      this.mailErr = false;
    } else {
      let isValid = this.emailRegex.test(this.mail);
      if (isValid)
        this.mailErr = false;
    }
  }

  checkMail(): boolean {

    if (this.mail) {

      let isValid = this.emailRegex.test(this.mail);
      if (isValid) {
        this.mailErr = false;
        // this.disableSubscribe=false;
      } else {
        this.mailErr = true;
        //this.disableSubscribe=true;
      }
    }
    else this.mailErr = true;
    return this.mailErr;
  }

  subscribeNews() {
    if (!this.checkMail()) {
      this.homeService.subscribeNewsLetter(this.mail, this.defaultToken).subscribe(res => {
        if (res) {
          this.openMessageDialog("Subscribed successfully.", false, '')
        }
        else {
          this.openMessageDialog("Given mail id Already Subscribed.", true, '')
        }
        this.mail = "";
        this.mailErr = false;
        // this.disableSubscribe=true;
      })
    }
  }

  showOffers: boolean = false;
  slidePopup(offer: any) {
    this.selectedOffer = offer;
    this.getSchoolList();
  }

  getSchoolList() {
    this.registerService.getSchoolList(this.defaultToken).subscribe(res => {
      this.schoolList = res;
      this.schoolDropdownList = [];
      this.homeService.getOffers(this.defaultToken, "PRELOGIN", this.selectedOffer?.id).subscribe(res => {
        res?.data.forEach(y => {
          this.schoolDropdownList.push(this.schoolList.find(x => x.bsU_ID == y.bsU_ID));
        })
        this.schoolDropdownList = this.schoolDropdownList.filter(z => z != null);
        this.showOffers = !this.showOffers;
        this.offersForm.reset();
        this.selectedCountries = 'ae'
        this.gradeList=[];
        this.pickUpPoints=[];
      })
    })
  }
  closeOffersPopup() {
    this.showOffers = !this.showOffers;
    this.offersForm.reset();
    this.selectedCountries = 'ae'
    this.schoolDropdownList = [];
    this.gradeList=[];
    this.pickUpPoints=[];
  }

  changeSchoolName() {
    let data = this.schoolList.find(x => x.schoolName.toLowerCase() == this.offersForm.value.school.toLowerCase());
    if (data) {
      this.bsuId = data.bsU_ID;
      if (data?.country == "UAE") {
        this.selectedCountries = this.countries[0]
      } else {
        this.selectedCountries = this.countries[1]
      }
      this.offersForm.patchValue({ phone: '' })
      if (this.startDate)
        this.getAcademicYrs();
    }
  }

  changeStartDate(event: any) {
    let startDate = event.value._d;
    this.offersForm.controls['startDate'].setValue(this.datePipe.transform(startDate, 'yyyy-MM-dd'));
    this.startDate = this.offersForm.get('startDate').value
    this.getAcademicYrs();
  }

  getAcademicYrs() {

    if (this.startDate) {
      this.registerService.getAcademicYrs(this.defaultToken, this.startDate, this.bsuId).subscribe(res => {
        this.academicYrList = res?.data;
        this.acdId = this.academicYrList[0].id;
        this.getGrades();
        this.getPickupPoints();
      });
    }
  }

  checkEmail() {

    if (this.offersForm.get('emailId').value) {

      let isValid = this.emailRegex.test(this.offersForm.get('emailId').value);
      if (isValid)
        this.emailErr = false
      else this.emailErr = true
    } else this.emailErr = false
  }
  getGrades() {
    let data = {
      bsuId: this.bsuId,
      acdyr: this.acdId.toString()
    }
    this.registerService.getGrades(this.defaultToken, data).subscribe(res => {
      this.gradeList = res?.data;
    })
  }
  getPickupPoints() {

    let data: any = {
      headers: {
        "master": "AREA_M_TPT_R",
        "param1": "172",
        "bsuid": this.bsuId,
        "acdid": this.acdId.toString(),
        "Authorization": "Bearer " + this.defaultToken
      }
    }
    this.serviceinfoService.getMaster(data).subscribe((res: any) => {
      this.pickUpPoints = res?.data;
    })
  }
  formatPhoneNumber($event, currentCtrl, type: any) {
    let phoneNumber: any = parsePhoneNumberFromString('+' + currentCtrl.selectedCountry.dialCode + $event.srcElement.value);
    if (phoneNumber && phoneNumber.isValid()) {
      var string = phoneNumber;
      let phone = ''
      if (type == "offer") {
        if (string.country == 'AE') {
          phone = [string.nationalNumber.slice(0, 2) + "-" + string.nationalNumber.slice(2, 9)].join('');
          // let firstIndex=Number(phone.charAt(0));
          this.isUAENoValid = phone.startsWith('5') ? false : true;
        } else {
          phone = phoneNumber.nationalNumber
          this.isUAENoValid = false;
        }
      } else {

        if (string.country == 'AE') {
          phone = [string.nationalNumber.slice(0, 2) + "-" + string.nationalNumber.slice(2, 9)].join('');
          // let firstIndex=Number(phone.charAt(0));
          this.isUAENoValidCallback = phone.startsWith('5') ? false : true;
        } else {
          phone = phoneNumber.nationalNumber
          this.isUAENoValidCallback = false;
        }
      }
      this.nationalnumber = phoneNumber.number
      $event.srcElement.value = phone
    }
  }

  loadCityBasedOnCountry(currentCtrl){
    this.city = [];
    this.callBackForm.controls.city.setValue('');
    if(currentCtrl.selectedCountry.dialCode == '974'){
      this.getCity(this.qatarCountryId);
    }else{
      this.getCity(this.dubaiCountryId);
    }
  }

  submitOffers() {
    let formData = {
      "STU_NAME": this.offersForm.controls.name.value,
      "BSU_ID": this.bsuId,
      "SERVICE_START_DATE": this.datePipe.transform(this.offersForm.controls.startDate.value, 'yyyy-MM-dd') + "T00:00:00",
      "STU_GRADE": this.offersForm.controls.grade.value,
      "STU_DOB": this.datePipe.transform(this.offersForm.controls.dob.value, 'yyyy-MM-dd') + "T00:00:00",
      "PRIMARY_CONTACT": this.offersForm.controls.parentName.value,
      "PICKUP_POINT": this.offersForm.controls.area.value,
      "CONTACT_NUMBER": this.offersForm.controls.phone.value,
      "EMAIL_ID": this.offersForm.controls.emailId.value,
      "STU_SCHOOL_ID": this.offersForm.controls.schoolId.value,
      "SOURCE": "WEB"
    }
    this.homeService.postOffers(formData, this.defaultToken).subscribe(res => {
      if (res.success == "true") {
        this.openMessageDialog("Request submitted successfully.", false, "");
        this.closeOffersPopup();
      }
      else {
        this.openMessageDialog(res?.message, false, "");
        this.closeOffersPopup();
      }
    })
  }
  redirectUrl(url:any)
{
  if(url)
  window.open(url, '_blank')
}

openPdf(type:any)
{
  this.homeService.getTermsAndPolicy(type, this.defaultToken).subscribe((res:any)=>{
    window.open(res?.data, '_blank');
  })
}

openChatBot(){
  this.openchatBot = !this.openchatBot;
}

redirectEvent(message:any){
  if(message.redirectType=='scroll'){
    const classElement = document.getElementById(message.redirectUrl);
    if(classElement){
      classElement.scrollIntoView();
    }
  }
  else if(message.redirectType=='open-modal'){
    const template = this.templateMap[message.redirectUrl];
    if (template) {
      this.openDialog(template);
    }
  }
}
}
