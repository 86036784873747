import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
//   studentId:any = localStorage.getItem('selectedstudentid');
//  public allchild:any = JSON.parse(localStorage.getItem('allChildren'))

 
  // private photoUrl = new BehaviorSubject<string>('');
  // console.log(this.photoUrl);
  
  // currentPhotoUrl = this.photoUrl.asObservable();
  public isPaymentMessage = new BehaviorSubject<any>(null);
  public fileErrMessage = new BehaviorSubject<any>(null);
  public isPaymentContent = new BehaviorSubject<any>(true);
  public contactDetails = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient, private configService:ConfigService, private fileService:FileService) {
   //let data = this.allchild.filter(x=>x.studentId == this.studentId);

   }
  getToken()
  {
    let token: any;
    token = JSON.parse(localStorage.getItem('currentToken'))
   return token?.access_token;
  }
  getUsername()
  {
    let username:any;
    username=localStorage.getItem('parent')
    return username;
  }

  getIpAddress()
  {
    let ip:any;
    ip=localStorage.getItem('IPAddress')
    return ip;
  }
  getContactDetails(name: any) {
    let token: any;
    token = localStorage.getItem('currentToken')
    name=this.getUsername();
    const Header = { username: name, Authorization: `Bearer ${token.access_token}` };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetTransportContactInfo`, { headers: Header })
  }
  getPaymentStatusDetails(data: any) {
    let token: any;
    token = localStorage.getItem('currentToken')
    const Header = {
      StudentNo: data?.StudentNo,
      SchoolId: data?.SchoolId.toString(), Authorization: `Bearer ${token.access_token}`
    };
    return this.http.get(`${this.configService.baseUrl}/api/Payment/GetSTSPaymentStatus`, { headers: Header })
  }
  getQuickLinks() {
    let token: any;
    token = localStorage.getItem('currentToken')
    const Header = {
      Authorization: `Bearer ${token.access_token}`
    };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetSTSQuickLinks`, { headers: Header })
  }
  getAccountDetails()
  {

    const Header = {
      Authorization: `Bearer ${this.getToken()}`,
      username:this.getUsername()
    };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetTransportContactInfo`, { headers: Header })
  }

  getTermsAndPolicy(type:any)
  {
    let child =JSON.parse(localStorage.getItem("parentChildrens"))
    const Header = {
      Authorization: `Bearer ${this.getToken()}`,
      bsuid :child[0]?.bsuId,
      type :type,
      city :"na"
    };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetAppTermsandPrivacyPolicy`, { headers: Header })
  }

  changePassword(value:any)
  {
    const Header = {
      Authorization: `Bearer ${this.getToken()}`,
    };
    let body={
      UserName:this.getUsername(),
      OldPassword:value?.old,
      NewPassword:value?.new,
      DeviceId:this.configService.deviceId,
      Source:this.configService.source
    }
    return this.http.post(`${this.configService.baseUrl}/api/password/change`,body,{ headers: Header })
  }

  getMessageAlerts()
  {
    const Header = {
      Authorization: `Bearer ${this.getToken()}`,
      username: this.getUsername()
    };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetMessageAlerts`, { headers: Header })
  }

 getuserProfileImg()
  {
    const Header = {
      Authorization: `Bearer ${this.getToken()}`,
      parentID: this.getUsername(),
      source:this.configService.source,
      appName:"web"
    };
    return this.http.get(`${this.configService.baseUrl}/api/Parent/GetParentProfile`, { headers: Header })
  }
  updateMessageAlert(value:any)
  {
    let body=
      {
        username: this.getUsername(),
        allowalerts: value
     }
    const Header = {
      Authorization: `Bearer ${this.getToken()}`
    };
    return this.http.post(`${this.configService.baseUrl}/api/Transport/UpdateMessageAlerts`,body, { headers: Header })
  }

  updateProfilePhoto(value:any)
  {
  let body={
    ParentID:this.getUsername(),
    DeviceID:this.configService.deviceId,
    DeviceIP:this.getIpAddress(),
    ImageFormat:value.imageFormat,
    ImageBase64:value.imageBase64,
    Source:this.configService.source,
    IsUpdate:1,
    IsDeleted:0
  }
  const Header = {
    Authorization: `Bearer ${this.getToken()}`
  };
  return this.http.post(`${this.configService.baseUrl}/api/Parent/POST_PARENT_PROFILE`,body, { headers: Header })
  }
getCO2Emission()
{
  const Header = {
    Authorization: `Bearer ${this.getToken()}`,
    apiId:"4",
    StuNo: JSON.parse(localStorage.parentChildrens)[0].studentId,
    Minutes:''
  };
  return this.http.get(`${this.configService.baseUrl}/api/Transport/GETSTUDENTETAMAPDETAILS`, { headers: Header });
}
updateProfile(data){
  let token:any;
  token=JSON.parse(localStorage.getItem('currentToken'));
  const headers = new HttpHeaders({
    Authorization: `Bearer ${token.access_token}`,
    source: this.configService.source,
    StudentId:data.StudentId,
    BsuId:data.BsuId
  });
  const storedFile = this.fileService.getFile();
  const formData = new FormData();
    formData.append('file', storedFile);
    const requestOptions = {
      headers: headers,
    };
  return this.http.post(`${this.configService.baseUrl}/api/StudentInfo/UpdateStudentPhotos`,formData, requestOptions)
}
// changeMemberPhoto(photoUrl: string) {
//   this.photoUrl.next(photoUrl);
// }

getAutoPromotionDetails() {
  let token: any;
  token = localStorage.getItem('currentToken')
  let name=this.getUsername();
  const Header = { OLU_NAME: name, Source: 'STS_WEB', Authorization: `Bearer ${token.access_token}` };
  return this.http.get(`${this.configService.baseUrl}/api/transport/GET_USER_TERMS`, { headers: Header })
}

}
