import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  isError:boolean=false;
  constructor(private router: Router,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if(this.data.buttonName==undefined || this.data.buttonName==null){
      this.data.buttonName='OK'
    }
  }
  dilogclose()
  {
    
    this.router.navigate([this.data.redirectpage]);
  }
}
