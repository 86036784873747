import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NotificationRoutingModule } from './notification-routing.module';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NotificationAlertsComponent } from './notification-alerts/notification-alerts.component';
import { NotificationStarredComponent } from './notification-starred/notification-starred.component';
import { NotificationMessagesComponent } from './notification-messages/notification-messages.component';
import { NotificationComponent } from './notification.component';
import {MatTabsModule} from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
@NgModule({
  declarations: [
    NotificationAlertsComponent,
    NotificationStarredComponent,
    NotificationMessagesComponent,
    NotificationComponent
  ],
  imports: [
    CommonModule,
    NotificationRoutingModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule, MatInputModule,
  ],
  exports: [[NotificationRoutingModule],],providers:[DatePipe]
})
export class NotificationModule { }
