import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild,Inject,Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  MatDialog, MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import { HttpClient } from '@angular/common/http';
import { RegistrationService } from 'src/app/service/registration.service';
import { TokenService } from 'src/app/service/token.service';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { PdfserviceService } from 'src/app/service/pdfservice.service';
import { saveAs } from 'file-saver';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-view-terms-and-conditions',
  templateUrl: './view-terms-and-conditions.component.html',
  styleUrls: ['./view-terms-and-conditions.component.scss']
})
export class ViewTermsAndConditionsComponent implements OnInit {
  pdfName: string="Terms and Conditions.pdf";
  defaultToken: any;
  index: number;
  pdfContent: any;
  textPdf: any='';
  textPdf2: any='';
  textPdf3: any='';
  textPdf4: any='';
  textPdf5: any='';
  textPdf6: any='';
  textPdf7:any='';
  textPdf8:any='';
  textPdf9: any='';
  sign: any='';
  sign2: any='';
  sign3: any='';
  sign4: any='';
  sign5: any='';
  checkbox1:boolean=false;
  checkbox2:boolean=false;
  city: any;
  isGrade: any;
  parentData: any;
  parentIndex: any;
  studentData: any;
  studentGrade: string;
  academicYr: any;
  agreeDisable: boolean=true;
  schoolName: any;
  pdfType: any;
  paramType: any;
  showloader:boolean=true;
  base64String: string | ArrayBuffer;
  isReactivate: boolean = false;
  constructor(private dialog: MatDialog,private router: Router,private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,private http: HttpClient,
  public regservice:RegistrationService,private tokenService: TokenService,private httpClient: HttpClient,
  private sanitizer: DomSanitizer,private pdfService: PdfserviceService,public activatedRoute:ActivatedRoute) { }
  pdfs:any[]=[];
  @ViewChild('downloadContent') downloadContent: ElementRef;
  pdfSrc='';
  pdfSrc_s='';
  @ViewChild('pdfFrame') pdfFrame: ElementRef;
  dffSrc_d:SafeResourceUrl;
  pdfHtml:any;
  @ViewChild('pdf') pdf: ElementRef;
  @ViewChild('header') header: ElementRef;
  @ViewChild('headerLogo') headerLogo: ElementRef;
  @ViewChild('signature') signature: ElementRef;
  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'rm-body-height');
    var root = document.getElementsByTagName( 'html' )[0];
    this.renderer.addClass(root, 'rm-body-height');
    this.activatedRoute.queryParams.subscribe(params=>{
      this.paramType=params?.type
      this.isReactivate = params?.isReactivate;
    })
    this.getToken();
    this.city = JSON.parse(localStorage.getItem("schoolDetails"))?.city;
    this.studentGrade=localStorage.getItem("studentGrade");
     this.parentData = JSON.parse(localStorage.getItem("parentDetails"));
     this.parentIndex= this.parentData?.length == 1 ? 0 : this.parentData.findIndex((x:any)=>x.disableRegistration == 0)
     this.parentIndex = this.parentIndex == -1? 0:this.parentIndex;
     this.studentData = JSON.parse(localStorage.getItem("studentDetails"));
     this.academicYr = localStorage.getItem("currentAcademicYear");
     this.schoolName =  localStorage.getItem("schoolName")
    // this.isGrade= (this.studentGrade.includes('1') && !this.studentGrade.includes('10') && !this.studentGrade.includes('11') && !this.studentGrade.includes('12') ) || (this.studentGrade.includes('2') && !this.studentGrade.includes('12')) || this.studentGrade.includes('3') || this.studentGrade.includes('KG') ? true : false
    //this.isGrade= this.studentGrade.includes('01')||this.studentGrade.includes('02')  ||this.studentGrade.includes('03') || this.studentGrade.includes('KG') ? true : false;
  }
  getToken() {
    this.tokenService.GetToken(null).subscribe(
      res => {
        this.defaultToken = res?.access_token;
      this.getTermsConditions();
      }
    )
  }
  getTermsConditions()
  {
    this.regservice.getTermsandConditions(this.defaultToken).subscribe(res=>{
      let pdfData = [];
      let parentPdfCount= res?.data.filter(y=>y.ttcuL_DOC_TYPE == 'PUT')?.length;
      res?.data.forEach(x=>{
        if(this.paramType == "PUT")
        {
          this.isGrade=x?.ttcuL_bShowSiblingInput;
      if(x.ttcuL_DOC_TYPE == 'PUT' && parentPdfCount > 1)
      {        
        // let gradeCheck= (x?.ttcuL_TYPE?.includes('1') && !x?.ttcuL_TYPE?.includes('10') && !x?.ttcuL_TYPE?.includes('11') && !x?.ttcuL_TYPE?.includes('12')) || (x?.ttcuL_TYPE?.includes('2') && !x?.ttcuL_TYPE?.includes('12')) || x?.ttcuL_TYPE?.includes('3') || x?.ttcuL_TYPE?.includes('KG') ? true : false;
        let gradeCheck= x?.ttcuL_GRD_IDS?.includes('01') || x?.ttcuL_GRD_IDS?.includes('02')  || x?.ttcuL_GRD_IDS?.includes('03') || x?.ttcuL_GRD_IDS?.includes('KG') ? true : false;
        if((gradeCheck && this.isGrade) || (!gradeCheck && !this.isGrade)) 
        pdfData.push(x)
      }else if(x.ttcuL_DOC_TYPE == 'PUT' && parentPdfCount == 1) pdfData.push(x)
    }else{
      if(x.ttcuL_DOC_TYPE != 'PUT')
      pdfData.push(x)
    }
      })
      
        this.pdfs = pdfData
        this.pdfs.forEach(x=>x.isAgree=false);
        let primaryContactName = this.parentData[this.parentIndex].stU_PRIMARYCONTACT=="F" ? this.parentData[this.parentIndex].stS_FFIRSTNAME : (this.parentData[this.parentIndex].stU_PRIMARYCONTACT=="M" ? this.parentData[this.parentIndex].stS_MFIRSTNAME : this.parentData[this.parentIndex].stS_GFULLNAME);

        if(this.parentData[this.parentIndex].stU_PRIMARYCONTACT=="G"){
          let guardianName = this.parentData[this.parentIndex].stS_GFULLNAME?.split(' ')
          primaryContactName= guardianName[0];
        }

        let payload={
          stuNo:this.studentData.stU_SCHOOL_ID,
          oluName:primaryContactName
        }
        this.regservice.getParentUndertakingData(this.defaultToken,payload).subscribe((content:any)=>{
          let data:any
          if(content.data?.ttcD_DATA)
          {
            data = JSON.parse(content.data?.ttcD_DATA);
          }
            this.textPdf= data?.input1 ? data?.input1:primaryContactName;
            this.textPdf2 = data?.input2 ? data?.input2:this.studentData.stU_FIRSTNAME;
            this.textPdf3 = data?.input3 ? data?.input3: (this.studentData.stU_SCHOOL_ID != "" ? this.studentData.stU_SCHOOL_ID : "N/A");
            this.textPdf4 = this.studentGrade ? this.studentGrade : data?.input4;
            this.textPdf5 = data?.input5 ? data?.input5:this.schoolName;
            this.textPdf6 = data?.input6 ? data?.input6:'';
            this.textPdf7 = data?.input7 ? data?.input7:'';
            this.textPdf6 =localStorage.getItem("regselecteddroparea");
           // this.textPdf7=localStorage.getItem("regselecteddroparea");
            this.textPdf8 = data?.input8 ? data?.input8:'';
            this.textPdf9 = data?.input9 ? data?.input9:'';
            this.sign = data?.sign1 ? data?.sign1:'';
            this.sign2 = data?.sign2 ? data?.sign2:'';
            this.sign3 = data?.sign3 ? data?.sign3:'';
            this.sign4 = data?.sign4 ? data?.sign4:'';
            this.sign5 = data?.sign5 ? data?.sign5:'';
            this.checkbox1=data?.checkbox1 ? data?.checkbox1:false;
            this.checkbox2=data?.checkbox2 ? data?.checkbox2:false;
            this.pdftext();
          this.loadPdf(0);
          this.showloader=false;
        })
    })
  }
  loadPdf(index:number)
  {
    this.pdfSrc=this.pdfs[index].ttcul_Url_Path
    this.pdfSrc_s=this.pdfSrc.replace("http://","https://");
    this.dffSrc_d = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSrc);
    this.pdfHtml=this.pdfs[index].ttcuL_PDF_HTML;
    // let pTag = document.createElement('p');
    //  pTag.innerHTML=this.pdfHtml;
    //  this.pdfContent=pTag.innerText;
    this.pdfName=this.pdfs[index].ttcul_Url_Text
    this.pdfType=this.pdfs[index].ttcuL_DOC_TYPE
    this.index=index;
  }
  get sanitizedPdfHtml(): SafeHtml { 
    this.pdfContent=this.sanitizer.bypassSecurityTrustHtml(this.pdfHtml ?? '');
    return this.pdfContent }

    pdftext()
    {
      if((this.city == 'Dubai' && !this.isGrade) || (this.city == 'Qatar' && !this.isGrade) || (this.city == 'Sharjah' && !this.isGrade))
      {
        if(!this.textPdf || !this.textPdf2 || !this.textPdf3 || !this.textPdf4 || !this.textPdf5 || !this.textPdf6)
        this.agreeDisable=true;
        else this.agreeDisable=false;
      }
      else if((this.city == 'Sharjah' && this.isGrade) || (this.city == 'Dubai' && this.isGrade))
      {
        if(!this.textPdf || !this.textPdf2 || !this.textPdf3 || !this.textPdf4 || !this.textPdf5 || !this.textPdf6 || !this.textPdf7 || !this.textPdf8 )
        this.agreeDisable=true;
        else this.agreeDisable=false;
      }
      else if(this.city == 'Qatar' && this.isGrade)
      {
        if(!this.textPdf || !this.textPdf2 || !this.textPdf3 || !this.textPdf4 || !this.textPdf5 || !this.textPdf6 || (!this.textPdf7 && this.checkbox1) || (!this.textPdf8 && this.checkbox1) || (!this.textPdf9 && this.checkbox2))
        this.agreeDisable=true;
        else this.agreeDisable=false;
      }else this.agreeDisable=false;
    }
  agree()
  {
    this.pdfs[this.index].isAgree =true;
    this.index++;
    if(this.index <= (this.pdfs.length - 1))
    {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' 
    });
      this.loadPdf(this.index)
    }
    else{
      if(this.paramType == "PUT")
      {
      let jsonstring=JSON.stringify({
        "input1":this.textPdf,
        "input2":this.textPdf2,
        "input3":this.textPdf3,
        "input4":this.textPdf4,
        "input5":this.textPdf5,
        "input6":this.textPdf6,
        "input7":this.textPdf7,
        "input8":this.textPdf8,
        "input9":this.textPdf9,
        "sign1":this.sign,
        "sign2":this.sign2,
        "sign3":this.sign3,
        "sign4":this.sign4,
        "sign5":this.sign5,
        "checkbox1":this.checkbox1,
        "checkbox2":this.checkbox2
      })
      let data={
        "TTCUL_ID":0,
        "OLU_NAME":this.parentData[this.parentIndex].stU_PRIMARYCONTACT=="F" ? this.parentData[this.parentIndex].stS_FFIRSTNAME : this.parentData[this.parentIndex].stS_MFIRSTNAME,
        "STU_NO":this.studentData.stU_SCHOOL_ID.toString(),
        "TTCD_DATA":jsonstring,
        "TTCD_MOBILE": localStorage.getItem("primaryMobileNo"),
        "TTCD_ACD_ID": localStorage.getItem("currentAcademicYearId"),
        "TTCD_SUBMIT_FROM": "Registration",
        "TTCD_SOURCE": "STS_WEB"
      }
      this.regservice.saveParentUndertakingData(this.defaultToken,data).subscribe(res=>{      
    //  let falseIndex = this.pdfs.findIndex(x=>x.isAgree == false)
    //   if(falseIndex == -1)
    //   this.router.navigate(['/schoolbus-registration'],{queryParams:{tab:3,isAgreed:true}});
    //   else 
    let checksData = JSON.parse(localStorage.getItem("termsConditions"));
    let agree:any
      if( checksData) 
      {
         agree=checksData?.isAgree ?true:false
      } else agree =false;
      localStorage.removeItem("regselecteddroparea");
       this.router.navigate(['/schoolbus-registration'],{queryParams:{tab:3,isAgreed:agree,isReactivate:this.isReactivate}});
    })
  }else{
    let falseIndex = this.pdfs.findIndex(x=>x.isAgree == false)
    if(falseIndex == -1)
    this.router.navigate(['/schoolbus-registration'],{queryParams:{tab:3,isAgreed:true,isReactivate:this.isReactivate}});
    else  this.router.navigate(['/schoolbus-registration'],{queryParams:{tab:3,isAgreed:false,isReactivate:this.isReactivate}});
  }
    }
  }
  
  goToPage()
  {
    if(this.paramType != "PUT")
    this.router.navigate(['/schoolbus-registration'],{queryParams:{tab:0,isAgreed:false,isReactivate:this.isReactivate}})
    else{
      let checksData = JSON.parse(localStorage.getItem("termsConditions"));
      let agree:any
        if( checksData) 
        {
           agree=checksData?.isAgree =="true"?true:false
           checksData.checkFour=false
           localStorage.setItem("termsConditions", JSON.stringify(checksData))
        } else agree =false;
        this.router.navigate(['/schoolbus-registration'],{queryParams:{tab:0,isAgreed:agree,isReactivate:this.isReactivate}})
    }
  }
  //  downloadPdf()
  //  {
  //   //this.http.get(this.pdfSrc, { responseType: 'blob' }).subscribe((blob: Blob) => {
  //     const blob = new Blob([this.pdfSrc], { type: 'application/pdf' });
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = this.pdfName;
  //     link.click();
  //  // });

  // }
  convertToBase64(filePath: string) {
    fetch(filePath)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onload = () => {
          this.base64String = reader.result;
        };
        reader.readAsDataURL(blob);
      })
  }

  pdfDownload()
  {
     
      // const file = new jsPDF('p', 'mm', 'a4');
      // file.text(this.pdf.nativeElement.innerText, 0, 0); 
      // file.save(this.pdfName + '.pdf');



    //   const width = this.pdf.nativeElement.clientWidth;
    //   const height = this.pdf.nativeElement.clientHeight + 40;
     
    //   let jsPdfOptions = {
    //     format: [width + 50, height + 220]
    //     };
    //   const file = new jsPDF(jsPdfOptions);
    //   file.setFontSize(48);
    //   file.text(this.pdf.nativeElement.innerText, 25, 100);
    //  // console.log(this.pdf.nativeElement.innerText)
    //   file.addPage();
    //   file.text(this.pdf.nativeElement.innerText, 25, 100);
    //   file.save(this.pdfName + '.pdf');
    var domain=this.regservice.appUrl
    var pdfLogo = (this.city == "Dubai" || this.city == "Qatar")? domain+"/assets/images/sts-logo.png": domain+"/assets/images/bbt-logo.png";
    //this.convertToBase64(pdfLogo);
      var content:any;
      var headerOption= {
        tableAutoSize:true,
        imagesByReference:true
      }
      var pdftitle = (this.city == "Dubai" || this.city == "Qatar") ? "School Transport Services LLC":"Bright Bus Transport"
      var headerContent= `<table style="width: 100%">
      <tr>
        <td style="width:80px;height:40px;border-right:1px solid black;text-align:center"><img style="width:40px;height:40px" src=${pdfLogo}></td>
        <td style="font-weight:bold;font-size: 18px;text-align:center;margin-top:10px">${pdftitle}</td>
      </tr>
      <tr>
        <td style="border-right:1px solid #fff"></td>
      <td style="font-weight:bold;font-size: 18px;text-align:center;margin-top:5px;margin-bottom:5px;">${this.pdfName}</td>
      </tr>
      </table>`
     if(this.header && this.header.nativeElement && this.pdfType=='PUT')
     {
      content =headerContent+this.removeMetadata(this.header.nativeElement.innerHTML)+this.removeMetadata(this.pdf.nativeElement.innerHTML);
      
     }
     else
     {
       content =headerContent+this.removeMetadata(this.pdf.nativeElement.innerHTML);
     }
      
   var html = htmlToPdfmake(content,headerOption);  
   // else var html = htmlToPdfmake(this.pdf.nativeElement.innerHTML);  
    const documentDefinition = { content:html.content,images:html.images };
    pdfMake.createPdf(documentDefinition).download(this.pdfName); 
  }

  removeMetadata(html: string): string {
    const metadataRegex = /<\s*(meta|title|link|style|script)[^>]*>.*?<\/\s*\1\s*>/gi;
    const nonContentRegex = /<\s*(head|script|style)[^>]*>.*?<\/\s*\1\s*>/gi;

    html = html.replace(metadataRegex, '');
    html = html.replace(nonContentRegex, '');
    return html;
  }

  downloadPdf() {
    this.regservice.DownloadPDF(this.defaultToken, this.pdfSrc).subscribe(
      (res: any) => {
        this.saveFile(res);
      },
      (error) => {
        console.error('Error downloading PDF:', error);
      }
    );
  }

  private saveFile(response: any): void {
    const blob = new Blob([response], { type: 'application/pdf' });
    saveAs(blob, this.pdfName);
  }
}


