import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild,Inject,Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  MatDialog, MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import { HttpClient } from '@angular/common/http';

import { ReregistrationService } from 'src/app/service/reregistration.service';
import { PdfserviceService } from 'src/app/service/pdfservice.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { RegistrationService } from 'src/app/service/registration.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-re-register-terms-and-condition',
  templateUrl: './re-register-terms-and-condition.component.html',
  styleUrls: ['./re-register-terms-and-condition.component.scss'],
 // providers:[SafePipe]
})
export class ReRegisterTermsAndConditionComponent implements OnInit {

  pdfName: string="Terms and Conditions.pdf";
  defaultToken: any;
  index: number;
  pdfHtml: any;
  pdfContent: any;
  @ViewChild('pdf') pdf: ElementRef;
  city: any;
  studentDetails: any;
  isGrade: any;
  textPdf: any='';
  textPdf2: any='';
  textPdf3: any='';
  textPdf4: any='';
  textPdf5: any='';
  textPdf6: any='';
  textPdf7:any='';
  textPdf8:any='';
  textPdf9: any='';
  checkbox1:boolean=false;
  checkbox2:boolean=false;
  agreeDisable: boolean=true;
  primaryContactName: string;
  @ViewChild('header') header: ElementRef;
  @ViewChild('signature') signature: ElementRef;
  pdfType: string;
  constructor(private reregistrationService:ReregistrationService,private dialog: MatDialog,
    private router: Router,private renderer: Renderer2, private sanitizer: DomSanitizer,
     @Inject(DOCUMENT) private document: Document,private http: HttpClient,
     private pdfservice:PdfserviceService,public regservice:RegistrationService,public activatedRoute:ActivatedRoute
  ) { }
  pdfs:any[]=[];
  @ViewChild('downloadContent') downloadContent: ElementRef;
  pdfSrc="";
  pdfSrc_s="";
  paramType:any
  gradeId="";
  checkedStudentId="";
  isSibling:boolean = false;
  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'rm-body-height');
    var root = document.getElementsByTagName( 'html' )[0];
    this.renderer.addClass(root, 'rm-body-height');
    this.activatedRoute.queryParams.subscribe(params=>{
      this.paramType=params?.type;
      this.gradeId = params?.gradeId;
      this.checkedStudentId = params?.studentId;

      if(params?.isSibling){
        this.isSibling = params?.isSibling == 'false' ? false : true;
      }
    })
    this.city = JSON.parse(localStorage.getItem("schoolDetails"))?.city;
    let data= JSON.parse(localStorage.getItem("parentChildrens"))[0];
    let transportData=JSON.parse(localStorage.getItem("transportChildInfo"));

    if(this.checkedStudentId != null && this.checkedStudentId != undefined && this.checkedStudentId != ''){
      this.studentDetails= transportData.find(x=>x.studentNo == this.checkedStudentId);
    }else{
      this.studentDetails= transportData.find(x=>x.stU_ID == data.stuId);
    }
    
    this.primaryContactName=localStorage.getItem('primaryContactName');
    //this.isGrade= (this.studentDetails?.grade.includes('1') && !this.studentDetails?.grade.includes('10') && !this.studentDetails?.grade.includes('11') && !this.studentDetails?.grade.includes('12') ) || (this.studentDetails?.grade.includes('2') && !this.studentDetails?.grade.includes('12')) || this.studentDetails?.grade.includes('3') || this.studentDetails?.grade.includes('KG')? true : false
    
    if(this.paramType == "All"){
      this.getTermsConditions();
    }else{
      this.getParentUndertakingByGrade();
    }
    
  }
 
  getTermsConditions()
  {
    this.reregistrationService.getTermsAndConditions().subscribe((res:any)=>{
      let pdfData = [];
      let parentPdfCount= res?.data.filter(y=>y.ttcuL_DOC_TYPE == 'PUT')?.length;
      res?.data.forEach(x=>{
        if(this.paramType == "PUT")
        {
          this.isGrade=x.ttcuL_bShowSiblingInput;
      if(x.ttcuL_DOC_TYPE == 'PUT' && parentPdfCount > 1)
      {
        let gradeCheck= (x?.ttcuL_TYPE?.includes('1') && !x?.ttcuL_TYPE?.includes('10') && !x?.ttcuL_TYPE?.includes('11') && !x?.ttcuL_TYPE?.includes('12')) || (x?.ttcuL_TYPE?.includes('2') && !x?.ttcuL_TYPE?.includes('12')) || x?.ttcuL_TYPE?.includes('3') || x?.ttcuL_TYPE?.includes('KG') ? true : false;
        if((gradeCheck && this.isGrade) || (!gradeCheck && !this.isGrade)) 
        pdfData.push(x)
      }else if(x.ttcuL_DOC_TYPE == 'PUT' && parentPdfCount == 1) pdfData.push(x)
    } else{
      if(x.ttcuL_DOC_TYPE != 'PUT')
      pdfData.push(x)
    }
      })
        this.pdfs = pdfData
        this.pdfs.forEach(x=>x.isAgree=false);
        let payload={
          stuNo:this.studentDetails?.studentNo,
          oluName:this.primaryContactName
        }
        this.regservice.getParentUndertakingData(this.defaultToken,payload).subscribe((content:any)=>{
          let data:any
          if(content.data?.ttcD_DATA)
          {
            data = JSON.parse(content.data?.ttcD_DATA);
          }
            this.textPdf= data?.input1 ? data?.input1:this.primaryContactName;
            this.textPdf2 = data?.input2 ? data?.input2:this.studentDetails?.studentName;
            this.textPdf3 = data?.input3 ? data?.input3:this.studentDetails?.studentNo;
            this.textPdf4 = data?.input4 ? data?.input4:this.studentDetails?.grade;
            this.textPdf5 = data?.input5 ? data?.input5:this.studentDetails?.school;
            let selecteddropoffarea=localStorage.getItem("selecteddropoffarea");
            if(selecteddropoffarea)
            {
              this.textPdf6=selecteddropoffarea;
            }
            else{
            this.textPdf6 = data?.input6 ? data?.input6:this.studentDetails?.dropoffpoint;
            }
            
            this.textPdf7 = data?.input7 ? data?.input7:'';
            this.textPdf8 = data?.input8 ? data?.input8:'';
            this.textPdf9 = data?.input9 ? data?.input9:'';
            this.checkbox1=data?.checkbox1 ? data?.checkbox1:false;
            this.checkbox2=data?.checkbox2 ? data?.checkbox2:false;
            this.pdftext();
          this.loadPdf(0);
        })
    })
  }
  loadPdf(index:number)
  {
    this.pdfSrc=this.pdfs[index].ttcul_Url_Path
    this.pdfSrc_s=this.pdfSrc.replace("http://","https://");
    this.pdfName=this.pdfs[index].ttcul_Url_Text
    this.pdfHtml=this.pdfs[index].ttcuL_PDF_HTML;
    this.pdfType=this.pdfs[index].ttcuL_DOC_TYPE;
    this.index=index;
  }
  get sanitizedPdfHtml(): SafeHtml { 
    this.pdfContent=this.sanitizer.bypassSecurityTrustHtml(this.pdfHtml ?? '');
    return this.pdfContent }
  agree()
  {
    this.pdfs[this.index].isAgree =true;
    this.index++;
    if(this.index <= (this.pdfs.length - 1))
    {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' 
      });
      this.loadPdf(this.index)
    }
    else{
     if(this.paramType == "PUT")
     {
      let jsonstring=JSON.stringify({
        "input1":this.textPdf,
        "input2":this.textPdf2,
        "input3":this.textPdf3,
        "input4":this.textPdf4,
        "input5":this.textPdf5,
        "input6":this.textPdf6,
        "input7":this.textPdf7,
        "input8":this.textPdf8,
        "input9":this.textPdf9,
        "checkbox1":this.checkbox1,
        "checkbox2":this.checkbox2
      })
      let data={
        "TTCUL_ID":0,
        "OLU_NAME":this.primaryContactName,
        "STU_NO":this.studentDetails.studentNo.toString(),
        "TTCD_DATA":jsonstring,
        "TTCD_MOBILE": localStorage.getItem('primaryMobileNo'),
        "TTCD_ACD_ID": localStorage.getItem("nextAcademicYearId"),
        "TTCD_SUBMIT_FROM": "ReRegistration",
        "TTCD_SOURCE": "STS_WEB"
      }
      this.regservice.saveParentUndertakingData(this.defaultToken,data).subscribe(res=>{   
    //  let falseIndex = this.pdfs.findIndex(x=>x.isAgree == false)
    //   if(falseIndex == -1)
    //   this.router.navigate(['/re-registration'],{queryParams:{isAgreed:true}});
    //   else 
        if (this.isSibling) {
          localStorage.setItem('siblingPUTAgreed', 'true');
        }else{
          localStorage.setItem('PUTAgreed', 'true');
        }
        this.router.navigate(['/re-registration'], { queryParams: { isAgreed: false, parentUndertaking: true,checkTerms:true } });
      })
    }else{
      let falseIndex = this.pdfs.findIndex(x=>x.isAgree == false)
       if (falseIndex == -1) {
         localStorage.setItem('termsAgreed', 'true');
         this.router.navigate(['/re-registration'], { queryParams: { isAgreed: true,checkTerms:true } });
       }
      else  this.router.navigate(['/re-registration'],{queryParams:{isAgreed:false,checkTerms:true}});
    }
    }
  }
  pdftext()
  {
    if((this.city == 'Dubai' && !this.isGrade) || (this.city == 'Qatar' && !this.isGrade) || (this.city == 'Sharjah' && !this.isGrade))
    {
      if(!this.textPdf || !this.textPdf2 || !this.textPdf3 || !this.textPdf4 || !this.textPdf5 || !this.textPdf6)
      this.agreeDisable=true;
      else this.agreeDisable=false;
    }
    else if((this.city == 'Sharjah' && this.isGrade) || (this.city == 'Dubai' && this.isGrade))
    {
      if(!this.textPdf || !this.textPdf2 || !this.textPdf3 || !this.textPdf4 || !this.textPdf5 || !this.textPdf6 || !this.textPdf7 || !this.textPdf8 )
      this.agreeDisable=true;
      else this.agreeDisable=false;
    }
    else if(this.city == 'Qatar' && this.isGrade)
    {
      if(!this.textPdf || !this.textPdf2 || !this.textPdf3 || !this.textPdf4 || !this.textPdf5 || !this.textPdf6 || (!this.textPdf7 && this.checkbox1) || (!this.textPdf8 && this.checkbox1) || (!this.textPdf9 && this.checkbox2))
      this.agreeDisable=true;
      else this.agreeDisable=false;
    }else this.agreeDisable=false;
  }
  
  goToPage()
  { 
    if(this.paramType != "PUT")
    this.router.navigate(['/re-registration'],{queryParams:{isAgreed:false,checkTerms:true}})
    else
    this.router.navigate(['/re-registration'],{queryParams:{isAgreed:false,parentUndertaking:false,checkTerms:true}})
  }
   downloadPdf()
   {
    this.pdfservice.downloadPdf(this.defaultToken,this.pdfSrc,this.pdfName);
  //   //this.http.get(this.pdfSrc, { responseType: 'blob' }).subscribe((blob: Blob) => {
  //     const blob = new Blob([this.pdfSrc], { type: 'application/pdf' });
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = this.pdfName;
  //     link.click();
  //  // });

  }
  pdfDownload()
  {
    var domain=this.regservice.appUrl
    var pdfLogo = (this.city == "Dubai" || this.city == "Qatar")? domain+"/assets/images/sts-logo.png": domain+"/assets/images/bbt-logo.png";
    var content:any;
      var headerOption= {
        tableAutoSize:true,
        imagesByReference:true
      }
      var pdftitle = (this.city == "Dubai" || this.city == "Qatar") ? "School Transport Services LLC":"Bright Bus Transport"
      var headerContent= `<table style="width: 100%">
      <tr>
        <td style="width:80px;height:40px;border-right:1px solid black;text-align:center"><img style="width:40px;height:40px" src=${pdfLogo}></td>
        <td style="font-weight:bold;font-size: 18px;text-align:center;margin-top:10px">${pdftitle}</td>
      </tr>
      <tr>
        <td style="border-right:1px solid #fff"></td>
      <td style="font-weight:bold;font-size: 18px;text-align:center;margin-top:5px;margin-bottom:5px;">${this.pdfName}</td>
      </tr>
      </table>`
     if(this.header && this.header.nativeElement && this.pdfType=='PUT')
     {
      content =headerContent+this.removeMetadata(this.header.nativeElement.innerHTML)+this.removeMetadata(this.pdf.nativeElement.innerHTML);
      
     }
     else
     {
       content =headerContent+this.removeMetadata(this.pdf.nativeElement.innerHTML);
     }
      
   var html = htmlToPdfmake(content,headerOption);  
   // else var html = htmlToPdfmake(this.pdf.nativeElement.innerHTML);  
    const documentDefinition = { content:html.content,images:html.images };
    pdfMake.createPdf(documentDefinition).download(this.pdfName);
  }
  removeMetadata(html: string): string {
    const metadataRegex = /<\s*(meta|title|link|style|script)[^>]*>.*?<\/\s*\1\s*>/gi;
    const nonContentRegex = /<\s*(head|script|style)[^>]*>.*?<\/\s*\1\s*>/gi;

    html = html.replace(metadataRegex, '');
    html = html.replace(nonContentRegex, '');
    return html;
  }

  getParentUndertakingByGrade()
  {
    let bsuId = localStorage.getItem('bsU_ID');
    this.reregistrationService.getTermsAndConditionsByGrade(this.gradeId, bsuId).subscribe((res:any)=>{
      let pdfData = [];
      let parentPdfCount= res?.data.filter(y=>y.ttcuL_DOC_TYPE == 'PUT')?.length;
      res?.data.forEach(x=>{
        if(this.paramType == "PUT")
        {
          this.isGrade=x.ttcuL_bShowSiblingInput;
      if(x.ttcuL_DOC_TYPE == 'PUT' && parentPdfCount > 1)
      {
        let gradeCheck= (x?.ttcuL_TYPE?.includes('1') && !x?.ttcuL_TYPE?.includes('10') && !x?.ttcuL_TYPE?.includes('11') && !x?.ttcuL_TYPE?.includes('12')) || (x?.ttcuL_TYPE?.includes('2') && !x?.ttcuL_TYPE?.includes('12')) || x?.ttcuL_TYPE?.includes('3') || x?.ttcuL_TYPE?.includes('KG') ? true : false;
        if((gradeCheck && this.isGrade) || (!gradeCheck && !this.isGrade)) 
        pdfData.push(x)
      }else if(x.ttcuL_DOC_TYPE == 'PUT' && parentPdfCount == 1) pdfData.push(x)
    } else{
      if(x.ttcuL_DOC_TYPE != 'PUT')
      pdfData.push(x)
    }
      })
        this.pdfs = pdfData
        this.pdfs.forEach(x=>x.isAgree=false);
        let payload={
          stuNo:this.studentDetails?.studentNo,
          oluName:this.primaryContactName
        }
        this.regservice.getParentUndertakingData(this.defaultToken,payload).subscribe((content:any)=>{
          let data:any
          if(content.data?.ttcD_DATA)
          {
            data = JSON.parse(content.data?.ttcD_DATA);
          }
            this.textPdf= data?.input1 ? data?.input1:this.primaryContactName;
            this.textPdf2 = data?.input2 ? data?.input2:this.studentDetails?.studentName;
            this.textPdf3 = data?.input3 ? data?.input3:this.studentDetails?.studentNo;
            this.textPdf4 = data?.input4 ? data?.input4:this.studentDetails?.grade;
            this.textPdf5 = data?.input5 ? data?.input5:this.studentDetails?.school;
            let selecteddropoffarea=localStorage.getItem("selecteddropoffarea");
            if(selecteddropoffarea)
            {
              this.textPdf6=selecteddropoffarea;
            }
            else{
            this.textPdf6 = data?.input6 ? data?.input6:this.studentDetails?.dropoffpoint;
            }
            
            this.textPdf7 = data?.input7 ? data?.input7:'';
            this.textPdf8 = data?.input8 ? data?.input8:'';
            this.textPdf9 = data?.input9 ? data?.input9:'';
            this.checkbox1=data?.checkbox1 ? data?.checkbox1:false;
            this.checkbox2=data?.checkbox2 ? data?.checkbox2:false;
            this.pdftext();
          this.loadPdf(0);
        })
    })
  }
}
