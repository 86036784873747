import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';

import { environment } from '../../environments/environment';
import { User } from '../models/User';
import { Token } from '../models/token';
import { TokenService } from './token.service';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class ServiceinfoService {
  constructor(private http: HttpClient,private configService:ConfigService) { }
  private get GetCommonHeader() {
    return {
      headers: {
        "oluName": localStorage.parent,
        "language": this.configService.language,

      }
    }
  }
  public getServiceInfo() {

    return this.http.get<any>(`${this.configService.baseUrl}/${environment.api.GetServiceInfo}`, this.GetCommonHeader);
  }
  public getMaster(obj) {
    
    return this.http.get<any>(`${this.configService.baseUrl}/${environment.api.getMasters}`, obj);
  }

  public SaveTransportRequest(obj: any): Observable<any> {
    // let obj1 = JSON.parse('{"StudentNo":"13100100025426","ServiceStartDate":"2022/09/04","AcdId":"1876","TripTypeId":"1"}');
    let res = this.http.post<any>(`${this.configService.baseUrl}/${environment.api.saveTransportRequest}`, obj);
    //let res = this.http.post<any>("http://localhost:27802/api/transport/SaveTransportRequest", '{"StudentNo":"13100100025426","ServiceStartDate":"2022/09/04","AcdId":"1876","TripTypeId":"1"}', obj)
    return res;
  }
  public SaveNonGemsTransportRequest(obj: any, objtkn: any): Observable<any> {
    // let obj1 = JSON.parse('{"StudentNo":"13100100025426","ServiceStartDate":"2022/09/04","AcdId":"1876","TripTypeId":"1"}');
    let res = this.http.post<any>(`${this.configService.baseUrl}/${environment.api.SaveNonGemsTransportRequest}`, obj, objtkn);
    //let res = this.http.post<any>("http://localhost:27802/api/transport/SaveTransportRequest", '{"StudentNo":"13100100025426","ServiceStartDate":"2022/09/04","AcdId":"1876","TripTypeId":"1"}', obj)
    return res;
  }
}
