import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  constructor( private router: Router) { }

  ngOnInit(): void {
  }
  navigatetolink(lnk) {
    this.router.navigate([lnk]);
  }

  navigatetoBBC(Link){
    this.router.navigate([Link],{queryParams:{isBBC:true}})
  }

}
