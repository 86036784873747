import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient,private configService:ConfigService) { }
  token: any = localStorage.getItem('currentToken');
  excessAmt:BehaviorSubject<any>=new BehaviorSubject(null);

  downloadSummaryPDF:BehaviorSubject<any>=new BehaviorSubject(null);
  downloadDetailsPDF:BehaviorSubject<any>=new BehaviorSubject(null);

  getPaymentType(stuid: any): Observable<any> {
    const header = { Authorization: `Bearer ${this.token.access_token}`, STUID: stuid, SOURCE: this.configService.source };
    return this.http.get(`${this.configService.baseUrl}/api/payment/GET_PAYMENT_TYPE`, { headers: header })
  }

  getFeeDetails(data): Observable<any> {
    const header = { Authorization: `Bearer ${this.token.access_token}`, stuid: data.stuid, Paymentto: this.configService.source, isPaySibling: data.isPaySibling, providerTypeID: data.providerTypeID };
    return this.http.get(`${this.configService.baseUrl}/api/payment/GET_FEE_DETAILS`, { headers: header })
  }

  getPaymentHistory(data): Observable<any> {
    const header = { Authorization: `Bearer ${this.token.access_token}`, STUID: data.stuid, SOURCE: this.configService.source, fromDate: data.fromDate, toDate: data.toDate };
    return this.http.get(`${this.configService.baseUrl}/api/payment/GET_PAYMENT_HISTORY`, { headers: header })
  }

  getPaymentSummary(data): Observable<any> {
    const header = { Authorization: `Bearer ${this.token.access_token}`, STUID: data.stuid, SOURCE: this.configService.source, fromDate: data.fromDate, toDate: data.toDate };
    return this.http.get(`${this.configService.baseUrl}/api/payment/GET_STS_STATEMENT_OF_ACCOUNTS`, { headers: header })
  }
  getEmailSummary(data): Observable<any> {
    const header = { Authorization: `Bearer ${this.token.access_token}`, stuid: data.stuid, SOURCE: this.configService.source, fromDate: data.fromDate, toDate: data.toDate, statementType:'SUMMARY' };
    return this.http.get(`${this.configService.baseUrl}/api/payment/SEND_EMAIL_STATEMENT_OF_ACCOUNTS`, { headers: header })
  }

  getPaymentSummaryDetails(data): Observable<any> {
    const header = { Authorization: `Bearer ${this.token.access_token}`, STUID: data.stuid, SOURCE: this.configService.source, fromDate: data.fromDate, toDate: data.toDate };
    return this.http.get(`${this.configService.baseUrl}/api/payment/GET_STATEMENT_OF_ACCOUNTS_DETAILS`, { headers: header })
  }

  postFeeDetails(data) {
    const header = {
      language: this.configService.language, paymentTo: this.configService.source,
      apdId: this.configService.AppId.toString(),
      activityFeeCollType: this.configService.client,
      redirectURL: this.configService.redirectionURL,
      paymentOrigin: "TRANSPORT_CONNECT_WEB"
    };
    return this.http.post(`${this.configService.baseUrl}/api/Payment/POST_FEE_DETAILS`, data, { headers: header });

  }

  getRefundDetails(): Observable<any> {
    const header = { Authorization: `Bearer ${this.token.access_token}`,StuNo: localStorage.getItem('selectedstudentid')};
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetRefundsData `, { headers: header })
  }

  getReceipt(data:any): Observable<any> {
    const header = { Authorization: `Bearer ${this.token.access_token}`,StudentNo: data.studNo,ReceiptNo:data.receiptNo};
    return this.http.get(`${this.configService.baseUrl}/api/Payment/GetFeeReceiptTemplate`, { headers: header })
  }

  saveRefund(data)
  {
    const header = { Authorization: `Bearer ${this.token.access_token}`}
    return this.http.post(`${this.configService.baseUrl}/api/Transport/SaveRefunds`, data, { headers: header });
  }

  downloadStatementOfAccountsDetailsPdf(data): Observable<any> {
    const header = { Authorization: `Bearer ${this.token.access_token}`, STUID: data.stuid, SOURCE: this.configService.source, fromDate: data.fromDate, toDate: data.toDate, name: data.studentName, schoolName: data.schoolName, grade: data.studentGrade };
    return this.http.get(`${this.configService.baseUrl}/api/payment/GET_STATEMENT_OF_ACCOUNTS_DETAILS_PDF`, { headers: header, responseType: 'blob' })
  }

  downloadStatementOfAccountsSummaryPdf(data): Observable<any> {
    const header = { Authorization: `Bearer ${this.token.access_token}`, STUID: data.stuid, SOURCE: this.configService.source, fromDate: data.fromDate, toDate: data.toDate, name: data.studentName, schoolName: data.schoolName, grade: data.studentGrade };
    return this.http.get(`${this.configService.baseUrl}/api/payment/GET_STATEMENT_OF_ACCOUNTS_SUMMARY_PDF`, { headers: header, responseType: 'blob' })
  }

  getRefundsData(studentIds: any): Observable<any> {
    const header = { Authorization: `Bearer ${this.token.access_token}`, StuType: 'S', mode: 'HEADER', stuId: studentIds, Bsuid: localStorage.getItem('bsU_ID')};
    return this.http.get(`${this.configService.baseUrl}/api/TransportRefund/GET_REFUND_DATA`, { headers: header })
  }

  saveFeeRefund(data:any, attachment:any)
  {
    var formData=new FormData();
    formData.append("refundHeader",data);
    if(attachment!=null){
      formData.append("RefundAttachment",attachment);
    }
    const header = { Authorization: `Bearer ${this.token.access_token}`}
    return this.http.post(`${this.configService.baseUrl}/api/TransportRefund/SaveFeeRefund`, formData, { headers: header });
  }

  getTaxInvoice(data:any): Observable<any> {
    const header = { Authorization: `Bearer ${this.token.access_token}`,ReceiptNo:data.receiptNo};
    return this.http.get(`${this.configService.baseUrl}/api/payment/GetAdvancetaxinvoice`, { headers: header })
  }
}
