import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import { TokenService } from 'src/app/service/token.service';
import { TransportserviceService } from 'src/app/transportservice.service';

import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import {
  AuthenticationService,
  CommonService,
  ServiceinfoService
} from 'src/app/service';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { environment } from '../../../../environments/environment';
import { ConfigService } from 'src/app/service/config.service';
@Component({
  selector: 'app-onlineregistration',
  templateUrl: './onlineregistration.component.html',
  styleUrls: ['./onlineregistration.component.scss'],
  providers: [DatePipe]
})
export class OnlineregistrationComponent implements OnInit {
  portalShortCode:string =this.configService.portalShortCode;
  logoimage:string=this.configService.logoimageUrl;
  @ViewChild('myGoogleMap', { static: false })
  map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false })
  info!: MapInfoWindow;
  zoom = 12;
  maxZoom = 15;
  minZoom = 8;
  latitude:number;
  longitude:number
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'hybrid',
    maxZoom:this.maxZoom,
    minZoom:this.minZoom,
  }
  markers = []  as  any;
  infoContent = ''
  schools: any;
  city: string = ""
  digopen: any;
  school: string = "";
  childerns: any[] = [];
  pickuppoints: [];
  transportTypes: [];
  emirates: [];
  acyear: [];
  sGrades: [];
  selectedchild: any[];
  otherDropoffArea: boolean = false;
  otherPickupArea: boolean = false;
  fName: string = "";
  middleName: string = "";
  lastName: string = "";
  PoBoxNo: string = "";
  HBVNo: string = "";
  sEmirate: any;
  StreetAddress: string = "";
  OfficeTel: string = "";
  OfficeTelCountry: string = "";
  OfficeTelArea: string = "";
  fatherMobileCountry: string = "";
  fatherMobilelArea: string = "";
  fatherTel: string = "";
  motherMobileCountry: string = ""
  motherMobilelArea: string = "";
  motherTel: string = "123456789";
  acdid: number
  acGrade: any;
  EmergencyMobileCountry: string = "";
  EmergencyMobilelArea: string = "";
  Emailaddress: string = "";
  AlternateEmailaddress: string = "";
  EmergencyTel: string = ""
  sfName: string = "";
  smiddleName: string = "";
  slastName: string = "";
  sSchoolId: string = "";
  sgender: string = "Male";
  title: string = "Mr";
  pContact: string = "Father";
  transportStartDate: Date;
  dateofBirth: Date;
  @ViewChild('tptRequest', { static: false }) tptRequest: TemplateRef<any>;
  constructor(private datePipe: DatePipe, private trnsrequest: TransportserviceService, private trn: MatListModule, private route: ActivatedRoute,
    private tokenService: TokenService, private dialog: MatDialog, private serviceinfoservice: ServiceinfoService,
    private configService:ConfigService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: 25.104565,//position.coords.latitude,
        lng: 55.17231//position.coords.longitude,
      }
    })
    this.school = localStorage.NGSchoolName;
    this.getMasers();

  }
  OpenTransportRequest(item) {
    this.childerns = JSON.parse(localStorage.parentChildrens);
    this.selectedchild = this.childerns.filter(function (child) {
      return child.studentId == item.studentNo;
    });
    this.school = this.selectedchild[0].school;
    // this.getMasers();
    // this.openDialog(this.tptRequest);
  }
  openDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      disableClose: true,
      width: '800px'
    });

  }
  closedialog() {
    this.digopen.close();
  }
  getMasers() {
    let tkn = "";
    this.tokenService.GetToken("").subscribe(tknresult => {

      tkn = tknresult.access_token;
      let obj: any = {
        headers: {
          "master": "AREA_M_TPT",
          "param1": "172",
          "bsuid": localStorage.NGSBsuid,
          "acdid":localStorage.getItem("currentAcademicYearId"),
          "Authorization": "Bearer " + tkn

        }

      }

      let obj1: any = {
        headers: {
          "master": "TPT_TYPE",
          "bsuid": localStorage.NGSBsuid,
          "Authorization": "Bearer " + tkn
        }
      }
      let obj2: any = {
        headers: {
          "master": "CITY_M",
          "param1": "172",
          "Authorization": "Bearer " + tkn
        }
      }
      let obj3: any = {
        headers: {
          "master": "TPT_SERVICE_ACADEMIC_YEAR",
          "bsuid": localStorage.NGSBsuid,
          "Authorization": "Bearer " + tkn
        }
      }
      let obj4: any = {
        headers: {
          "master": "GRADE_M",
          "acdid": "1993",
          "bsuid": localStorage.NGSBsuid,
          "Authorization": "Bearer " + tkn
        }
      }
      let otherpoint = {
        "descr": "Other",
        "id": 0
      };
      this.serviceinfoservice.getMaster(obj1).subscribe(res => {
        this.transportTypes = res["data"];
      });
      this.serviceinfoservice.getMaster(obj).subscribe(res => {

        res["data"].push(otherpoint);
        this.pickuppoints = res["data"];
      });
      this.serviceinfoservice.getMaster(obj2).subscribe(res => {
        this.emirates = res["data"];
      });
      this.serviceinfoservice.getMaster(obj3).subscribe(res => {
        this.acyear = res["data"];
      });
      this.serviceinfoservice.getMaster(obj4).subscribe(res => {
        this.sGrades = res["data"];
      });
    });


  }
  PickupAreaChange(val) {

    if (val.value == "0") {
      this.otherPickupArea = true;
    }
    else {
      this.otherPickupArea = false;
    }
  }
  DropoffAreaChange(val) {

    if (val.value == "0") {
      this.otherDropoffArea = true;
    }
    else {
      this.otherDropoffArea = false;
    }
  }

  SaveTransportRequest() {
    let tkn = "";
    this.tokenService.GetToken("").subscribe(tknresult => {

      tkn = tknresult.access_token;
      let objtkn: any = {
        headers: {

          "Authorization": "Bearer " + tkn

        }

      }
      let obj: any = {

        "bsuId": localStorage.NGSBsuid,
        "startDate": this.datePipe.transform(this.transportStartDate, "yyyy-MM-dd"),// "2022/09/06",
        "AcdId": this.acdid,
        "stuFirstName": this.sfName,
        "stuMidName": this.smiddleName,
        "stuLastname": this.slastName,
        "stuDOB": this.datePipe.transform(this.dateofBirth, "yyyy-MM-dd"),
        "stuGender": this.sgender,
        "medCondition": "",
        "sblID": 548,
        "pntID": 0,
        "sblIDDropOff": 0,
        "pntIdDropOffPO": 0,
        "primaryContact": this.pContact,
        "title": this.title,
        "pFirstName": this.fName,
        "pMidName": this.middleName,
        "pLastName": this.lastName,
        "poBox": this.PoBoxNo,
        "emirate": this.sEmirate,
        "houseNo": this.HBVNo,
        "address": this.StreetAddress,
        "offPhone": this.OfficeTelCountry + this.OfficeTelArea + this.OfficeTel,
        "resPhone": this.EmergencyMobileCountry + this.EmergencyMobilelArea + this.EmergencyTel,
        "mobilePhone": this.fatherMobileCountry + this.fatherMobilelArea + this.fatherTel,
        "motherMobile": this.motherMobileCountry + this.motherMobilelArea + this.motherTel,
        "emergencyMobile": this.EmergencyMobileCountry + this.EmergencyMobilelArea + this.EmergencyTel,
        "email": this.Emailaddress,
        "altEmail": this.AlternateEmailaddress,
        "bSendEmail": true,
        "bSMS": true,
        "pickUpLocation": "",
        "regFrom": "",
        "otherPickUp": this.otherPickupArea,
        "otherDropOff": this.otherDropoffArea,
        "imageLoc": "",
        "id": "",
        "mtitle": "test",
        "mfirstname": this.fName,
        "mmidname": this.middleName,
        "mlastname": this.lastName,
        "mresphone": this.motherTel,
        "memail": this.Emailaddress,
        "gfullname": "test",
        "makani": "test"

      }



      this.serviceinfoservice.SaveNonGemsTransportRequest(obj, objtkn).subscribe(res => {
        let r = res;
        this.snackBar.open('Your Transport request successfully registered, Thank you for using '+this.portalShortCode+' Transport System. ', 'Ok', {

          panelClass: ['nt', 'nt_success'],
          horizontalPosition: "center",
          verticalPosition: "top",

        });
      });
    })
  }
  eventHandler(event: any ,name:string){
    console.log(event,name);
    
    // Add marker on double click event
    if(name === 'mapDblclick' || name==='mapClick'){
      this.dropMarker(event)
    }
  }
  dropMarker(event:any) {
    this.latitude=event.latLng.lat();
    this.longitude=event.latLng.lng();
    this.markers=[];
    this.markers.push({
      position: {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      },
      label: {
        color: 'blue',
        text: 'Marker label ' + (this.markers.length + 1),
      },
      title: 'Marker title ' + (this.markers.length + 1),
      info: 'Marker info ' + (this.markers.length + 1),
      options: {
        animation: google.maps.Animation.DROP,
      },
    })
  }
  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker)
  }
  zoomIn() {
    if (this.zoom < this.maxZoom) this.zoom++;
    console.log('Get Zoom',this.map.getZoom());
  }

  zoomOut() {
    if (this.zoom > this.minZoom) this.zoom--;
  }

}

