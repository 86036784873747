import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutTopNavComponent } from './layout-top-nav.component';
import { MatIconModule } from '@angular/material/icon';
import { FooterComponent } from '../footer/footer.component';
import {MatMenuModule} from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
@NgModule({
  declarations: [LayoutTopNavComponent, FooterComponent],
  exports: [LayoutTopNavComponent, FooterComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatSelectModule
  ]
})
export class LayoutTopNavModule { }
