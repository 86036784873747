import { NgModule,APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './core/http-request-interceptor';
import { ErrorInterceptor } from './core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ServiceinfoComponent } from './features/serviceinfo/serviceinfo.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LayoutTopNavModule } from './core/layout-top-nav/layout-top-nav.module';
import { MatListModule } from '@angular/material/list';
import { TransportrequestComponent } from './features/transportrequest/transportrequest.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { OnlineregistrationComponent } from './features/transportrequest/onlineregistration/onlineregistration.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { GoogleMapsModule } from '@angular/google-maps';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ForgotPasswordComponent } from './features/login/forgot-password/forgot-password.component';
import {MatButtonModule} from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { RequestScheduleChangeComponent } from './features/home/schedule-change/request-schedule-change/request-schedule-change.component';
import { TransportInformationComponent } from './features/home/transport-information/transport-information.component';
import { HomeModule } from './features/home/home.module';
import { LandingModule } from './landing-page/landing-page.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {MatTabsModule} from '@angular/material/tabs';
import { RequestCallbackComponent } from './features/home/request-callback/request-callback.component';
import {MatMenuModule} from '@angular/material/menu';
import { MessageComponent } from './message/message.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { AgmCoreModule } from '@agm/core';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { MyaccountComponent } from './features/myaccount/myaccount.component';
import { SupportComponent } from './features/menu/support/support.component';
import { MenuModule } from './features/menu/menu.module';
import { MatTooltipModule} from '@angular/material/tooltip';
import { PaymentsModule } from './features/payments/payments.module';
import { NotificationModule } from './features/notification/notification.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ConfigService } from './service/config.service';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { SmartbusComponent } from './features/smartbus/smartbus.component';
import { environment } from 'src/environments/environment';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LayoutComponent } from './shared/layout/layout.component';
import { AutoPromoLayoutComponent } from './shared/AutoPromoLayout/autoPromoLayout.component';
import { CommonModulesModule } from './common-modules/common-modules.module';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
register();
export function initializeApp(configService: ConfigService) {
  return () => configService.loadConfig();
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    ServiceinfoComponent,
    TransportrequestComponent,
    OnlineregistrationComponent,
    LandingPageComponent,
    ForgotPasswordComponent,
    RequestScheduleChangeComponent,
    TransportInformationComponent,
    RequestCallbackComponent,
    MessageComponent,
    MyaccountComponent,
    SupportComponent,
    SmartbusComponent,
    AutoPromoLayoutComponent



  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    CommonModule,
    AppRoutingModule,
    NgxSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatPaginatorModule,
    MatListModule,
    LayoutTopNavModule,
    MatButtonModule,
    HomeModule,
    PaymentsModule,
    LandingModule,
    NotificationModule,
    CarouselModule,
    MatTabsModule,
    MatDialogModule,
    MatMenuModule,
    MenuModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient]
      }
    })
    , NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSnackBarModule,
    Ng2TelInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA1iYoEmhopACwr8vAOLOO3KSOHvMERD-E',
      libraries: ['places']
    }),
    GoogleMapsModule,
    PdfViewerModule,
    NgxMatIntlTelInputModule,
    NgxMaterialTimepickerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    CommonModulesModule
  ],
  exports: [
    MatTableModule,
    CommonModule,
    MatMenuModule
  ],
  providers: [ConfigService,LocalizationService,
    { provide: L10N_PREFIX, useValue: 'kendo' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true

    },
    {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpRequestInterceptor,
    multi: true,

  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  },{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      // siteKey:environment.captchaAPIKey,
    } as RecaptchaSettings,
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
