import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-graph-widget',
  templateUrl: './graph-widget.component.html',
  styleUrls: ['./graph-widget.component.scss']
})
export class GraphWidgetComponent implements OnInit,AfterViewInit {
emission:any;
  isLeasedSchool: any;
  ispaymentcontent: boolean =false;
  constructor(public dashboardService:DashboardService) { }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dashboardService.getCO2Emission().subscribe((res:any)=>{
        let data:any = localStorage.getItem('leasemodelschool')
        this.isLeasedSchool = data
        let treesLength =res.data[0]?.trees?.toString().length;
        if(res.data[0].trees)
        {
          let leastNumber = Math.pow(10,treesLength-1)
          let dividedTrees = res.data[0]?.trees;
          // res.data[0].trees= treesLength == 6 ? dividedTrees+" Lakhs" : treesLength == 7 ? dividedTrees+" Million":treesLength == 8 ? dividedTrees+" Billion": treesLength == 9 ? dividedTrees+" Trillion":dividedTrees+" Thousand"
          res.data[0].trees= dividedTrees;
        if(treesLength >=5)
        {
        //  let leastNumber = Math.pow(10,treesLength-1)
        //   let dividedTrees = res.data[0]?.trees / leastNumber;
        //   if (dividedTrees % 1 !== 0) {
        //     dividedTrees = parseFloat(dividedTrees.toFixed(1));
        // }
          
        }else {
           
        }
      }else{
        res.data[0].trees=0;
      }
        let co2Length =res.data[0]?.co2?.toString().length;
        if(res.data[0].co2 && res.data[0].co2 != "0")
        {
        if(co2Length >=4)
        {
        let dividedCo2 = res.data[0]?.co2;
        if (dividedCo2 % 1 !== 0) {
          dividedCo2 = parseFloat(dividedCo2.toFixed(1));
        }
        res.data[0].co2= dividedCo2;
        }
      }else res.data[0].co2=0;
        this.emission = res?.data[0]
     }) 
    }, 1000);
  }

  ngOnInit(): void {
    // this.dashboardService.isPaymentContent.subscribe(res=>{
    //   this.ispaymentcontent=res;
    // })
  }
 
}
