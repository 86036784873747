import { Component, DoCheck, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService, CommonService } from 'src/app/service';
import { ConfigService } from 'src/app/service/config.service';

@Component({
  selector: 'app-autoPromoLayout',
  templateUrl: './autoPromoLayout.component.html',
  styleUrls: ['./autoPromoLayout.component.scss']
})

export class AutoPromoLayoutComponent implements OnInit, DoCheck {
  digopen:any;
  contactDetails: any;
  token: any;
  logoimage: string = this.configService.logoimageUrl;
  public oluName: string;
  public selectedstudentid:any;
  isLeasedSchool: any;
  isHybridSchool: any;
  userProfileImage: any;
  studentName: any;
  constructor(
    private configService:ConfigService,
    private auth: AuthenticationService,
    private router: Router, private dialog: MatDialog) {
    this.oluName = this.auth.currentUser.userDisplayName;
  }
  ngOnDestroy() {
  }
  ngDoCheck(): void {
    let data:any = localStorage.getItem('leasemodelschool')
    this.isLeasedSchool = data;
    let isHybrid:any = localStorage.getItem('IsHybrid')
    this.isHybridSchool = isHybrid;

  }

  ngOnInit(): void {
    this.getProfileImage();
  }

  getProfileImage()
  {
    let selectedStudent = localStorage.getItem("selectedstudentid");
    let transportChildInfo = JSON.parse(localStorage.getItem("transportChildInfo"));

    let childInfo = transportChildInfo.filter((x:any) => x.studentNo == selectedStudent);

    this.userProfileImage = childInfo[0]?.photoPath === '' || childInfo[0]?.photoPath == null ? "assets/img/no-avatar.svg" : childInfo[0]?.photoPath;
    this.studentName = childInfo[0]?.studentName;
  }
  ngAfterViewInit(): void {
    
  }

  goToHome(){
    this.router.navigate(['/home']);
  }
  
  public logOut($event): void {
    $event.stopPropagation();
    this.auth.logout();
    localStorage.clear();
    setTimeout(() => {                           //<<<---using ()=> syntax
      this.router.navigate(['/login']);
    }, 1000);
    this.dialog.closeAll()
    // this.router.navigate(['/'])
    //   .then(() => {
    //     window.location.reload();
    //   });
  }

  public handleMissingImage(event: Event) {
    // (event.target as HTMLImageElement).style.display = 'none';
    (event.target as HTMLImageElement).src = '../../../assets/img/no-avatar.svg';
  }

  onImageError(){
    return "assets/images/social-icons/twitter.svg"
  }

  
  moreMenuDialog(template: TemplateRef<any>) {
    this.digopen = this.dialog.open(template, {
      disableClose: true,
      width: '500px',
      panelClass: 'mobile-menu-modalbox'
    });
    if (this.digopen['_overlayRef'].overlayElement) {
      this.digopen['_overlayRef'].overlayElement.parentElement.className += ' dialog-wrapper';
    }
  }
  close(){
    this.dialog.closeAll()
  }
}
