import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Chatbot } from "../models/app-config";
import { ConfigService } from "./config.service";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  
  chatbotSettings:Chatbot;
  constructor(private http: HttpClient,private configService:ConfigService) { 
      this.chatbotSettings=this.configService.chatbotSetting;
      if(this.token==''){
        this.getToken();
      }
  }
  public token:any='';
  public phoenixToken:any='';
  public phoenixTokenExpired=false;
  
  setPhoenixToken(data:any){
    this.phoenixToken=data.access_token;
    //call timeout 2 mins before token expiry
    let timeout= (data.expires_in * 1000) - (120*1000);
    setTimeout(() => {
      this.phoenixTokenExpired=false
    }, timeout);
  }
  private tokenRequestBody() {
      let formData:FormData=new FormData();
      formData.append('username',this.chatbotSettings.name);
      formData.append('password',this.chatbotSettings.secret);
      formData.append('grant_type',this.chatbotSettings.grantType);
      formData.append('app_name',this.chatbotSettings.appName);

      return formData;
  }

  private get tokenRequestHeader() {
      return {
          headers: {
              "Authorization": `Basic ${this.chatbotSettings.key}`
          }
      };
  }

  getToken(){
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${this.chatbotSettings.tokenUrl}/o/token/`, this.tokenRequestBody(), this.tokenRequestHeader).subscribe(res=>{
        this.token=res?.access_token;
        resolve(true);
      },err=>{
        resolve(false);
      });      
    })
  }

  postChat(data:any){
    let options={
      headers: {
          "Authorization": `Basic ${this.token}`
      }
    }
    return this.http.post<any>(`${this.chatbotSettings.apiUrl}/StsBot/`,data,options);
  }
  
  getServerSentEvent(sessionId: string): Observable<any> {
    return new Observable((observer:any) => {
      let url=`${this.chatbotSettings.apiUrl}/StsBot/chatbot/?session_id=${sessionId}&stream=true`
      
      const eventSource = new EventSource(url);
      eventSource.onmessage = event => {
          observer.next(event);
          var data=JSON.parse(event.data);
          if(data?.end_stream){
            eventSource.close();
          }
      };
      eventSource.onerror = error => {
          observer.error(error);
          eventSource.close();
      };
    });
  }

  getChatHistory(userName:any): Observable<any> {
    const header = {USER_NAME:userName,HideSpinner:"true"};
    return this.http.get(`${this.configService.baseUrl}/api/transport/GET_CHATBOT_HISTORY`,{headers:header});
  }

  postChatHistory(data:any): Observable<any> {
    const header = {HideSpinner:"true"};
    return this.http.post(`${this.configService.baseUrl}/api/transport/SAVE_CHATBOT_HISTORY`,data,{headers:header});
  }
}