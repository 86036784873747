import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.scss']
})
export class ReceiptsComponent implements OnInit {
  type: string='';
  id: string='';
  src: string='';
  transactionStatusMessage: any;
  paymentDetails: any;
  transactionStatusCode: any;
  idCardPayment: any = "0";
  today = new Date()
  constructor(private commonservice:CommonService) { }

  ngOnInit(): void {

    this.idCardPayment = localStorage.getItem('idCardPayment');

    let data = decodeURIComponent(window.location.href).split('?');
    let finaldata = data.slice(1).toString();
    if(finaldata.length > 0){
      this.type = finaldata.split('&')[0]?.split('=')[1].concat('=')
      this.id = finaldata.split('&')[1]?.split('=')[1].concat('=')
      this.src = finaldata.split('&')[2]?.split('=')[1].concat('=');
      this.getTransportFeeDEtails()
    }else{
      this.type = ''
    this.id = ''
    this.src = ''
    }
  }
  getTransportFeeDEtails(){
    let data:any;
    data={
      type:this.type,PaymentRefNo:localStorage.getItem('postPaymentRefNo'),
    }
    
          this.commonservice.getTransportFeeDetails(data).subscribe(res=>{
            this.transactionStatusMessage = res?.data?.transactionStatusMessage
            if(res?.success == "true"){
              //this.openPaymentDialog(this.showReceipt);
              this.transactionStatusCode = res?.data?.transactionStatusCode
              this.paymentDetails = res?.data?.schoolFeePaymentResultDetails
            }

            if(this.idCardPayment != null && this.idCardPayment != "0"){
              localStorage.removeItem('idCardPayment');
              let payload:any;
              payload = {
                "SSR_ID": localStorage.getItem('idCardRefNo'),
                "PAYMENT_STATUS": res?.success,
                "PAYMENT_REFNO": localStorage.getItem('postPaymentRefNo')
              }
              this.commonservice.updateIdCardPayment(payload).subscribe(res=>{

              });
            }
          })
  }
}
