import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../service/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize, map } from 'rxjs/operators';
import { ConfigService } from '../service/config.service';
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService, private spinner: NgxSpinnerService,
        private configService:ConfigService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentToken = this.authenticationService.currentToken;
        const isClientLoggedIn = currentToken && currentToken.access_token;
        const isApiUrl = request.url.startsWith(this.configService.baseUrl);
        const hideSpinner=request.headers.get('HideSpinner') =='true' ? true : false;
        if (isClientLoggedIn && isApiUrl) {
            if(!hideSpinner){
                this.spinner.show();
            }
            else{
                request = request.clone({
                    headers: request.headers.delete("HideSpinner")
                });
            }
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentToken.access_token}`
                }
            });
        }

        return next.handle(request)
            .pipe(
                finalize(() => this.spinner.hide()),
                catchError((err) => {
                    this.spinner.hide();
                    return err;
                }),

            )
            .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
                if (evt instanceof HttpResponse) {
                    this.spinner.hide();
                }
                return evt;
            }));
    }
}