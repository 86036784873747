import { Component,Inject, OnInit, Renderer2, TemplateRef} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TransportserviceService } from 'src/app/transportservice.service';
import { FormGroup, FormsModule,FormBuilder, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MessageComponent } from 'src/app/message/message.component';
import * as _moment from 'moment';
import { Router } from '@angular/router';
const moment = _moment;


@Component({
  selector: 'app-schedule-change',
  templateUrl: './schedule-change.component.html',
  styleUrls: ['./schedule-change.component.scss'],
  providers: [DatePipe]
})
export class ScheduleChangeComponent implements OnInit {
  
  studentId: any;
  schoolId: any;
  grade: any;
  digopen: any;
  deletedData: any;
  editData: any;
  options: any[]=[];
  section: any;
  constructor(public transportService:TransportserviceService,private _snackBar: MatSnackBar,private fb: FormBuilder,private datePipe: DatePipe, private dialog: MatDialog,private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private router: Router) { }
  sname:string;
  school:string;
  imagePath:string;
  scheduleList:any[]=[];
 
  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'rm-body-height');
    var root = document.getElementsByTagName( 'html' )[0];
    this.renderer.addClass(root, 'rm-body-height');
    this.getScheduleList();
  }
  getScheduleList()
  {
    let token=JSON.parse(localStorage.getItem('currentToken'));
    this.transportService.getTransportTypes().subscribe(res => {
      this.options = res["data"];
    this.transportService.GetScheduleList(token?.access_token).subscribe((res:any)=>{

      this.sname=JSON.parse(localStorage.parentChildrens)[0].studentName;
      this.school=JSON.parse(localStorage.parentChildrens)[0].school;
      this.imagePath=JSON.parse(localStorage.parentChildrens)[0].photoPath;
      this.studentId=JSON.parse(localStorage.parentChildrens)[0].studentId;
      this.grade=JSON.parse(localStorage.parentChildrens)[0].grade;
      this.section=JSON.parse(localStorage.parentChildrens)[0].section;

      let studentTransportInfo = JSON.parse(localStorage.getItem('transportChildInfo'));
      let selectedStudentId = localStorage.getItem('selectedstudentid');
      let selectedStudentData = studentTransportInfo?.filter((x: any) => x.studentNo == selectedStudentId);

      this.schoolId = selectedStudentData[0]?.stU_SCHOOL_ID;

      this.scheduleList=[];
    res?.data.forEach(x=>{
      //FromTime
    const parsedFromTime = new Date(x.rpdFromDate);
    const formattedDate = this.datePipe.transform(parsedFromTime, 'dd MMM yyyy')
    const fromHour = parsedFromTime.getHours();
    const fromMinute = parsedFromTime.getMinutes();
    const fromAmPm = fromHour >= 12 ? 'PM' : 'AM';
    const fromFormattedHour = fromHour % 12 || 12; 
    x.rpdFormattedFromDate = `${formattedDate}`;
    x.formattedFromTime=`${fromFormattedHour}:${fromMinute < 10 ? '0' + fromMinute : fromMinute} ${fromAmPm}`
 
    //ToTime
    const parsedToTime = new Date(x.rpdToDate);
    const formattedToDate = this.datePipe.transform(parsedToTime, 'dd MMM yyyy')
    const toHour = parsedToTime.getHours();
    const toMinute = parsedToTime.getMinutes();
    const toAmPm = toHour >= 12 ? 'PM' : 'AM';
    const toFormattedHour = toHour % 12 || 12; 
    x.rpdFormattedToDate = `${formattedToDate} ${toFormattedHour}:${toMinute < 10 ? '0' + toMinute : toMinute} ${toAmPm}`;
    x.formattedToTime = `${toFormattedHour}:${toMinute < 10 ? '0' + toMinute : toMinute} ${toAmPm}`

    x.pickup=this.options.find(y=>y.id == x?.rpdJourney)?.descr;
    this.scheduleList=res?.data;
    })
    })
  })
  }
  navigatetolink(lnk,editData:any=null) {
    this.transportService.editSchedule.next(editData)
    this.router.navigate([lnk]);
    this.dialog.closeAll()
  }

  confirmDelete()
  {
    this.digopen.close();
    this.deletedData.editable ="No"
    this.deletedData.delete="Yes"
    let studId= localStorage.getItem("selectedstudentid")
    this.deletedData.rpdStuID=studId;
    this.transportService.RequestSchedulechange(this.deletedData).subscribe(res=>{
      if(res.Success=="true")
      {
       this.openMessageDialog("Request deleted successfully.",false,"");
      }
      else{
       this.openMessageDialog(res?.Message,false,"");
      }
    })
  }

  openMessageDialog(msg:string,iseror:boolean,errormessage:string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      data: {
        msg: msg,
        isError:iseror,
        errmessage: errormessage,
      },
      panelClass: 'custom-modalbox',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getScheduleList();
    });
  }

  cancelDelete()
  {
    this.digopen.close();
  }

  openDialog(template: TemplateRef<any>, deleteData:any) {
    this.deletedData=deleteData;
    this.digopen = this.dialog.open(template, {
      disableClose: true,
      width: '500px',
      panelClass: ['custom-modalbox']
    });
  }
}
