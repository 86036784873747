import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-notification-starred',
  templateUrl: './notification-starred.component.html',
  styleUrls: ['./notification-starred.component.scss']
})
export class NotificationStarredComponent implements OnInit {
@Input('notificationStarredList') notificationStarredList;
@Output() alertUpdate:EventEmitter<any>= new EventEmitter()
  modifiedMessages:any[]=[];
  checkAll:boolean=false;
  enableBulks:boolean=false;
  notificationType: any;
  totalcount: number;
  constructor(private notificationService: NotificationService,private datePipe: DatePipe,public router:Router) { }

  ngOnInit(): void {
    console.log(this.notificationStarredList);
    this.notificationStarredList.forEach(ele => {
      ele.isChecked = false;
    })

  }
  
  ngOnChanges():void
  {
    this.getMessages();
  }

  getMessages()
  {
    this.checkAll=false
    this.totalcount=0;
    this.notificationStarredList.forEach(x=>{
      const parsedTime = new Date(x.notification_Date);
      const formattedDate = this.datePipe.transform(parsedTime, 'dd MMM yyyy')
      const Hour = parsedTime.getHours();
      const Minute = parsedTime.getMinutes();
      const AmPm = Hour >= 12 ? 'PM' : 'AM';
      const FormattedHour = Hour % 12 || 12; 
      x.notificationFormattedDate = `${formattedDate}`
      x.notificationFormattedTime=`${FormattedHour}:${Minute < 10 ? '0' + Minute : Minute} ${AmPm}`
      x.isChecked=false;
    })
    this.modifiedMessages=[... this.notificationStarredList]
  }

  checkBoxSelection(type:any)
  {
    if(type == 'all')
    {
      let tempList=this.modifiedMessages;
      this.enableBulks = this.checkAll ? true : false;
      tempList.forEach(x=>{
        x.isChecked = this.checkAll ? true : false;
      })
      let data:any[];
      data= this.modifiedMessages.filter(x=>x.isChecked == true)
      this.modifiedMessages=[...tempList];
      if(this.modifiedMessages.length == data.length ){
        this.checkAll = true
      }else{
        this.checkAll = false
      }
    }
    else{
      let count=0;
      this.modifiedMessages.forEach(x=>{
       count++;
      })
      let data:any[];
      data= this.modifiedMessages.filter(x=>x.isChecked == true)
      if(this.modifiedMessages.length == data.length ){
        this.checkAll = true
      }else{
        this.checkAll = false
      }
    }
    this.totalcount = this.modifiedMessages.filter(x=>x.isChecked == true).length
  }

  updateNotification(type:any)
  {
   this.notificationType = type;
   let postmodel={};
   let data:any[];
    data= this.modifiedMessages.filter(x=>x.isChecked == true)
    //data= this.modifiedMessages
    postmodel={
      type:this.notificationType,
      data:data,
      isBulk:true
    }
    this.alertUpdate.emit(postmodel)
  }
  updateNotificationType(type, selecteddata,res?:any){
    let postmodel={};
    let data:any[]=[];
     data.push(selecteddata)
     postmodel={
       type:type,
       data:data,
       res:res ? res:'',
       isBulk:false
     }
     this.alertUpdate.emit(postmodel)

  }

  navigateUrl(notification:any)
  {
    if(notification.categorY_CODE== "URD")
    {
      window.open(notification.notification_Url, '_blank')
    }
    else if (notification.categorY_CODE== "FEP")
    {
      this.router.navigate(['/payment-detail'])
    }
  }
}
