import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private storedFile: File | null = null;

  setFile(file: File): void {
    this.storedFile = file;
  }

  getFile(): File | null {
    return this.storedFile;
  }

  clearFile(): void {
    this.storedFile = null;
  }
}
