import { Component,Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentInformationComponent } from './student-information/student-information.component';
import { ParentInformationComponent } from './parent-information/parent-information.component';
import { ResidentialInformationComponent } from './residential-information/residential-information.component';
import { MatDialog } from '@angular/material/dialog';
import { ReactivateStudentInformationComponent } from './reactivate-student-information/reactivate-student-information.component';

@Component({
  selector: 'app-schoolbus-registration',
  templateUrl: './schoolbus-registration.component.html',
  styleUrls: ['./schoolbus-registration.component.scss']
})
export class SchoolbusRegistrationComponent implements OnInit {
  IsParentTab: boolean=false;
  IsStudentTab: boolean=false;
  IsResidentTab: boolean=false;
  IsTCTab: boolean=false;
  digopen: any;
  isReactivate:boolean = false;
  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,private router: Router,private activatedRoute:ActivatedRoute,private dialog: MatDialog) { }
 selectedTab:number=0;
 @ViewChild("studentInfo") studentInfo:StudentInformationComponent
 @ViewChild("reactivateStudentInfo") reactivateStudentInfo:ReactivateStudentInformationComponent
 @ViewChild("parentInfo") parentInfo:ParentInformationComponent
 @ViewChild("residentaddress") residentaddress: ResidentialInformationComponent
 //isTabYes: any
 isAgree:boolean=false;
  ngOnInit(): void {
    this.dialog.closeAll();
    this.renderer.addClass(this.document.body, 'rm-body-height');
    var root = document.getElementsByTagName( 'html' )[0];
    this.renderer.addClass(root, 'rm-body-height');
    this.activatedRoute.queryParams.subscribe(params=>{
      if(params?.isAgreed)
      {
        this.isAgree = params.isAgreed == "false" ?false:true;
        this.selectedTab=params.tab ;
      }
     else if(params?.isFromDashboard){
      this.selectedTab=params.tab;
     // this.tabYes(true);
     }

     if(params?.isReactivate){
      this.isReactivate=params.isReactivate == 'false' ? false : true;
     }
     this.setStyle(this.selectedTab)
    })

  }

  goToLanding(){
    this.router.navigate(['/home']);
  }
  // tabYes(event:any)
  // {
  //   this.isTabYes=event;
  // }
  onTabChange(event:any)
  {
    this.selectedTab=event;
    this.setStyle(this.selectedTab)
  }

  setStyle(event:any)
  {
    if(event == 0){
      this.IsParentTab = false;
      this.IsStudentTab = false;
      this.IsResidentTab = false;
      this.IsTCTab = false;
    }
    if(event == 1){
      this.IsParentTab = true;
      this.IsStudentTab = false;
      this.IsResidentTab = false;
      this.IsTCTab = false;
    }
    if(event == 2){
      this.IsParentTab = true;
      this.IsStudentTab = true;
      this.IsResidentTab = false;
      this.IsTCTab = false;
    }
    if(event == 3){
      this.IsParentTab = true;
      this.IsStudentTab = true;
      this.IsResidentTab = true;
      this.IsTCTab = false;
    }
  }
  gotoLogin(){
    this.router.navigate(['/login']);

  }
}
