import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class GoogleMapsAutocompleteService {
  private autocompleteService: google.maps.places.AutocompleteService;
 
  constructor() {
    this.autocompleteService = new google.maps.places.AutocompleteService();
  }
 
  getPlacePredictions(input: string): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable((observer: Observer<google.maps.places.AutocompletePrediction[]>) => {
      this.autocompleteService.getPlacePredictions({ input }, (predictions) => {
        observer.next(predictions);
        observer.complete();
      });
    });
  }
}