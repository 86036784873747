import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/service/payment.service';

@Component({
  selector: 'app-statement-of-accounts',
  templateUrl: './statement-of-accounts.component.html',
  styleUrls: ['./statement-of-accounts.component.scss']
})
export class StatementOfAccountsComponent implements OnInit {

 
  fromdate: string;
  todate: string;
  excessAmt: any;
  selectedTab:number=0;
  studentId: any;
  disableRefund: boolean = true;

  constructor(private datePipe: DatePipe, private router:Router,public paymentService:PaymentService, private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      this.studentId = params?.id
    });

    let transportInfo =JSON.parse(localStorage.getItem("transportChildInfo"));
    let stuIds: any[] = [];
    transportInfo.forEach((x:any) => {
      stuIds.push(x.stU_ID)
    });
    let studentIds = stuIds.join(',');
    this.paymentService.getRefundsData(studentIds).subscribe((res:any)=>{
      if(res?.length > 0){
        res?.forEach((refund:any) => {
          if(refund?.fee?.toLowerCase() == "total" && refund?.amount >= 100){
            //this.excessAmt=refund?.amount;
            this.disableRefund = false
          }
        });
      }
    });

    let date= new Date();
    date = new Date(date.getFullYear(), new Date(date).getMonth(), new Date(date).getDate() - 90);
    this.fromdate = this.datePipe.transform(new Date(date), 'yyyy-MM-dd');    this.todate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }
  changeDates(event:any)
  {
    this.fromdate =event.fromDate;
    this.todate=event.toDate;
  }
  changeServiceDate(event, value) {
    let serviceDate = event.value._d;
    let data: any;
    if (value == 'fdate') {
      this.fromdate = this.datePipe.transform(serviceDate, 'yyyy-MM-dd');
    } else {
      this.todate = this.datePipe.transform(serviceDate, 'yyyy-MM-dd');
    }
  }
  
  clear() {
    this.todate = '';
    this.fromdate = '';
  }

  navigatetolink(lnk) {
    //this.paymentService.excessAmt.next(this.excessAmt)
    this.router.navigate([lnk],{queryParams:{id:this.studentId}});
  }

  onTabChange(event:any)
  {
    this.selectedTab=event;
  }

  downloadAsPDF(){
    if(this.selectedTab == 0){
      this.paymentService.downloadDetailsPDF.next("1");
    }else{
      this.paymentService.downloadSummaryPDF.next("1");
    }
  }
}
