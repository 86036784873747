import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SchoolsListComponent } from './schools-list/schools-list.component';
import { SchoolbusRegistrationComponent } from './schoolbus-registration/schoolbus-registration.component';
import { ViewTermsAndConditionsComponent } from './schoolbus-registration/terms-and-conditions/view-terms-and-conditions/view-terms-and-conditions.component';
import { UaepassCallbackComponent } from './schoolbus-registration/terms-and-conditions/uaepass-callback/uaepass-callback.component';

const routes: Routes = [
  {
    path: 'schools-list',
    component: SchoolsListComponent
  },
  {
    path: 'schoolbus-registration',
    component: SchoolbusRegistrationComponent
  },
  {
    path: 'terms-and-conditions',
    component: ViewTermsAndConditionsComponent
  },
  {
    path: 'uaepass_callback',
    component: UaepassCallbackComponent
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SchoolBusRegistrationRoutingModule { }
