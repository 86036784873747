import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RegistrationService } from './registration.service';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class PdfserviceService {

  constructor(private http: HttpClient,public regservice:RegistrationService) {}
  getPdf(url: string): Observable<ArrayBuffer> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/pdf' });
    return this.http.get(url, { responseType: 'arraybuffer', headers });
  }
  downloadPdf(token:any,pdfscr:string,filename:string) {
    this.regservice.DownloadPDF(token, pdfscr).subscribe(
      (res: any) => {
        this.saveFile(res,filename);
      },
      (error) => {
        console.error('Error downloading PDF:', error);
      }
    );
  }

  private saveFile(response: any,filename:string): void {
    const blob = new Blob([response], { type: 'application/pdf' });
    saveAs(blob, filename);
  }

}
