//var envValues=values;
export const environment = {
  production: false,
 
photopath:"",
applicationUrl:"http://localhost:4200",
//photopath:"https://qa.phoenixsystem.ae/phoenixphotos/OASIS_HR/Applicantphoto/",
  api: {
    token: 'token',
    login: 'api/login',
    PARENT_PORTAL_URL: 'api/masters/GetMenusforParentPortal',
    CHILDREN_BY_PARENT_URL: 'api/parent/GET_CHILDREN',
    GetMenusforParentPortal: 'api/masters/GetMenusforParentPortal',
    GetChildren: 'api/parent/GET_CHILDREN',
    GetServiceInfo: 'api/transport/GetStudentsTransportInfo',
    GetPaymentHystory: 'api/payment/GET_PAYMENT_HISTORY',
    getStatementofAccounts: 'api/payment/GET_STATEMENT_OF_ACCOUNTS_DETAILS',
    getStatementofAccountsSummary: 'api/payment/GET_STATEMENT_OF_ACCOUNTS_SUMMARY1',
    getPaymentType: 'api/payment/GET_PAYMENT_TYPE',
    getFeeDetails: 'api/payment/GET_FEE_DETAILS',
    getOutstandingFeeDetails: 'api/payment/GetTransportFeeOutstanding',
    GetTransportFeeReceipt: 'api/payment/GetTransportFeeReceipt',
    postfeedetails: 'api/payment/POST_FEE_DETAILS',
    getPaymentResult: 'api/payment/GetTransportFeePaymentResult',
    getRewardRedemptionDetails: 'api/rewards/GET_REWARD_REDEMPTION_DETAILS',
    getRedemptionHistory: 'api/rewards/GET_REDEMPTION_HISTORY',
    getParentContact: 'api/StudentInfo/GET_PARENT_CONTACT',
    posttRewardRedemptionDetails: 'api/rewards/POST_REWARD_REDEMPTION_DETAILS',
    postRewardRedeem: 'api/rewards/POST_REWARD_REDEEM',
    transportrequest: 'api/transport/GetNonGemsSchoolsListByCity',
    getMasters: 'api/masters/getmaster',
    saveTransportRequest: "api/transport/SaveTransportRequest",
    SaveNonGemsTransportRequest: "api/transport/SaveNonGemsStudentTransportRequest",
    getNotificationcount:"api/Notification/GetNotificationCount",
    getNotificationDetails:"api/Notification/PARENT_NOTIFICATION_DETAILS",
    notificationUpdate:"api/Notification/NOTIFICATION_UPDATE",
    logErrorDetails:"api/logs",
    loginAttempts:"LoginDetails",
    transportInfoOfChildren:"api/Transport/GetStudentsTransportInfo",
    GetMenusforWebPortalSTS:"api/masters/GetMenusforWebPortalSTS",
    requestSchedulechange:"api/Transport/POST_PERSONAL_PICKUP_DROP",
    getuaepassuserdetails:"api/uaepass/GET_USER_DETAILS",
    uaepassinitiatesigning:"api/uaepass/INITIATE_SIGNING",
    processdocumentsigning:"/api/uaepass/PROCESS_DOCUMENTS_SIGNING",
    downloadsigneddoc:"api/uaepass/DOWNLOAD_SIGN_DOCUMENT"
  }
};
