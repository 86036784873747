import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';

import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ConfigService } from './config.service';
const key = 'TUc0emRqRXpkdw==';
@Injectable({ providedIn: 'root' })

export class CommonService {
    reloadNotificationCount:BehaviorSubject<boolean>=new BehaviorSubject(false)
    public responseCache = new Map();

    constructor(private http: HttpClient, private configService:ConfigService) { }

    private get parentId() {
        return localStorage.getItem('parent');
    }

    private get GetCommonHeader() {
        return {
            headers: {
                "schoolid": localStorage.parentChildrens? JSON.parse(localStorage.parentChildrens)[0].studentId:"",
                "source": this.configService.source,
                "language": this.configService.language,
                "parentID": this.parentId,
            }
        }
    }
    private get GetCommonHeader1() {
        return {
            headers: {
                "StudentNo": localStorage.parentChildrens? JSON.parse(localStorage.parentChildrens)[0].studentId:"",
                "source": this.configService.source,
                "language": this.configService.language,
                "pathbase":''
            }
        }
    }
    private get GetNotificationCountHeader() {
        return {
            headers: {
                "ParentID": this.parentId,
                "source": this.configService.source,
                "type":''
           }
        }
    }
    private get GetNotificationDeatilHeader() {
        return {
            headers: {
                "ParentID": this.parentId,
                "source": this.configService.source  
           }
        }
    }
    private get GetHeaderforChildern() {
        return {
            headers: {
                "ParentID": this.parentId,
                "source": this.configService.source  
           }
        }
    }

    private getFromCache(url: string, headers: any): Observable<any> {
        const fromCache = this.responseCache.get(url);
        if (fromCache) {
            console.log('cache:', fromCache);
            return of(fromCache);
        }
        const response = this.http.get<any>(url, headers);
        response.subscribe(data => this.responseCache.set(url, data));
        return response;
    }

    public getMenusforParentPortal(): Observable<any> {
        return this.getFromCache(`${this.configService.baseUrl}/${environment.api.GetMenusforWebPortalSTS}`, this.GetCommonHeader1);
    }

    public getChildrenByParent(): Observable<any> {
        return this.getFromCache(`${this.configService.baseUrl}/${environment.api.CHILDREN_BY_PARENT_URL}`, this.GetCommonHeader);
    }

    public getMenusforParentPortal2() {
        return this.http.get<any>(`${this.configService.baseUrl}/${environment.api.GetMenusforParentPortal}`, this.GetCommonHeader);
    }

    public getChildrenByParent2() {
        return this.http.get<any>(`${this.configService.baseUrl}/${environment.api.GetChildren}`, this.GetCommonHeader);
    }
    public getChildrenByParentId() {
        return this.http.get<any>(`${this.configService.baseUrl}/${environment.api.GetChildren}`, this.GetHeaderforChildern);
    }
    public encryptVal(val:any)
    {
        return CryptoJS.AES.encrypt(val, key).toString();
    }
    public decryptVal(val:any)
    {
        
        let decrypted= CryptoJS.AES.decrypt(val, key).toString();
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    public getNotificationCount(): Observable<any> {
        return this.http.get(`${this.configService.baseUrl}/${environment.api.getNotificationcount}`, this.GetNotificationCountHeader);
    }
    public getNotificationDetails(): Observable<any> {
        
        return this.http.get(`${this.configService.baseUrl}/${environment.api.getNotificationDetails}`, this.GetNotificationDeatilHeader);
     //   return this.getFromCache("http://localhost:27802/api/Notification/PARENT_NOTIFICATION_DETAILS", this.GetNotificationDeatilHeader);
    }
    public notificationUpdate(obj):Observable<any>
    {
        let res = this.http.post<any>(`${this.configService.baseUrl}/${environment.api.notificationUpdate}`, obj, { 'headers': {} });
        return res;
        //return this.http.post<any>(`${this.configService.localapi}/${this.configService.api.notificationUpdate}`, obj,obj);
    }
    public postLogInfo(obj):Observable<any>
    {
        let res = this.http.post<any>(`${this.configService.baseUrl}/${environment.api.logErrorDetails}`, obj, { 'headers': {} });
        return res;
    }
    getIPAddress()
  {
   return this.http.get("https://api.ipify.org/?format=json")
  }
  validateUserName(username, token):Observable<any>{
    const usernameHeader = { username:username,source:this.configService.source,deviceid:this.configService.deviceId, Authorization:`Bearer ${token}` };
    return this.http.get(`${this.configService.baseUrl}/api/User/GetValidateUserName`, { 'headers': usernameHeader })
  }
 
  sendOTPToUser(data, token){
    const usernameHeader = {Username:data,
        source:this.configService.source,
        deviceid:this.configService.deviceId, Authorization:`Bearer ${token}` };
    return this.http.post(`${this.configService.baseUrl}/api/user/SendOTPChangePassword`, data,{ 'headers': usernameHeader })  }

  validateOTP(data, token){
    const usernameHeader = {Authorization:`Bearer ${token}`,username: data.username,
    otp: data.otp,
    source:this.configService.source,
    deviceid:this.configService.deviceId };
    return this.http.get(`${this.configService.baseUrl}/api/user/GetValidateUserName`, { 'headers': usernameHeader} )
  }

  resetPassword(data, token){
    const usernameHeader = {username:data.username,
        source:this.configService.source,
        deviceid:this.configService.deviceId,
        OldPassword:data.OldPassword,
        NewPassword:data.NewPassword, Authorization:`Bearer ${token}` };
        
    return this.http.post(`${this.configService.baseUrl}/api/password/forgot`,data, { 'headers': usernameHeader })
  }
  auditLog(name:any,date:any,ip:any):Observable<any>{
    let token = JSON.parse(localStorage.getItem('currentToken'));
    const Header = {  Authorization:`Bearer ${token?.access_token}` };
    //let body={userName:name,LoginDate:date,IpAddress:ip,"Source":"sts"}
    return this.http.post(`${this.configService.baseUrl}/api/Audit/SaveLogInAudit`,{userName:name,LoginDate:date,IpAddress:ip,"Source":"sts"}, { 'headers': Header })
  }
  auditLogout(name:any,date:any):Observable<any>{
    let token = JSON.parse(localStorage.getItem('currentToken'));
    const Header = {  Authorization:`Bearer ${token?.access_token}` };
   // let body={userName:name,LogoutDate:date}
    return this.http.post(`${this.configService.baseUrl}/api/Audit/SaveLogOutAudit`,{userName:name,LogoutDate:date}, { 'headers': Header })
  }
  getToken()
  {
    let token: any;
    token = JSON.parse(localStorage.getItem('currentToken'))
   return token?.access_token;
  }
  getTransportFeeDetails(data):Observable<any>{
    const usernameHeader = { type:data.type,PaymentRefNo:data.PaymentRefNo,paymentTo:this.configService.source,Language:this.configService.language, Authorization:`Bearer ${this.getToken()}` };
    return this.http.get(`${this.configService.baseUrl}/api/payment/GetTransportFeePaymentResult`, { 'headers': usernameHeader })
  }

  updateIdCardPayment(data:any):Observable<any>{
    let token = JSON.parse(localStorage.getItem('currentToken'));
    const Header = {  Authorization:`Bearer ${token?.access_token}` };
    return this.http.post(`${this.configService.baseUrl}/api/StudentInfo/UpdateStudentIdPaymentStatus`,data, { 'headers': Header })
  }
}