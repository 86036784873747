export class UrlRedirect{

    getRedirectUrl(key:string){
       var redirectUrl =RedirectUrls[key];
       return redirectUrl;
    }

}

export const RedirectUrls:{[key:string]:{redirectUrl:string,redirectTitle:string,redirectType:string}}={
    "REGISTRATION_LOGIN_HOME":{redirectUrl:"login",redirectTitle:"Click here to login",redirectType:"redirect"},
    "REGISTRATION_LOGIN":{redirectUrl:"login",redirectTitle:"Click here to login",redirectType:"redirect"},
    "REGISTRATION_REGISTER_HOME":{redirectUrl:"schools-list",redirectTitle:"Click here to register",redirectType:"redirect"},
    "REGISTRATION_REGISTER":{redirectUrl:"schools-list",redirectTitle:"Click here to register",redirectType:"redirect"},
    "REQUEST_CALLBACK_HOME":{redirectUrl:"requestCallback",redirectTitle:"Click here to proceed",redirectType:"open-modal"},
    "REQUEST_CALLBACK":{redirectUrl:"/?type=requestCallback",redirectTitle:"Click here to proceed",redirectType:"redirect"},
    "REDIRECT_OFFERS_HOME":{redirectUrl:"new-offers",redirectTitle:"Click here to proceed",redirectType:"scroll"},
    "REDIRECT_OFFERS":{redirectUrl:"/?type=new-offers",redirectTitle:"Click here to proceed",redirectType:"redirect"},
    "PAYMENT_HISTORY":{redirectUrl:"payment-history?id=<studentNo>",redirectTitle:"Click here to proceed",redirectType:"redirect"},
    "LAST_PAYMENT":{redirectUrl:"payment-history?id=<studentNo>",redirectTitle:"Click here to proceed",redirectType:"redirect"},
    "CONTACT_SDE":{redirectUrl:"contact-sde",redirectTitle:"Click here to proceed",redirectType:"redirect"},
    "SERVICE_REQUEST":{redirectUrl:"service-request",redirectTitle:"Click here to proceed",redirectType:"redirect"},
    "RE_ACTIVATE":{redirectUrl:"transport-information",redirectTitle:"Click here to proceed",redirectType:"redirect"},
    "AREA_CHANGE":{redirectUrl:"request-area-change",redirectTitle:"Click here to proceed",redirectType:"redirect"},
    "REGISTER_SIBLING":{redirectUrl:"registerNow",redirectTitle:"Click here to proceed",redirectType:"method"},
    "REQUEST_SCHEDULE_CHANGE":{redirectUrl:"schedule-change",redirectTitle:"Click here to proceed",redirectType:"redirect"},
    "RE_REGISTER":{redirectUrl:"transport-information?showReRegister=true",redirectTitle:"Click here to proceed",redirectType:"redirect"},
    "DISCONTINUE_SERVICE":{redirectUrl:"transport-discontinuation",redirectTitle:"Click here to proceed",redirectType:"redirect"},
    "RECOVER_PASSWORD_HOME":{redirectUrl:"forgot",redirectTitle:"Proceed to Trouble Signing In",redirectType:"redirect"},
    "RECOVER_PASSWORD":{redirectUrl:"forgot",redirectTitle:"Proceed to Trouble Signing In",redirectType:"redirect"},
    "CONTACT_CHANGE":{redirectUrl:"request-for-contact-change",redirectTitle:"Click here to proceed",redirectType:"redirect"}
};
