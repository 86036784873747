import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StatementOfAccountsComponent } from './statement-of-accounts/statement-of-accounts.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { PaymentDetailComponent } from './payment-detail/payment-detail.component';
import { PaymentRefundComponent } from './statement-of-accounts/payment-refund/payment-refund.component';
import { ReceiptsComponent } from './receipts/receipts.component';

const routes: Routes = [
  {
    path: 'statement-of-accounts',
    component: StatementOfAccountsComponent
  },
  {
    path: 'payment-detail',
    component: PaymentDetailComponent
  },
  {
    path: 'payment-history',
    component: PaymentHistoryComponent
  },
  {
    path: 'payment-refund',
    component: PaymentRefundComponent
  },
  {
    path:'payment-receipt',
    component:ReceiptsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentsRoutingModule { }
