import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { MessageComponent } from 'src/app/message/message.component';
import { RegistrationService } from 'src/app/service/registration.service';
import { TokenService } from 'src/app/service/token.service';

import { UaepassService } from 'src/app/service/uaepass.service';
import * as html2pdf from 'html2pdf.js';
@Component({
  selector: 'app-uaepass-callback',
  templateUrl: './uaepass-callback.component.html',
  styleUrls: ['./uaepass-callback.component.scss']
})
export class UaepassCallbackComponent implements OnInit {

  constructor(private uaepasservice: UaepassService, private dialog: MatDialog, public router: Router,
    private tokenService: TokenService, public regService: RegistrationService) { }
  defaultToken: any;
  pdfs: any[] = [];
  tandcfilteredDatapdf: any;
  BusBehaviourCodedatapdf: any;
  Parentundertakingapdf: any;
  files: File[] = []; //

  ngOnInit(): void {

    const queryString = window.location.search;

    // Create a new URLSearchParams object with the query string
    const urlParams = new URLSearchParams(queryString);

    // Get the value of the 'code' parameter
    const code = urlParams.get('code');

    const tokenURL = urlParams.get('tokenURL');
    const state1=urlParams.get('state');
    if(localStorage.processdocument=="success")
    {
     let ret_signed_url=tokenURL+"&code="+code;
     let signIdentityId=localStorage.signingIdentity;
     let txId=localStorage.txId;
     this.uaepasservice.DownloadSignedDoc(ret_signed_url,signIdentityId,txId,'13100112345680','131001','0508262556').subscribe
     (downloadres=>
      {
       // alert(JSON.stringify(downloadres));
       const downloadresResponse: ApiResponse = JSON.parse(JSON.stringify(downloadres));
       if(downloadresResponse.success=="true")
       {
              const downloadresisSuccess = downloadresResponse.data.isSuccess;

             if(downloadresisSuccess)
             {
               localStorage.setItem("docdownloadurl",downloadresResponse.data.downloadUrl);
               this.openMessageDialog("Thank you for signing your document with UAE Pass. Your signed document is now being processed. This may take a moment. Please wait while we finalize the process.",false,"");
             }
       }
       else
       {
        this.openMessageDialog(downloadresResponse.message, true, "DownloadSignedDoc: " + downloadresResponse.message);
       }

      })
      localStorage.removeItem("processdocument");
    }

    
    else if (tokenURL) {
      this.getToken(tokenURL, code);
    }
    else {
      this.uaepasservice.getUserDetails(code).subscribe(res => {
        // alert(JSON.parse(JSON.stringify(res)));
        const apiResponse: ApiResponse = JSON.parse(JSON.stringify(res));
        // alert(apiResponse.data.isSuccess);
        const isSuccess = apiResponse.data.isSuccess;
        if (isSuccess) {

          if (apiResponse.data.uaepassUserDetails.userType == "SOP3" || apiResponse.data.uaepassUserDetails.userType == "SOP2") {

            this.uaepasservice.initateSigning().subscribe(signingresult => {
              const signingresultapiResponse: ApiResponse = JSON.parse(JSON.stringify(signingresult));
              const signingresultisSuccess = signingresultapiResponse.data.isSuccess;
              if (signingresultisSuccess) {
                // alert(signingresultapiResponse.data.insitiateSigningURL);
                window.location.href = signingresultapiResponse.data.insitiateSigningURL;

              }
              else {
                this.openMessageDialog(signingresultapiResponse.data.uaepassErrorMessage.error, true, "INITIATE_SIGNING: " + apiResponse.data.uaepassErrorMessage.error_description);
              }

            })

          }
          else {
            this.openMessageDialog("This user type sould not support the signed documents", true, "This user type sould not support the signed documents");

          }
        }
        else {
          //alert(JSON.stringify( apiResponse.data.uaepassErrorMessage));
          this.openMessageDialog(apiResponse.data.uaepassErrorMessage.error, true, apiResponse.data.uaepassErrorMessage.error_description);

        }
      });
    }

  }

  getToken(tokenURL: any, code: any) {
    this.tokenService.GetToken(null).subscribe(
      res => {
        this.defaultToken = res?.access_token;
        this.getTermsConditions(tokenURL, code);
      }
    )
  }

  getTermsConditions(tokenURL: any, code: any) {

    this.regService.getTermsandConditions(this.defaultToken).subscribe(res => {

      this.pdfs = res?.data
      // alert(JSON.stringify(this.pdfs));
      this.pdfs.forEach(x => x.isAgree = false);
      // Specify the value to filter by


      // Filter the array based on the specified property and value
      var vtermsac = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "TNC");
      // alert(vtermsac.length);
      if (vtermsac.length > 0) {
        this.tandcfilteredDatapdf = vtermsac.map(item => item.ttcuL_PDF_HTML);

        this.convertHtmlToPdf(this.tandcfilteredDatapdf, "TnC.pdf");

        //  const tncBlob = new Blob(this.BusBehaviourCodedatapdf, { type: 'application/pdf' });
        // const tncFile = this.convertBlobToFile(tncBlob, 'TnC.pdf');
        // this.files.push(tncFile);
        // alert(this.tandcfilteredDatapdf);
      }

      var vbusb = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "BBC");
      if (vbusb.length > 0) {
        this.BusBehaviourCodedatapdf = vbusb.map(item => item.ttcuL_PDF_HTML);
        this.convertHtmlToPdf(this.BusBehaviourCodedatapdf, "BBC.pdf");
        // const bbcBlob = new Blob(this.BusBehaviourCodedatapdf, { type: 'application/pdf' });
        // const bbcfile = this.convertBlobToFile(bbcBlob, 'BBC.pdf');

        // this.files.push(bbcfile);
      }

      var parentUndertaking = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "PUT");
      if (parentUndertaking.length > 0) {
        this.Parentundertakingapdf = parentUndertaking.map(item => item.ttcuL_PDF_HTML);
        //const putBlob = new Blob(this.BusBehaviourCodedatapdf, { type: 'application/pdf' });
        this.convertHtmlToPdf(this.Parentundertakingapdf, "PUT_1223344.pdf");
        // const putFile = this.convertBlobToFile(putBlob, 'PUT_.pdf');
        //this.files.push(putFile);
      }
      setTimeout(() => {
       
      if (this.files.length > 0) {
        let signingurl: any = tokenURL + "&code=" + code;
        this.uaepasservice.processDocumnetSigning(signingurl, this.files).subscribe(
          signres => {
            const signresapiResponse: ApiResponse = JSON.parse(JSON.stringify(signres));
          //  alert(JSON.stringify(signres));
         if( signresapiResponse.success=="false")
         {
          this.openMessageDialog(signresapiResponse.message, true, signresapiResponse.message);

         }
         else
         {

              const signresSuccess = signresapiResponse.data.isSuccess;
              if(signresSuccess)
              {
              let signingIdentity=signresapiResponse.data.signingIdentity;
              let txId=signresapiResponse.data.txId;
              localStorage.setItem("txId",txId);
              localStorage.setItem("signingIdentity",signingIdentity);
             // alert(signresapiResponse.data.processSigningURL);
              window.location.href=signresapiResponse.data.processSigningURL+"&fromdoc=docs";
              localStorage.setItem("processdocument","success");
              }
              else
              {
                this.openMessageDialog(signresapiResponse.message, true, signresapiResponse.message);
              }
            }

          }
        );
      }
      else {
        this.openMessageDialog("No files found", true, "No files found");
      }
      
    }, 3000);
    
    });
  }
  convertHtmlToPdf(htmlContent: string, filename: string) {
    // Create a new element to hold the HTML content
    const element = document.createElement('div');
    element.innerHTML = htmlContent;

    // Append the element to the document body
    document.body.appendChild(element);

    // Define options for html2pdf
    const options = {
      margin: [0.5, 0.5, 0.5, 0.5], // margins: top, right, bottom, left
      filename: 'document.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    // Convert HTML to PDF
    html2pdf().from(element).set(options).outputPdf('blob').then((blob: Blob) => {
      // Remove the element from the document
      document.body.removeChild(element);

      // Handle the Blob, for example, save it or upload it
      // Save the Blob as a file
      const bbcfile = this.convertBlobToFile(blob, filename);
      this.files.push(bbcfile);
      // const url = URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = 'document.pdf';
      // a.click();
      // URL.revokeObjectURL(url); // Clean up
    });
  }
  private convertBlobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, { type: 'application/pdf' });
  }

  downloadBlob(blob: Blob, filename: string): void {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url); // Clean up
  }
  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      
     
      this.router.navigate(['/schoolbus-registration'],{queryParams:{tab:3,isReactivate:false,isAgreed:true}});
    });
  }


}
// Define interfaces to match the JSON structure
interface UaepassUserDetails {
  sub: string;
  fullnameAR: string;
  idType: string;
  gender: string;
  mobile: string;
  lastnameEN: string;
  fullnameEN: string;
  uuid: string;
  lastnameAR: string;
  idn: string;
  nationalityEN: string;
  firstnameEN: string;
  userType: string;
  nationalityAR: string;
  firstnameAR: string;
  email: string;

}

interface UaepassResponse {
  uaepassUserDetails: UaepassUserDetails;
  uaepassErrorMessage: Errorresponse | null;
  isSuccess: boolean;
  insitiateSigningURL: string;
  processSigningURL:string;
  signingIdentity:string;
  txId:string;
  downloadUrl:string;
}

interface ApiResponse {
  cmd: string;
  success: string;
  responseCode: string;
  message: string;
  data: UaepassResponse;
}
interface Errorresponse {
  error_description: string;
  error: string
}


