import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthenticationService } from '../service/authentication.service';
import { LoggerService } from '../service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private logger:LoggerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err:HttpErrorResponse) => {
                
                this.logger.error(err)

                if (err.status === 401) {
                    this.authenticationService.logout();
                    location.reload();
                }
                
                else{
                    console.log(err);
                    const error = err.error.message || err.statusText;
                    return throwError(error);
                }
                
            })
        )
    }
}