import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-widgets',
  templateUrl: './dashboard-widgets.component.html',
  styleUrls: ['./dashboard-widgets.component.scss']
})
export class DashboardWidgetsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    localStorage.removeItem('parentDetails');
    localStorage.removeItem('bsU_ID');
    localStorage.removeItem('studParentDetails');
    localStorage.removeItem('studentDetails');
    localStorage.removeItem('disableIds');
    localStorage.removeItem('termsConditions');
  }

}
