import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  showTerms = false;
  data = [
    {id: 1, name: 'Rajesh', email: 'rajesh@gmail.com'},
    {id:2, name: 'Paresh', email: 'paresh@gmail.com'},
    {id:3, name: 'Naresh', email: 'naresh@gmail.com'},
    {id:4, name: 'Suresh', email: 'suresh@gmail.com'},
    {id:5, name: 'Karan', email: 'karan@gmail.com'},
  ];
  displayedColumns = ['id', 'name', 'email'];
  constructor() { }

  ngOnInit(): void {
  }
  viewTerms(){
    this.showTerms = !this.showTerms;
  }


}
