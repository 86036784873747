import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';

import { environment } from '../../environments/environment';
import { User } from '../models/User';
import { Token } from '../models/token';
import { TokenService } from './token.service';
import { CommonService } from '.';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private username = new BehaviorSubject<string>("");
  private password = new BehaviorSubject<string>("");
  private currentUserSubject = new BehaviorSubject<User>(undefined);
  private currentTokenSubject = new BehaviorSubject<Token>(undefined);
  public isLoginSuccess:boolean=true;

  constructor(private http: HttpClient,private router:Router, private tokenService: TokenService,private common: CommonService,
    private configService:ConfigService,
    public date:DatePipe) {
  }

  public get currentUser(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  public get currentToken() {
    return JSON.parse(localStorage.getItem('currentToken'));
  }
  private get encryptedPassword() {
    return CryptoJS.SHA512(this.password.value.trim()).toString();
  }

  private get loginHeader() {
    return {
      headers: {
        "username": this.username.value,
        "password": this.encryptedPassword,
        "source": this.configService.source,
        "deviceid": this.configService.deviceId,
        "Authorization":'Bearer '+this.currentToken?.access_token
      }
    }
  }

  public login(username: string, password: string) {
    this.username.next(username);
    this.password.next(password);
    return this.tokenService.GetToken(username)
      .pipe(
        tap((token: Token) => {
          this.currentTokenSubject.next(token);
          localStorage.setItem('currentToken', JSON.stringify(token));
        }),
        switchMap(() => this.http.get<any>(`${this.configService.baseUrl}/${environment.api.login}`, this.loginHeader)),
        tap((user: User) => {
          if (!user.errorCode) {
            this.isLoginSuccess=true;
            this.currentUserSubject.next(user);
            localStorage.setItem('currentUser', JSON.stringify(user));
          }
          else{
            this.isLoginSuccess=false;
          }
        })
      )
  }

  public logout() {
    let username = localStorage.getItem('parent')
    let currentDate = new Date();
    let date = this.date.transform(currentDate, 'yyyy-MM-ddTHH:mm:ss');
    this.common.auditLogout(username,date).subscribe(res=>{
      this.currentTokenSubject.next(null);
      this.currentUserSubject.next(null);
      localStorage.clear();
      localStorage.removeItem("currentUser");
      this.router.navigate(['/login']);
    })
    // this.currentTokenSubject.next(null);
    //   this.currentUserSubject.next(null);
    //   localStorage.clear();
    //   localStorage.removeItem("currentUser");
  }

  public getAttemptCount(username:string)
  {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer '+this.currentToken?.access_token);
    return this.http.get<any>(`${this.configService.baseUrl}/${environment.api.loginAttempts}?userid=${username}`,{headers:headers})
  }
}