import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  logLevel: LogLevel = new LogLevel();
  msg: any;
  constructor(private commonService: CommonService) { }
  info(msg: string): void {
    this.logWith(this.logLevel.Info, msg);
  }
  warn(msg: any): void {
    this.logWith(this.logLevel.Warn, msg);
  }
  error(msg: any): void {
    this.msg = msg;
    this.postDetails()
    this.logWith(this.logLevel.Error, msg);
  }
  private logWith(level: any, msg: any): void {
    if (level <= this.logLevel.Error) {
      switch (level) {
        case this.logLevel.None:
          return console.log(msg);
        case this.logLevel.Info:
          return console.info('%c' + msg, 'color: #6495ED');
        case this.logLevel.Warn:
          return console.warn('%c' + msg, 'color: #FF8C00');
        case this.logLevel.Error:
          return console.log(msg);

        default:
          console.debug(msg);
      }
    }
  }
  postDetails() {
    let data: any = {};
    data = {
      "Message": this.msg?.error.message, "RequestUri": this.msg.url, "LogType": "Error",
      "IpAddress": localStorage.getItem('IPAddress')
    }
    console.log(data);
    // this.commonService.postLogInfo(data).subscribe(
    //   res => {
    //     console.log(res);

    //   }
    // )
  }
}
export class LogLevel {
  None = 0;
  Info = 1;
  Verbose = 2;
  Warn = 3;
  Error = 4;
  constructor() { }
}