import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class TokenService {
    constructor(private http: HttpClient,
        private configService:ConfigService) { }

    private tokenRequestBody(userName: string) {
        return `grant_type=${this.configService.grantType}&username=${this.configService.client}&password=${this.configService.key}&AppId=${this.configService.AppId}&app_user=${userName}`;
    }

    private tokenRequestHeader(hideSpinner=false) {
        let headers :any={
            "Content-Type": "application/x-www-form-urlencoded"
        }
        headers={...headers,HideSpinner:`${hideSpinner}`}
        return {
            headers: headers
        };
    }

    public GetToken(userName: string,hideSpinner=false): Observable<any> {
        
        return this.http.post<any>(`${this.configService.baseUrl}/${environment.api.token}`, this.tokenRequestBody(userName), this.tokenRequestHeader(hideSpinner));
    }
}