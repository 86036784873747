import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageComponent } from 'src/app/message/message.component';
import { CommonService } from 'src/app/service';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  selectedTab: any=0;

  constructor(public notificationService:NotificationService, private dialog:MatDialog,public commonService:CommonService) { }
  notificationList:any[]=[];
  notificationMessageList:any[]=[];
  notificationAlertList:any[]=[];
  notificationStarredList:any[]=[];
  messageCount:any;
  alertCount:any;
  starredCount:any;
  
  ngOnInit(): void {
   this.getNotifications();
  }

  getNotifications()
  {
    this.notificationService.getNotifications().subscribe((res:any)=>{
      this.notificationList=res?.data;
      if(this.notificationList != null){
        this.notificationMessageList=this.notificationList.filter(x=>(x.categorY_CODE != 'EMG') && (x.categorY_CODE != 'GEN') && (x.categorY_CODE != 'TRA') && (x.categorY_CODE != 'ALE') );
        this.notificationAlertList=this.notificationList.filter(x=>(x.categorY_CODE == 'EMG') ||(x.categorY_CODE == 'GEN') || (x.categorY_CODE == 'TRA') || (x.categorY_CODE == 'ALE') );
        this.notificationStarredList=this.notificationList.filter(x=>x.npD_IS_FAV_FLAG == true );
      }
      this.notificationService.isNotifications.next(true)
    })
  }

  callNotification(event:any)
  {
     if(event)
     this.getNotifications();
  }
  postData(event){
    console.log(event);
    let postdata=[];
    if(!event.isBulk)
    {
    event.data.forEach(ele=>{
      postdata.push(  {
        "NotificationID": ele.npD_ID,
        "ReadFlag": (event.type == 'read' && event.res == 1) ? 1: event.type != 'read'? ele.npD_ISREADFLAG : 0,
        "FavoriteFlag": (event.type == 'starred' && event.res == 1) ? 1: event.type != 'starred'? ele.npD_IS_FAV_FLAG : 0,
        "DeleteFlag": event.type == 'delete' ? 1:0,
    })
    })
  }else{
    event.data.forEach(ele=>{
      postdata.push(  {
        "NotificationID": ele.npD_ID,
        "ReadFlag": (event.type == 'read' && ele.isChecked == 1) ? 1: event.type != 'read'? ele.npD_ISREADFLAG :0,
        "FavoriteFlag": (event.type == 'starred' && ele.isChecked == 1) ? 1: event.type != 'starred'? ele.npD_IS_FAV_FLAG :0,
        "DeleteFlag": event.type == 'delete' ? 1:0,
    })
    })
  }
    
    
    this.notificationService.postNotifications(postdata).subscribe(
      res=>{
        if(event.type == 'read')
        this.commonService.reloadNotificationCount.next(true)
        this.getNotifications();
        if(event.type == 'delete'){
          this.openMessageDialog('Notification(s) deleted successfully.', false,'')
        }
      }
    )
    
  }
  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      minWidth: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.goToLogin()
      
    });
  }
  searchtext:string='';
  changeTab(value){
    console.log(value);
    this.selectedTab = value.index;
    this.searchtext='';
    if(this.notificationList != null){
      this.notificationMessageList=this.notificationList.filter(x=>(x.categorY_CODE != 'EMG') && (x.categorY_CODE != 'GEN') && (x.categorY_CODE != 'TRA') && (x.categorY_CODE != 'ALE') );
      this.notificationAlertList=this.notificationList.filter(x=>(x.categorY_CODE == 'EMG') ||(x.categorY_CODE == 'GEN') || (x.categorY_CODE == 'TRA') || (x.categorY_CODE == 'ALE') );
      this.notificationStarredList=this.notificationList.filter(x=>x.npD_IS_FAV_FLAG == true );
    }
  }
  search(){
    console.log(this.searchtext);
    if(this.selectedTab == 0){
      this.notificationMessageList= this.notificationList.filter(x=>(x.categorY_CODE != 'EMG') && (x.categorY_CODE != 'GEN') && (x.categorY_CODE != 'TRA') && (x.categorY_CODE != 'ALE') ).filter(x=>x.notification_MSG.toLowerCase().includes(this.searchtext.toLowerCase()) || x.nlD_NOTIFICATION_TITLE.toLowerCase().includes(this.searchtext.toLowerCase()) );
    }else if (this.selectedTab == 1){
      this.notificationAlertList= this.notificationList.filter(x=>(x.categorY_CODE == 'EMG') ||(x.categorY_CODE == 'GEN') || (x.categorY_CODE == 'TRA') || (x.categorY_CODE == 'ALE') ).filter(x=>x.notification_MSG.toLowerCase().includes(this.searchtext.toLowerCase()) || x.nlD_NOTIFICATION_TITLE.toLowerCase().includes(this.searchtext.toLowerCase()) );
    }else {
      this.notificationStarredList= this.notificationList.filter(x=>x.npD_IS_FAV_FLAG == true ).filter(x=>x.notification_MSG.toLowerCase().includes(this.searchtext.toLowerCase()) || x.nlD_NOTIFICATION_TITLE.toLowerCase().includes(this.searchtext.toLowerCase()) );

    }
    // this.notificationAlertList= this.notificationList.filter(x=>(x.categorY_CODE == 'EMG') ||(x.categorY_CODE == 'GEN') || (x.categorY_CODE == 'TRA') || (x.categorY_CODE == 'ALE') ).filter(x=>x.notification_MSG.toLowerCase().startsWith(this.searchtext.toLowerCase()) );
    console.log( this.notificationAlertList);
    
  }
}
