import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { ServiceinfoComponent } from './features/serviceinfo/serviceinfo.component';

import { LayoutComponent } from './shared/layout/layout.component';
import { MatTableModule } from '@angular/material/table';
import { TransportrequestComponent } from './features/transportrequest/transportrequest.component';
import { OnlineregistrationComponent } from './features/transportrequest/onlineregistration/onlineregistration.component';
// import { ChangePasswordComponent } from './features/account/change-password/change-password.component';
import { SibilinglinkingComponent } from './features/sibilinglinking/sibilinglinking.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ForgotPasswordComponent } from './features/login/forgot-password/forgot-password.component';
import { RequestScheduleChangeComponent } from './features/home/schedule-change/request-schedule-change/request-schedule-change.component';
import { TransportInformationComponent } from './features/home/transport-information/transport-information.component';
import { RequestCallbackComponent } from './features/home/request-callback/request-callback.component';
import { SchoolsListComponent } from './features/school-bus-registration/schools-list/schools-list.component';
import { PreloginLayoutComponent } from './shared/prelogin-layout/prelogin-layout.component';
import { MyaccountComponent } from './features/myaccount/myaccount.component';
import { SupportComponent } from './features/menu/support/support.component';
import { SettingsComponent } from './features/menu/settings/settings.component';
import { ChangePasswordComponent } from './features/menu/settings/change-password/change-password.component';
import { AppTermsAndConditionsComponent } from './features/menu/settings/app-terms-and-conditions/app-terms-and-conditions.component';
import { PrivacyPolicyComponent } from './features/menu/settings/privacy-policy/privacy-policy.component';
import { FaqComponent } from './features/menu/support/faq/faq.component';
import { ServiceRequestComponent } from './features/menu/support/service-request/service-request.component';
import { ContactSdeComponent } from './features/menu/support/contact-sde/contact-sde.component';
import { FeedbackComponent } from './features/menu/support/feedback/feedback.component';
import { TermsConditionsComponent } from './features/menu/support/terms-conditions/terms-conditions.component';
import { AddFeedbackComponent } from './features/menu/support/feedback/add-feedback/add-feedback.component';
import { TransportDiscontinuationComponent } from './features/home/transport-information/transport-discontinuation/transport-discontinuation.component';
import { ReRegistrationComponent } from './features/home/transport-information/re-registration/re-registration.component';
import { PaymentsComponent } from './features/home/transport-information/payments/payments.component';
import { ReRegisterTermsAndConditionComponent } from './features/home/transport-information/re-registration/re-register-terms-and-condition/re-register-terms-and-condition.component';
import { ScheduleChangeComponent } from './features/home/schedule-change/schedule-change.component';
import { NotificationComponent } from './features/notification/notification.component';
import { RequestForContactChangeComponent } from './features/home/request-for-contact-change/request-for-contact-change.component';
import { StsOffersComponent } from './features/menu/sts-offers/sts-offers.component';
import { StsGroupComponent } from './features/menu/sts-group/sts-group.component';
import { RequestAreaChangeComponent } from './features/menu/request-area-change/request-area-change.component';
import { SmartbusComponent } from './features/smartbus/smartbus.component';
import { RequestAreaChangeInfoComponent } from './features/menu/request-area-change/request-area-change-info/request-area-change-info.component';
import { RfidComponent } from './features/menu/support/service-request/rfid/rfid.component';
import { AutoPromotionComponent } from './features/home/auto-promotion/auto-promotion.component';
import { AutoPromoLayoutComponent } from './shared/AutoPromoLayout/autoPromoLayout.component';
import { ViewServiceRequestComponent } from './features/menu/support/service-request/view-service-request/view-service-request.component';
const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'index',
    loadChildren: () =>
    import('../app/landing-page/landing-page.module').then((m) => m.LandingModule),
    component: LandingPageComponent
  },
  {
    path: '',
    loadChildren: () =>
    import('../app/landing-page/landing-page.module').then((m) => m.LandingModule),
    component: LandingPageComponent
  },
  {
    path: 'forgot',
    component: ForgotPasswordComponent
  },
  // {
  //   path: 'request-schedule-change',
  //   component: RequestScheduleChangeComponent
  // },
    {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'request-schedule-change', component: RequestScheduleChangeComponent },
      { path: 'schedule-change', component: ScheduleChangeComponent }
      // Add other routes with the standard layout
    ]
  },
  // {
  //   path: 'transport-information',
  //   component: TransportInformationComponent
  // },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'transport-information', component: TransportInformationComponent },
      { path: 'request-callback', component: RequestCallbackComponent },
      { path: 'schools-list', component: SchoolsListComponent },
      { path: 'my-account', component: MyaccountComponent },
      { path: 'support', component: SupportComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'app-terms-and-conditions', component: AppTermsAndConditionsComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'service-request', component: ServiceRequestComponent },
      { path: 'rfid', component: RfidComponent },
      { path: 'contact-sde', component: ContactSdeComponent },
      { path: 'feedback', component: FeedbackComponent },
      { path: 'add-new', component: AddFeedbackComponent },
      { path: 'terms-coniditions', component: TermsConditionsComponent },
      {path: 'transport-discontinuation',component: TransportDiscontinuationComponent},
      {path: 're-registration',component: ReRegistrationComponent},
      {path: 're-register-terms-and-conditions',component: ReRegisterTermsAndConditionComponent},
      {path: 'payments',component: PaymentsComponent},
      {path: 'notification',component: NotificationComponent},
      {path: 'request-for-contact-change',component: RequestForContactChangeComponent},
      {path: 'sts-offers',component: StsOffersComponent},
      {path: 'sts-group',component: StsGroupComponent},
      {path: 'request-area-change',component: RequestAreaChangeComponent},
      {path: 'request-area-change-info',component: RequestAreaChangeInfoComponent},
      {path: 'smartbus',component: SmartbusComponent},
      { path: 'view-service-request', component: ViewServiceRequestComponent }
      // Add other routes with the standard layout
    ]
  },


  {
    path: 'request-callback',
    component: RequestCallbackComponent
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./features/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: '',
    children: [
      {
        path: '', redirectTo: 'home', pathMatch: 'full'
      },
      {
        path: 'home',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: (): Promise<any> =>
          import('./features/home/home.module').then(
            (m) => m.HomeModule
          ),
      },
    ],
  },

  {
    path: 'makepayment',
    children: [
      {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: (): Promise<any> =>
          import('./features/payments/payments.module').then(
            (m) => m.PaymentsModule
          ),
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'paymenthistory', redirectTo: 'paymenthistory', pathMatch: 'full'
      },
      {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: (): Promise<unknown> =>
          import('./features/payments/payments.module').then(
            (m) => m.PaymentsModule
          ),
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'outstandingfee', redirectTo: 'outstandingfees', pathMatch: 'full'
      },
      {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: (): Promise<any> =>
          import('./features/payments/payments.module').then(
            (m) => m.PaymentsModule
          ),
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'statementofaccounts', redirectTo: 'statementofaccounts', pathMatch: 'full'
      },
      {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: (): Promise<unknown> =>
          import('./features/payments/payments.module').then(
            (m) => m.PaymentsModule
          ),
      },
    ],
  },
  {
    path: 'bus',
    children: [
      {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: (): Promise<unknown> =>
          import('./features/bus-information/bus-information.module').then(
            (m) => m.BusInformationModule
          ),
      },
    ],
  },
  {
    path: 'statements',
    children: [
      {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: (): Promise<unknown> =>
          import('./features/statement-of-accounts-old/statement-of-accounts.module').then(
            (m) => m.StatementOfAccountsModule
          ),
      },
    ],
  },
  {
    path: 'SilibingLinking',
    children: [
      {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: (): Promise<unknown> =>
          import('./features/sibilinglinking/sibilinglinking.module').then(
            (m) => m.SibilinglinkingModule
          ),
      },
    ],
  },
  {
    path: 'serviceinfo',
    children: [
      {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: (): Promise<unknown> =>
          import('./features/serviceinfo/serviceinfo.module').then(
            (m) => m.ServiceinfoModule
          ),
      },
    ],
  }, {
    path: 'changepassword',
    component: ChangePasswordComponent
  },
  // {
  //   path: 'notifications',
  //   component: NotificationsComponent
  // },
  // {
  //   path: 'notifications',
  //   children: [
  //     {
  //       path: '',
  //       component: LayoutComponent,
  //       canActivate: [AuthGuard],
  //       loadChildren: (): Promise<unknown> =>
  //         import('./features/notifications-old/notifications.module').then(
  //           (m) => m.NotificationsModule
  //         ),
  //     },
  //   ],
  // },

  {
    path: 'pageNotFound',
    component: PageNotFoundComponent
  },
  {
    path: 'transportrequest',
    component: TransportrequestComponent
  },
  {
    path: 'onlineregistration',
    component: OnlineregistrationComponent
  },
  {
    path: 'account',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'request-schedule-change',
    component: RequestScheduleChangeComponent,
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
  },
  // {
  //   path: 'auto-promotion',
  //   component: AutoPromotionComponent,
  //   loadChildren: () =>
  //     import('./features/home/home.module').then((m) => m.HomeModule),
  // },
  {
    path: '',
    component: AutoPromoLayoutComponent,
    children: [
      {path: 'auto-promotion', component: AutoPromotionComponent}
      // Add other routes with the standard layout
    ]
  },
  {
    path: '**',
    redirectTo: 'pageNotFound',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), MatTableModule],
  exports: [RouterModule, MatTableModule]
})
export class AppRoutingModule { }
