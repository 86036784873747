import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-contacts-widget',
  templateUrl: './contacts-widget.component.html',
  styleUrls: ['./contacts-widget.component.scss']
})
export class ContactsWidgetComponent implements OnInit {
  contactDetails: any;
  primaryContact: string='';
  secondaryContact: string='';
  public addPaymentClass: boolean = false;
  fileErrClass: boolean = false;

  constructor(private dashboardService:DashboardService,private router: Router) { }

  ngOnInit(): void {
    this.getPrimaryContact();
    this.dashboardService.isPaymentMessage.subscribe(res=>{
    this.addPaymentClass = res;
    this.dashboardService.fileErrMessage.subscribe(val=>{
      this.fileErrClass=val;
     })
    })
  }
  getPrimaryContact(){
    let name:any='';
    name = JSON.parse(localStorage.getItem('currentUser'));
    console.log(name);

    this.dashboardService.getContactDetails(name?.userDisplayName).subscribe((res:any)=>{
      localStorage.setItem('primaryMobileNo', res?.data?.primaryContactNumber);
      res.data.primaryContactNumber=res?.data.primaryContactNumber.replace('+','')
      res.data.secondaryContactNumber=res?.data.secondaryContactNumber.replace('+','')
      this.contactDetails = res?.data;
      let phone =''
      // if(this.selectedCountries == 'ae'){
        if((this.contactDetails.primaryContactNumber.length >= 12 && (this.contactDetails.primaryContactNumber.slice(0, 3) == "971")) || (this.contactDetails.primaryContactNumber.length >= 11 && (this.contactDetails.primaryContactNumber.slice(0, 3) == "974")))
        {
        this.primaryContact = "+"+[this.contactDetails.primaryContactNumber.slice(0, 3)+" "+this.contactDetails.primaryContactNumber.slice(3,5) +" "+this.contactDetails.primaryContactNumber.slice(5,8)+" "+this.contactDetails.primaryContactNumber.slice(8)].join('');
        }
        else{
          this.primaryContact = this.contactDetails.primaryContactNumber
          }


          if((this.contactDetails.secondaryContactNumber.length >= 12 && (this.contactDetails?.secondaryContactNumber.slice(0, 3) == "971")) || (this.contactDetails.secondaryContactNumber.length >= 11 && (this.contactDetails?.secondaryContactNumber.slice(0, 3) == "974")))
        {
        this.secondaryContact ="+"+[this.contactDetails?.secondaryContactNumber.slice(0, 3)+" "+this.contactDetails?.secondaryContactNumber.slice(3,5) +" "+this.contactDetails.secondaryContactNumber.slice(5,8)+" "+this.contactDetails.secondaryContactNumber.slice(8)].join('');
        }
        else{
          this.secondaryContact = this.contactDetails?.secondaryContactNumber
          }
      console.log(phone);

     // }else{
       // phone = phoneNumber.nationalNumber
      //}
      localStorage.setItem('primaryContactName', res?.data?.primaryContactName)
      localStorage.setItem('primaryEmail', res?.data?.primaryContactEmailAddress)
    })
  }
  navigatetolink(lnk) {
    this.dashboardService.contactDetails.next(this.contactDetails)
    this.router.navigate([lnk]);
  }
}
