import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient,private configService:ConfigService) { }

  public getNews(token:any): Observable<any> {
    let Header = {
      Authorization:`Bearer ${token}`,
     };
    return this.http.get<any>(`${this.configService.baseUrl}/api/cms/cmsdetails?source=STS_WEB&type=news`, {headers:Header});
  }
  public getCategoriesData(token): Observable<any> {
    const usernameHeader = {Authorization:`Bearer ${token}`, APIID: '3',  stuno:'0' };
    return this.http.get<any>(`${this.configService.baseUrl}/api/SchoolTransport/GET_STS_APPDATA`, {headers:usernameHeader});
  }
  public postComplaint(data,token): Observable<any> {
    const usernameHeader = {Authorization:`Bearer ${token}` };

    return this.http.post<any>(`${this.configService.baseUrl}/api/SchoolTransport/POST_STS_APPDATA`,data,{headers:usernameHeader});
  }
  public getAwards(token:any): Observable<any> {
    let Header = {
      Authorization:`Bearer ${token}`,
     };
    return this.http.get<any>(`${this.configService.baseUrl}/api/cms/cmsdetails?source=STS_WEB&type=awards`, {headers:Header});
  }
  public getAccreditation(token:any): Observable<any> {
    let Header = {
      Authorization:`Bearer ${token}`,
     };
    return this.http.get<any>(`${this.configService.baseUrl}/api/cms/cmsdetails?source=STS_WEB&type=accreditations`, {headers:Header});
  }
  public getPartnership(token:any): Observable<any> {
    let Header = {
      Authorization:`Bearer ${token}`,
     };
    return this.http.get<any>(`${this.configService.baseUrl}/api/cms/cmsdetails?source=STS_WEB&type=partnerships`, {headers:Header});
  }
  public getVision(token:any): Observable<any> {
    let Header = {
      Authorization:`Bearer ${token}`,
     };
    return this.http.get<any>(`${this.configService.baseUrl}/api/cms/cmsdetails?source=STS_WEB&type=Exploring`, {headers:Header});
  }

  public getLocation(token:any): Observable<any> {
    let Header = {
      Authorization:`Bearer ${token}`,
     };
    return this.http.get<any>(`${this.configService.baseUrl}/api/cms/cmsdetails?source=STS_WEB&type=locations`, {headers:Header});
  }

  public getCity(type:any,defaultToken?:any,cityId?:any): Observable<any>
  {
    let token=JSON.parse(localStorage.getItem('currentToken'));
    
    let Header:any;
    if(type == 'postLogin')
    {
    let child =JSON.parse(localStorage.getItem("parentChildrens"))
    Header = {Authorization:`Bearer ${token?.access_token}`, master: 'CITY_M',  bsuid:child[0]?.bsuId,param1:'172' };
    }
    else {
      let defToken = defaultToken;
      let city = (cityId != null && cityId != '') ? cityId : '172';
      Header = {Authorization:`Bearer ${defToken}`, master: 'CITY_M',  bsuid:"",param1:city };
    }
    return this.http.get<any>(`${this.configService.baseUrl}/api/masters/GetMaster`, {headers:Header});
  }

  public postCallback(formData:any,type:any,defaultToken?:any)
  {
    let token=JSON.parse(localStorage.getItem('currentToken'));
    let child =JSON.parse(localStorage.getItem("parentChildrens"))
    let Header:any;
    if(type == 'postLogin')
    {
       Header = {
        contactName:formData.name,
        contactMobile:formData.phone,
        subject:"Request Callback",
        message:formData.description,
        city:formData.city,
        school:child[0]?.bsuId,
        Authorization:`Bearer ${token?.access_token}`,
       };
    }
    else{
      let defToken = defaultToken;
       Header = {
        contactName:formData.name,
        contactMobile:formData.phone,
        subject:"Request Callback",
        message:formData.description,
        city:formData.city,
        school:"",
        Authorization:`Bearer ${defToken}`,
       };
    } 
    return this.http.post<any>(`${this.configService.baseUrl}/api/transport/POST_TRANSPORT_CALLBACK`,{},{headers:Header});
  }
  public getMdsMessage(token:any): Observable<any> {
    let Header = {
      Authorization:`Bearer ${token}`,
     };
    return this.http.get<any>(`${this.configService.baseUrl}/api/cms/cmsdetails?source=STS_WEB&type=mdmessage`, {headers:Header});
  }
  public subscribeNewsLetter(data:any,token): Observable<any>
  {
   let Header = {
      Authorization:`Bearer ${token}`,
     };
    let body={
      Source:this.configService.source,
      Email:data,
      LanguageId:'En'
    }
    return this.http.post<any>(`${this.configService.baseUrl}/api/Transport/SubscribeToNewsLetter`,body,{headers:Header});
  }

  public postOffers(data:any,token): Observable<any>
  {
   let Header = {
      Authorization:`Bearer ${token}`,
     };
    return this.http.post<any>(`${this.configService.baseUrl}/api/Transport/SaveOfferDetails`,data,{headers:Header});
  }

  public getOffers(token,logintype:any,offerId:any): Observable<any>
  {
   let Header = {
      Authorization:`Bearer ${token}`,
      LOGINTYPE:logintype,
      OFFER_ID:offerId.toString()
     }
    return this.http.get<any>(`${this.configService.baseUrl}/api/Transport/GetSchoolOffers`,{headers:Header});
  }

  getTermsAndPolicy(type:any, token)
  {
    const Header = {
      Authorization: `Bearer ${token}`,
      bsuid :"na",
      type :type,
      city :"na"
    };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetAppTermsandPrivacyPolicy`, { headers: Header })
  }
}
