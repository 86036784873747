import { Component,Inject, OnInit, Renderer2, TemplateRef} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormGroup } from 'ngx-typesafe-forms';
import { requestCallback } from 'src/app/models/requestCallback.model';
import { HomeService } from 'src/app/service/home.service';

@Component({
  selector: 'app-request-callback',
  templateUrl: './request-callback.component.html',
  styleUrls: ['./request-callback.component.scss']
})
export class RequestCallbackComponent implements OnInit {

 callBackForm = new FormGroup<requestCallback>({
   name : new FormControl<string>('',[Validators.required]),
   city:new FormControl<string>('',[Validators.required]),
   description:new FormControl<string>('',[Validators.required]),
   phone:new FormControl<string>('',[Validators.required,Validators.pattern(/^\d{1,10}$/)]),
   captcha:new FormControl<any>('',[Validators.required])
 })
 count:number=0;
 city:any[]=[];
 horizontalPosition: MatSnackBarHorizontalPosition = 'start';
 verticalPosition: MatSnackBarVerticalPosition = 'top';
 digopen:any;
 msg:string="";
 isError:boolean=false;
  constructor(public homeService:HomeService,private _snackBar: MatSnackBar,private dialog: MatDialog,private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,) { }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'rm-body-height');
    var root = document.getElementsByTagName( 'html' )[0];
    this.renderer.addClass(root, 'rm-body-height');

    this.getCity();
  }

  getCity()
  {
    this.homeService.getCity('postLogin').subscribe(
      res => {
        this.city = res?.data;
      })
  }

  checkName()
  {
    if(this.callBackForm.controls.name?.value?.trim() =="")
    {
      this.callBackForm.patchValue({ name: ''})
    }
  }
  onDescChange()
  {
    if(this.callBackForm.controls.description.value && this.callBackForm.controls.description.value.trim() != "")
    {
      this.count=this.callBackForm.controls.description.value.length;
    }
    else{
      this.count=0
      this.callBackForm.patchValue({description :this.callBackForm.controls.description.value.trim() == "" ? "" : this.callBackForm.controls.description.value})
    }
  }

  cancel()
  {
    this.callBackForm.reset();
    this.count=0;
  }
  successMsgPopup(template: TemplateRef<any>) {
    this.digopen = this.dialog.open(template, {
      disableClose: true,
      width: '500px',
      panelClass: 'custom-modalbox'
    });
  }

  onSubmit(template:any)
  {
    this.callBackForm.markAllAsTouched();
    if(this.callBackForm.valid)
    {
    let formData={
      name:this.callBackForm.controls.name.value,
      city:this.callBackForm.controls.city.value,
      description:this.callBackForm.controls.description.value,
      phone:this.callBackForm.controls.phone.value
    }
    this.homeService.postCallback(formData,'postLogin').subscribe(res=>{
     console.log(res)
     if(res.Success=="true")
     {
      // this._snackBar.open('Request send successfully.', '', {
      //   horizontalPosition: this.horizontalPosition,
      //   verticalPosition: this.verticalPosition,
      //   duration: 2000
      // });
      this.cancel();
      this.isError=false;
      this.msg="Request submitted successfully.";
      this.successMsgPopup(template)

     }
     else{
      this.cancel();
      this.isError=true;
      this.msg="Something went wrong";
      this.successMsgPopup(template)
      // this._snackBar.open('Something went wrong.', '', {
      //   horizontalPosition: this.horizontalPosition,
      //   verticalPosition: this.verticalPosition,
      //   duration: 2000
      // });

     }
    })
  }
  }
}
