import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { FormControl, FormGroup } from 'ngx-typesafe-forms';

@Component({
  selector: 'app-recover-login-form',
  templateUrl: './recover-login-form.component.html',
  styleUrls: ['./recover-login-form.component.scss']
})
export class RecoverLoginFormComponent implements OnInit {
  @Input() data: any;
  @Input() disableForm = false;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  
  selectedCountries: string;  
  public countries=['ae', 'qa'];
  validPhoneNumber: boolean=false;
  
  maxDate = new Date(new Date().getFullYear()-1, null,null);
  public loginForm=new FormGroup({    
    primaryMobile: new FormControl('', [Validators.required]),
    studentName: new FormControl('', [Validators.required]),
    studentDob: new FormControl('', [Validators.required])
  });
  constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
    if (this.disableForm) {      
      this.loginForm.disable();
      this.disableForm = true;
    }
    if(this.data){
      this.loginForm.patchValue({
        primaryMobile: this.data?.primaryMobile,
        studentName: this.data?.studentName,
        studentDob: this.data?.studentDob,
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const disableForm = changes['disableForm'];
    if (disableForm.currentValue == true) {
      this.loginForm.disable();
      this.disableForm = true;
    }
  }
  formatPrimaryPhoneNumber($event, currentCtrl) {
    let phoneNumber: any = parsePhoneNumberFromString('+' + currentCtrl.selectedCountry.dialCode + $event.srcElement.value);
    if (phoneNumber && phoneNumber.isValid()) {
      let phone =phoneNumber.nationalNumber;
      this.validPhoneNumber=true;
      $event.srcElement.value = phone;
    }
  }
  changeDOB(event){
    let dob = event.value._d;
    this.loginForm.controls['studentDob'].setValue(this.datePipe.transform(dob, 'yyyy-MM-dd'))
  }

  clearForm(phoneCtrl:any){
    this.loginForm.reset();
    phoneCtrl.value='';
    phoneCtrl.phoneNumber='';
    phoneCtrl.selectedCountry={"name":"United Arab Emirates ","iso2":"ae","dialCode":"971","priority":0,"flagClass":"AE","placeHolder":""};
    this.validPhoneNumber=false;
  }
  submit(){
    this.disableForm=true;
    this.loginForm.disable();
    this.submitEvent.emit(this.loginForm.value);
  }
}
