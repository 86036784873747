import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ReregistrationService {

  appUrl: any;
  constructor(private http: HttpClient,private configService:ConfigService) { 
    this.appUrl=this.configService.appUrl;
  }

  public getSiblingDetails(): Observable<any> {
    let token: any;
    token = localStorage.getItem('currentToken');
    let studentId: any;
    studentId = JSON.parse(localStorage.getItem('selectedstudentid'));
    console.log(studentId);

    const header = {
      Authorization: `Bearer ${token.access_token}`, master: 'SIBLINGDETAIL',
      stunumber: studentId.toString(),
      IsReEnroll: 'yes', ShowAllSibiling:'1'
    };
    return this.http.get(`${this.configService.baseUrl}/api/TPTReEnrollement/GetMaster_ReEnrollment`, { headers: header });
  }
  public getFeeAmount(data): Observable<any> {
    let token: any;
    token = localStorage.getItem('currentToken');
    let child = JSON.parse(localStorage.getItem("parentChildrens"))

    const header =
    {
      "Authorization": `Bearer ${token.access_token}`,
      "BSU_ID": data[0].stU_BSU_ID,
      "ACD_ID": data[0].stU_CURR_ACD_ID.toString(),
      "TRIPTYPE": data[0].TRIPTYPE.toString(),
      "SBL_ID": data[0].SBLID.toString(),
      "DSBLID": data[0].DSBLID.toString(),
      "STU_ID":data[0].STU_ID.toString(),
      "PROVIDER_BSU_ID":data[0].PROVIDER_BSU_ID.toString(),
      "TRM_STARTDT":data[0].TRM_STARTDT,
      "SSV_STARTDT":data[0].SSV_STARTDT,
    };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetSTSFeeAmounts`, { headers: header });
  }

  getTermsAndConditions() {
    let token: any
    token = localStorage.getItem('currentToken');

    let studentId: any;
    studentId = JSON.parse(localStorage.getItem('selectedstudentid'));

    let allChildrens = JSON.parse(localStorage.getItem("allChildren"));
    let child = allChildrens?.find((x:any) => x.studentId == studentId);

    let school = JSON.parse(localStorage.getItem("schoolList"))

    const header =
    {
      "Authorization": `Bearer ${token.access_token}`,
      'BSU_ID': child?.bsuId,
      'BSU_CITY': school[0]?.citycode,
      'GRD_ID':child?.gradeID
    };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetTransportTermsConditions`, { headers: header });
  }

  saveReregistration(data) {

    let token: any;
    token = localStorage.getItem('currentToken');
    const header =
    {
      "Authorization": `Bearer ${token.access_token}`,
    };
    return this.http.post(`${this.configService.baseUrl}/api/TPTReEnrollement/SaveMultiReEnrollmentRegTran`, data, { headers: header });
  }
  public getSchoolList(location: any = ''): Observable<any> {
    let token: any
    token = localStorage.getItem('currentToken');
    const header = { Authorization: `Bearer ${token.access_token}`, schoolName: '', location: location };
    return this.http.get(`${this.configService.baseUrl}/api/transport/GET_SCHOOL_TRANSPORTAION_INFO`, { headers: header });
  }

  postFeeDetails(data) {
    //let data: any = 'https://qa.phoenixsystem.ae/stsconnect';
    const header = {
      language: this.configService.language, paymentTo: this.configService.source,
      apdId: this.configService.AppId.toString(),
      activityFeeCollType: this.configService.client,
      redirectURL: this.configService.redirectionURL,
      paymentOrigin: this.configService.paymentreenrollOrigin
    };
    console.log(header);
    
    return this.http.post(`${this.configService.baseUrl}/api/Payment/POST_FEE_DETAILS`, data, { headers: header });

  }
  public getFeeDetails(obj: any) {


    //return
    let res = this.http.get<any>(`${this.configService.baseUrl}/${environment.api.getFeeDetails}`, {
      headers: {
        "stuid": obj,
        "isPaySibling": "1",
        "providerTypeID": "2",
        "Paymentto": this.configService.paymentTo

      }
    });
    return res;
  }
  public getPaymentType(obj: any) {


    let res = this.http.get<any>(`${this.configService.baseUrl}/${environment.api.getPaymentType}`, obj);
    return res;
  }
  updateStatus(data:any)
  {
    let token: any;
    token = localStorage.getItem('currentToken');
    const header = {Authorization:`Bearer ${token.access_token}`};
   return this.http.post(`${this.configService.baseUrl}/api/TPTReEnrollement/UpdateReenrollmentStatus`,data,{headers:header});
  }

  getTermsAndConditionsByGrade(gradeId: any, bsuId:any) {
    let token: any
    token = localStorage.getItem('currentToken');
    let school = JSON.parse(localStorage.getItem("schoolList"))

    let bsuCity = school?.find((x:any) => x.bsU_ID == bsuId)?.citycode;
    const header =
    {
      "Authorization": `Bearer ${token.access_token}`,
      'BSU_ID': bsuId,
      'BSU_CITY': bsuCity,
      'GRD_ID':gradeId
    };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetTransportTermsConditions`, { headers: header });
  }

  agreeAutoPromotion(data) {

    let token: any;
    token = localStorage.getItem('currentToken');
    const header =
    {
      "Authorization": `Bearer ${token.access_token}`,
    };
    return this.http.post(`${this.configService.baseUrl}/api/Transport/SAVE_STUDENT_TRANSPORT_TNC_DATA`, data, { headers: header });
  }
}
