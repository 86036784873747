import { Component, OnInit, EventEmitter, Output, SecurityContext, ChangeDetectorRef, NgZone, Input, OnDestroy, Renderer2, AfterViewInit } from '@angular/core';
import { Message, User, Action, SendMessageEvent } from '@progress/kendo-angular-conversational-ui';
import { ChatService } from '../../service/chat.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UrlRedirect } from 'src/app/helper/url-redirect';
import { ChatMessage } from 'src/app/models/chatbot.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from 'src/app/service/config.service';
import { Guid } from "guid-typescript";
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import {marked }from "marked";
import { DashboardService } from 'src/app/service/dashboard.service';
import { RegistrationService } from 'src/app/service/registration.service';
import { CommonService } from 'src/app/service';
@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit,OnDestroy, AfterViewInit {

  @Input() fromLocalStorage=true;
  @Input() greetings:any=null;
  @Input() userName:any=null;
  @Input() parentComponent:any=null;
  @Output() chatClosed = new EventEmitter<void>();
  @Output() redirectEvent = new EventEmitter<any>();
  sessionId:any;
  isMinimized = false;
  public readonly user: User = {
    id: 1
  };

  public readonly bot: User = {
    id: 0
  };
  public messages:ChatMessage[]=[];
  isNewMessage=false;
  newMessageCount=0;
  timeOutRef: any;
  baseUrl: any;
  showChatbot=false;
  requestInProgress: boolean=false;
  methods = {
    registerNow: () => this.registerNow()
  };
  student: any;
  constructor(private svc: ChatService,private _sanitizer:DomSanitizer, private configService:ConfigService,private dashboardService:DashboardService,private commonService:CommonService,
    private cdr: ChangeDetectorRef,private ngZone: NgZone,public registerService:RegistrationService,private router:Router,private config:ConfigService, private renderer: Renderer2) {
      this.getNewSession();
      this.baseUrl=this.config.baseUIUrl;
  }

  getNewSession(){
    let sessionId=localStorage.getItem("chatbotsessionid");
    if(sessionId !=null && sessionId!=undefined){
      this.sessionId=sessionId;
    }
    else{
      this.sessionId= Guid.create();
      localStorage.setItem("chatbotsessionid",this.sessionId);
    }
  }
  async ngOnInit() {
    let showGreeting=true;
    if(this.fromLocalStorage && localStorage.getItem('pre-login-chat-history') !=null){
      let chatMessages= JSON.parse(localStorage.getItem('pre-login-chat-history'));
      let chatDate=new Date(chatMessages[0]?.timestamp);
      let currentDate=new Date();
      if(chatDate.toDateString() === currentDate.toDateString()){
        chatMessages.forEach(element => {
          element.timestamp=new Date(element.timestamp);
          this.messages.push(element)
        });
        showGreeting=false;
        this.showChatbot=true;
      }
    }
    else if(this.userName!=null){
      let res =await this.svc.getChatHistory(this.userName).toPromise();
      if(res!=null && res.data !=null && res.data.length > 0){
        let chatHistory= res.data[0].scH_DATA;
        if(chatHistory!=null && chatHistory !=''){
          chatHistory=chatHistory.replaceAll('%28','(').replaceAll('%29',')');
          let parsedHistory:any=decodeURIComponent(JSON.parse(chatHistory));
          if(parsedHistory!=''){
            if(typeof parsedHistory =='string'){
              parsedHistory=JSON.parse(parsedHistory);
            }
            parsedHistory.forEach((element) => {
              element.timestamp=new Date(element.timestamp);
              let dateToCheck=new Date();
              dateToCheck.setDate(dateToCheck.getDate()-1);
              dateToCheck.setHours(0);
              dateToCheck.setMinutes(0);
              dateToCheck.setSeconds(0);
              dateToCheck.setMilliseconds(0);
              if(element.timestamp > dateToCheck){
                this.messages.push(element);
              }
            });
          }
          if(this.messages.length >0){            
            showGreeting=false;
          }
          this.showChatbot=true;
        }
      }
    }

    if(showGreeting)
    {
      if(this.greetings!=null){
        this.messages.push(this.greetings);
      }
      else{
        localStorage.removeItem('chatbotsessionid');
        localStorage.removeItem('pre-login-chat-history');
        this.getNewSession();
        let currentHour=new Date().getHours();
        let greet='';
        if(currentHour<12){
          greet='Good Morning!';
        }
        else if(currentHour<18){
          greet='Good Afternoon!';
        }
        else{
          greet='Good Evening!';
        }
        const greeting: ChatMessage = {
          author: this.bot,
          suggestedActions: preLoginActions,
          timestamp: new Date(),
          text: `Hi ${greet} I\'m Super Driver, your virtual assistant, what would you like to know?`
        };
        this.messages.push(greeting);
      }
      this.showChatbot=true;
    }




  }
  ngAfterViewInit(){

  }

  minimizeChat() {
   this.isMinimized = true;
  }

  maximizeChat(){
    this.isMinimized = false;
    this.isNewMessage=false;
    this.newMessageCount=0;
  }

  public sendMessage(e: SendMessageEvent): void {
    if(e?.message?.text !=null && e?.message?.text!=undefined && e?.message?.text?.trim()!=''){
      let message: ChatMessage={...e.message};
      this.setMessage(message);
    }
  }

  setMessage(e:ChatMessage){
    if(this.timeOutRef){
      clearTimeout(this.timeOutRef);
    }
    e.text = this.htmlProperty(e.text??"");

    this.messages = [...this.messages, e];
    this.enableOrDisableInput(true);
    var chat = this.initializeChatModel(e,this.sessionId);

    this.getAIResponse(chat);

    this.ngZone.run(() => {
      this.messages.forEach((x:any)=>{
        x.disableForm=true;
      });
      this.cdr.detectChanges();
    });
  }

  private getAIResponse(chat: FormData) {
    var urlRedirect= new UrlRedirect();
    let isStart = true;
    let isEndofStream = false;
    this.svc.postChat(chat).subscribe(res => {
      this.svc.getServerSentEvent(res.session_id)
        .subscribe((event: any) => {
          let parsedJson = JSON.parse(event.data);
          isEndofStream = parsedJson?.end_stream ? true : false;
          let message = parsedJson.response;
          if(message!=null && message!=undefined){
            if (isStart) {
              this.messages.push({ author: this.bot, text: message, timestamp: new Date(), suggestedActions: []});
            }
            else if (!isEndofStream) {
              let textMessage = this.messages[this.messages.length - 1].text + message;
              this.messages[this.messages.length - 1].text = textMessage;
            }
          }

          if (parsedJson?.redirect_keyword != undefined && parsedJson?.redirect_keyword != null && parsedJson?.redirect_keyword != '') {

            if(parsedJson?.redirect_keyword =='RENDER_SERVICE_AVAILABILITY_PRELOGIN'){
              this.ngZone.run(() => {
                this.messages[this.messages.length - 1].formName='serviceAvailability';
              });
            }
            else if(parsedJson?.redirect_keyword == 'RENDER_SERVICE_AVAILABILITY_POSTLOGIN'){
              let selectedChild=localStorage.getItem('selectedstudentid');
              let students=JSON.parse(localStorage.getItem('allChildren'))
              let studentDetail=students.find(x=>x.studentId ==selectedChild);
              this.ngZone.run(() => {
                this.messages[this.messages.length -1].student={studentId:studentDetail.studentId,studentName:studentDetail.studentName, acdId:studentDetail.acdId,photoPath:studentDetail.photoPath, bsuId:studentDetail.bsuId};
                this.messages[this.messages.length - 1].formName='serviceAvailability';
              });
            }
            else if(parsedJson?.redirect_keyword =='SELECT_CHILD'){
              let actions:any=[];
              let students=JSON.parse(localStorage.getItem('allChildren'));
              students.forEach(stu => {
                actions.push({
                  type: 'reply-studentName',
                  value: {studentId:stu.studentId,studentName:stu.studentName, acdId:stu.acdId,photoPath:stu.photoPath, bsuId:stu.bsuId}
                })
              });
              this.ngZone.run(() => {
                if(actions.length >0){
                  this.messages[this.messages.length - 1].suggestedActions=actions;                  
                }
              });
            }
            else if(parsedJson?.redirect_keyword =='RECOVER_DETAILS'){
              this.ngZone.run(() => {
                this.messages[this.messages.length - 1].formName='recoverLogin';
              });
            }
            else if(parsedJson?.redirect_keyword =='ASK_RECOVER'){
              this.ngZone.run(() => {
                let actions=[
                  {
                    type: 'reply-type',
                    value: 'Username'
                  },
                  {
                    type: 'reply-type',
                    value: 'Password'
                  }
                ]
                this.messages[this.messages.length - 1].suggestedActions=actions;
              });
            }
            else if(parsedJson?.redirect_keyword=='REGISTRATION_TEMPLATE'){
              let htmlMessage=`Are you already registered? \n <a class="link-title-variant2" href="${this.baseUrl}/login">Proceed to Login</a> \n \nNot registered yet? \n <a class="link-title-variant2" href="${this.baseUrl}/schools-list">Proceed to Register</a>`;

              this.messages.push({ author: this.bot, text: htmlMessage, timestamp: new Date(), suggestedActions: []});
            }
            else if(parsedJson?.redirect_keyword =='SHOW_MENU'){
              let actions=preLoginActions;
              if(this.greetings!=null){
                actions=this.greetings.suggestedActions;
              }
              if (isStart) {
                this.messages.push({ author: this.bot, text: '', timestamp: new Date(), suggestedActions: actions});
              }
              else if (!isEndofStream) {
                this.messages[this.messages.length - 1].suggestedActions = actions;
              }
              localStorage.removeItem('chatbotsessionid');
              this.getNewSession();
            }
            else if(parsedJson?.redirect_keyword =='SELECT_PAYMENT_TYPE'){
              this.ngZone.run(() => {
                let actions=[
                  {
                    type: 'reply-type',
                    value: 'Payment History'
                  },
                  {
                    type: 'reply-type',
                    value: 'Last Payment'
                  },
                  {
                    type: 'reply-type',
                    value: 'Refund'
                  }
                ]
                
                this.messages[this.messages.length - 1].suggestedActions=actions;
              });
            }
            else{
              this.ngZone.run(() => {
                let redirect_keyword =parsedJson.redirect_keyword;
                if(this.parentComponent!=null){
                  redirect_keyword = redirect_keyword+'_'+this.parentComponent
                }
                let redirectData:any = urlRedirect.getRedirectUrl(redirect_keyword);
                if (redirectData != null && redirectData != undefined) {
                  let redirect=this.messages[this.messages.length - 1].redirect;
                  let studentId=null;
                  if(this.student !=null){
                    studentId=this.student.studentId;
                  }
                  else{                    
                    let students=JSON.parse(localStorage.getItem('allChildren'));
                    if(students?.length==1){
                      studentId=students[0]?.studentId;
                    }
                  }
                  redirectData.redirectUrl = redirectData.redirectUrl.replace('<studentNo>',studentId);
                  redirectData.studentId= studentId;
                  if(redirect!=null && redirect.length > 0){
                    redirect.push(redirectData);
                  }
                  else{
                    redirect=[redirectData];
                  }
                  this.messages[this.messages.length - 1].redirect = redirect;
                }
              });
            }
          }
          if (isEndofStream) {
            let messagetext=this.messages[this.messages.length - 1].text;
            let renderedtext=this.renderMarkdown(messagetext);
            
            this.ngZone.run(() => {
              this.messages[this.messages.length - 1].text =renderedtext;
            });
            let endOfConversation = parsedJson?.Convo_complete ? true : false;
            if (endOfConversation) {
              //Call after 60 secs
              this.timeOutRef=setTimeout(() => {
                this.getOtherDetailText();
              }, 60000);
            }
            this.postChatHistory();
            this.enableOrDisableInput(false);
            //scroll to last message
            let msgGroupElements=document.getElementsByClassName('k-message-group');
            if(msgGroupElements !=null && msgGroupElements?.length >0){
              msgGroupElements[msgGroupElements.length-1].scrollIntoView();
            }
          }
          this.ngZone.run(() => {
            this.messages = [...this.messages];
            this.cdr.detectChanges();
            if (isStart) {
              //scroll to last message
              let msgGroupElements=document.getElementsByClassName('chat-wrapper');
              if(msgGroupElements !=null && msgGroupElements?.length >0){
                msgGroupElements[msgGroupElements.length-1].scrollIntoView();
              }
            }
            isStart = false;
          });
        }, err => {
          this.handleError();
        });
    }, (error:HttpErrorResponse) => {
        if(error.status==401){
          this.svc.getToken().then((res)=>{
            if(res){
              this.getAIResponse(chat);
            }
          });
        }
        else{
          this.handleError();
        }
    });
  }

  getOtherDetailText(){
    let message={ text:'get_followup_question_using_history',timestamp:new Date(),suggestedActions:[],author:this.bot };

    this.enableOrDisableInput(true);
    var chat = this.initializeChatModel(message,this.sessionId);
    this.getAIResponse(chat);
  }

  public sendReply(action:Action){
    if(!this.requestInProgress){
      if(action.type=='reply'){
        this.setMessage({ text:action.value,timestamp:new Date(),suggestedActions:[],author:this.user});
      }
      else if(action.type=='reply-type'){
        this.ngZone.run(() => {
          this.messages[this.messages.length-1].suggestedActions=[];
          this.messages = [...this.messages];
          this.cdr.detectChanges();
        });
        this.setMessage({ text:action.value,timestamp:new Date(),suggestedActions:[],author:this.user});
      }
      else if(action.type=='reply-studentName'){
        this.student=action.value;
        this.ngZone.run(() => {
          this.messages[this.messages.length-1].suggestedActions=[];
          this.messages = [...this.messages];
          this.cdr.detectChanges();
        });
        this.setMessage({ text:action.value.studentName,timestamp:new Date(),suggestedActions:[],author:this.user});
      }
      else{
        let message={ text:'',timestamp:new Date(),suggestedActions:moreOptions,author:this.bot};
        this.ngZone.run(() => {
          this.messages.push({ text:action.value,timestamp:new Date(),suggestedActions:[],author:this.user});
          this.messages.push(message);
          this.messages = [...this.messages];
          this.cdr.detectChanges();
          this.postChatHistory();
        });
        //scroll to last message
        let msgGroupElements=document.getElementsByClassName('k-message-group');
        if(msgGroupElements !=null && msgGroupElements?.length >0){
          msgGroupElements[msgGroupElements.length-1].scrollIntoView();
        }
      }
    }
  }

  handleError(){
    this.postChatHistory();
    this.enableOrDisableInput(false);
  }

  initializeChatModel(e:Message,sessionID:any){
    var chatFormData=new FormData();
    chatFormData.append("session_id",sessionID);
    chatFormData.append("input_text",e.text??"");
    chatFormData.append("input_type","text");
    chatFormData.append("persona","Parent");
    chatFormData.append("bot_personality","Super Driver");
    chatFormData.append("stream",'true');
    chatFormData.append("env",this.configService.chatbotSetting.environmentCode);
    chatFormData.append("channel",this.configService.chatbotSetting.channel);
    if(this.userName!=null){
      chatFormData.append("user_id",this.userName);
      if(localStorage.getItem('currentToken')){
        let token=JSON.parse(localStorage.getItem('currentToken'));
        chatFormData.append("access_token",token?.access_token);
      }
    }
    return chatFormData;
  }

  htmlProperty(textString:string) : string {
    var string = this._sanitizer.sanitize(SecurityContext.HTML,textString);
    return this.escapeHtml(string??"");
  }

  escapeHtml(unsafe:string) {
    return unsafe.replace(/<[^>]*>/g, '').replace(/\s\s+/g, ' ');

  }

  enableOrDisableInput(isDisable:boolean){

    var controls=document.getElementsByClassName('k-message-box')[0].children;
    for(let i=0;i<controls.length;i++){
      (controls[i] as any).disabled=isDisable;
    }
    this.requestInProgress=isDisable;
  }
  closeChat(){
    this.ngZone.run(() => {
      this.messages.forEach((x:any)=>{
        x.disableForm=true;
      });
      this.cdr.detectChanges();
    });
    this.postChatHistory();
    this.chatClosed.emit();
    this.renderer.removeClass(document.body, 'bodyoverflow-hidden');
  }

  navigateTo(message:any){
    this.closeChat();
    if(message.redirectType=='redirect'){
      if(message.studentId !=null && message.studentId !=undefined){
        let currentStudent=localStorage.getItem('selectedstudentid');
        if(currentStudent !=message?.studentId){
          
          const modebusEvent = new CustomEvent('app-switch-student', {      
            bubbles: true,
            detail: {
              eventType: 'student-switched',
              studentId: message?.studentId,
              redirectUrl:message.redirectUrl
            }
          });
          dispatchEvent(modebusEvent);
        }
        else{
          this.redirectPage(message)
        }
      }
      else{
        this.redirectPage(message);
      }
    }
    if(message.redirectType == 'method'){
      this[message.redirectUrl]();
    }
    else{
      this.redirectEvent.emit(message);
    }
  }

  redirectPage(message:any){
    if(message.redirectUrl.includes('?')){
      this.router.navigateByUrl(message.redirectUrl);
    }
    else{
      this.router.navigate([message.redirectUrl]);
    }
  }
  postChatHistory(){
    let messages:any=JSON.stringify(this.messages);
    if(this.fromLocalStorage){
      localStorage.setItem('pre-login-chat-history',messages);
    }
    else{
      messages= encodeURIComponent(messages);
      messages=messages.replaceAll('(','%28').replaceAll(')','%29');
      let data={
        SCH_USERNAME:this.userName,
        SCH_DATA:messages,
        SCH_SOURCE:"WEB"
      }
      this.svc.postChatHistory(data).subscribe();
    }
  }

  submitEvent(data:any){
    this.enableOrDisableInput(true);
    let message={ text:'what are the serverice available',timestamp:new Date(),suggestedActions:[],author:this.user}
    var chatFormData=this.initializeChatModel(message,this.sessionId);

    chatFormData.append("intent","service_availability");
    chatFormData.append("bsu_id ",data.SCHOOL);
    chatFormData.append("provider",data.PROVIDER_ID);
    if((data?.PICKUPAREA !=null && data?.PICKUPAREA !='')){
      chatFormData.append("sbl_id",data?.PICKUPAREA);
    }
    else{
      chatFormData.append("sbl_id",'0');
    }
    if((data?.DROPOFFAREA !=null && data?.DROPOFFAREA !='')){
      chatFormData.append("dsblid",data?.DROPOFFAREA);
    }
    else{
      chatFormData.append("dsblid",'0');
    }
    
    chatFormData.append("triptype",data.TPT_TYPE);
    chatFormData.append("acd_id",data.ACD_ID);
    this.messages[this.messages.length-1].data=data;
    this.messages[this.messages.length-1].disableForm=true;
    this.postChatHistory();
    this.getAIResponse(chatFormData);
  }

  submitLoginForm(data:any){
    this.enableOrDisableInput(true);
    this.messages[this.messages.length-1].data=data;
    this.messages[this.messages.length-1].disableForm=true;
    this.postChatHistory();

    let message={ text:'what are the serverice available',timestamp:new Date(),suggestedActions:[],author:this.user}
    var chatFormData=this.initializeChatModel(message,this.sessionId);

    chatFormData.append("intent","recover_username");
    chatFormData.append("input_text","recover username");
    chatFormData.append("primary_contact_number ",data.primaryMobile);
    chatFormData.append("student_fname",data.studentName);
    chatFormData.append("dob",data.studentDob);
    this.getAIResponse(chatFormData);
  }
  ngOnDestroy(): void {
    if(this.timeOutRef){
      clearTimeout(this.timeOutRef);
    }
  }
  isFullScreenChat = false;
  fullscreenChat(){
    this.isFullScreenChat = true;

    if (this.isFullScreenChat == true) {
      // alert('opened')
      this.renderer.addClass(document.body, 'bodyoverflow-hidden');
    } else {
      this.renderer.removeClass(document.body, 'bodyoverflow-hidden');
    }
  }
  regularscreenChat(){
    this.isFullScreenChat = false;
  }
  public renderMarkdown(md: string): string {
     var mark =  marked(md);
      return mark;
  }

  registerNow()
  {
      this.dashboardService.getContactDetails('').subscribe((res:any)=>{
        let contactDetails = res?.data;
        let token=JSON.parse(localStorage.getItem('currentToken'));
        this.registerService.getParentsList(contactDetails?.primaryContactNumber, token).subscribe(res=>{
          let parentDetails= res?.data;
          localStorage.removeItem('photopath')
          localStorage.removeItem('studentDetails');
          localStorage.setItem('parentDetails',JSON.stringify(parentDetails));
          localStorage.setItem('studParentDetails',JSON.stringify(parentDetails));
          this.registerService.sendStudentDetails.next(parentDetails);
          localStorage.setItem("proceed","false");
          localStorage.setItem("showSchool","true")
          this.router.navigate(['/schoolbus-registration'],{queryParams:{isFromDashboard:true,tab:1}});
      });
    })
  }
}

const preLoginActions=[{
  type: 'reply',
  value: 'Registration'
}, {
  type: 'reply',
  value: 'Request a callback'
}, {
  type: 'reply',
  value: 'Recover Login Credentials'
}, {
  type: 'reply',
  value: 'Offers'
}, {
  type: 'reply',
  value: 'Service Availability and Fees'
}];

const moreOptions= [
  {
    type: 'reply',
    value: 'Request Area Change'
  },
  {
  type: 'reply',
  value: 'Register Sibling'
}, {
  type: 'reply',
  value: 'Single-Day Schedule Customisation Request'
}, {
  type: 'reply',
  value: 'Contact SDE '
}, {
  type: 'reply',
  value: 'Re-registration'
}, {
  type: 'reply',
  value: 'Discontinue '
}];