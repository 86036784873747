import { AfterContentInit, AfterViewInit, Component,DoCheck,Inject, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/service';
import { DashboardService } from 'src/app/service/dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { LocalizationService, RTL } from '@progress/kendo-angular-l10n';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit,DoCheck, AfterViewInit   {

  isLeasedSchool: any;
  IsHybrid:any;
  ispaymentcontent: boolean=true;
  showdashboard:boolean=false;
  // @ViewChild('modalTemp') modalTemp1!: TemplateRef<any>;

  constructor( private localizationService: LocalizationService,
    private dialog: MatDialog,
    private common: CommonService,
    private route: ActivatedRoute,
    private router: Router,private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,public dashboardService:DashboardService, ) {
    if (localStorage.getItem('currentToken')) {
      this.router.navigate(['/home']);
    }
  }
  ngDoCheck(): void {
    let data:any = localStorage.getItem('leasemodelschool');
    this.isLeasedSchool = data
    this.IsHybrid=localStorage.getItem('IsHybrid');
  }

  ngOnInit(): void {
    // document.body.classList.remove('body-height-100vh');
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    }, 1000);

    this.renderer.removeClass(document.querySelector('body'), 'body-height-100vh');
    this.dashboardService.isPaymentContent.subscribe(res=>{
      this.ispaymentcontent=res;
 });



 let redirectMode = localStorage.getItem("redirectMode");

 if (redirectMode) {

   setTimeout(() => {
     if(redirectMode=="TPT")
     {
       localStorage.removeItem("redirectMode");
     this.router.navigate(['/transport-information']);
     }
     else if(redirectMode=="TPTPAY")
     {
       localStorage.removeItem("redirectMode");
       this.router.navigate(['/payment-detail']);
     }
   }, 3000);
 }
 else
 {
   this.showdashboard=true;
 }

    // setTimeout(() => {
    //   this.checkAutoPromotion();
    // }, 500);
  }

  ngAfterViewInit(): void {

  }

  // openPopup(template: TemplateRef<any>, value: any) {
  //   this.dialog.open(template, {
  //     disableClose: true,
  //     width: '500px',
  //     panelClass: ['custom-modalbox', 'mapPopup']
  //   });
  // }

  // openPopup() {
  //   this.dialog.open(this.modalTemp1,{
  //     width: '500px',
  //     disableClose: true,
  //   });
  // }

  // checkAutoPromotion(){
  //   this.dashboardService.getAutoPromotionDetails().subscribe((res:any)=>{
  //     if(res?.data?.length > 0){
  //       this.openPopup();
  //     }
  //   });
  // }

  navigatetolink(link) {
    this.dialog.closeAll();
    this.router.navigate([link]);
  }



}
