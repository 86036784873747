import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return '';
        const parts = value.split('/');
        const transformedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        return transformedDate;
    }
}