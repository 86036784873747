import { Component, ViewChild } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { AuthenticationService } from './service';
import { BrowserModule, Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { sessionTimeoutService } from './service/sessionTimeout.service';
import { ConfigService } from './service/config.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
@Directive({ selector: '[template]'})
export class AppComponent {


  @ViewChild('sayHelloTemplate', { static: false }) sayHelloTemplate: TemplateRef<any>;



  timedOut = false;
  lastPing?: Date = null;
  selected:boolean=true;
  digopen:any;
  constructor(private idle: Idle, public title:Title,private configService:ConfigService,
    private keepalive: Keepalive
    ,private router:Router
    ,private dialog: MatDialog
  , private viewContainer: ViewContainerRef
  ,private authenticationService: AuthenticationService,private sessionService:sessionTimeoutService) {
    
    this.configService.loadConfig().subscribe(res=>
      {
        this.sessionService.checkSessionTimeout();
      })
   // this.reset();
  }

  openDialog(template:TemplateRef<any>) {
    this.digopen = this.dialog.open(template, {
      disableClose: true,
      width: '450px',
      panelClass: 'custom-modalbox'
   });

  }

  extendsession()
  {
    this.dialog.closeAll();
    this.sessionService.reset();
  }
  logout()
  {
    this.dialog.closeAll();
    this.authenticationService.logout();
    this.idle.stop();
  }
  cloasedialog()
  {
    this.dialog.closeAll();
    this.authenticationService.logout();
  }ngOnInit() {
    this.title.setTitle(this.configService.portalTitle);
    this.sessionService.dataSubject.subscribe((data: boolean) => {
      if(data)
      this.openDialog(this.sayHelloTemplate);
    });
  }
}
