import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private http: HttpClient,private configService:ConfigService) { }

  getSDEContact() {
    let token: any;
    token = localStorage.getItem('currentToken');
    let bsuid: any;
    bsuid = JSON.parse(localStorage.getItem('parentChildrens'))
    const Header = {
      bsuid: bsuid[0].bsuId, 
      Authorization: `Bearer ${token.access_token}`
    };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetSDEContact`, { headers: Header })
  }

  getBusContact() {
    let token: any;
    token = localStorage.getItem('currentToken');
    let selectedId: any;
    selectedId =localStorage.getItem('selectedstudentid')
    const Header = {
      stuId: selectedId, 
      Authorization: `Bearer ${token.access_token}`
    };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GET_TRANSPORT_GPS_SDE_DETAILS`, { headers: Header })
  }

  getFAQs() {
    let token: any;
    token = localStorage.getItem('currentToken');
    const Header = {
      SOURCE: this.configService.source,
      DEVICEID: this.configService.deviceId,
      Authorization: `Bearer ${token.access_token}`
    };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetFAQs`, { headers: Header })
  }
  public getCategoriesData(): Observable<any> {
    let token: any;
    token = localStorage.getItem('currentToken');
    let stdno:any;
    stdno= localStorage.getItem('selectedstudentid')
    const usernameHeader = {Authorization:`Bearer ${token}`, APIID: '4',  stuno:stdno };
    return this.http.get<any>(`${this.configService.baseUrl}/api/SchoolTransport/GET_STS_APPDATA`, {headers:usernameHeader});
  }

  getFeedbackList(titleId){
    ///api/SchoolTransport/GET_STS_APPDATA_POSTED
    let token: any;
    token = localStorage.getItem('currentToken');
    let stdno:any;
    stdno= localStorage.getItem('selectedstudentid')
    const usernameHeader = {Authorization:`Bearer ${token}`, APIID: '6',  stuno:stdno,
    TITLEID:titleId,
    };
    return this.http.get<any>(`${this.configService.baseUrl}/api/SchoolTransport/GET_MAIN_CONVERSATIONS`, {headers:usernameHeader});
  }
  public getTermsandConditions(): Observable<any> {
    let token: any
    token = JSON.parse(localStorage.getItem('currentToken'));
    let child = JSON.parse(localStorage.getItem("parentChildrens"))
    let school = JSON.parse(localStorage.getItem("schoolList"))

    const header =
    {
      "Authorization": `Bearer ${token.access_token}`,
      'BSU_ID': child[0]?.bsuId,
      'BSU_CITY': school[0]?.citycode
    };
   return this.http.get(`${this.configService.baseUrl}/api/Transport/GetTransportTermsConditions`,{headers:header});
  }
  public getTermsandConditionsforlinks(ctoken:any): Observable<any> {
    let token: any;
    token = ctoken;
    let data=JSON.parse(localStorage.getItem("parentChildrens"));
    let bsuId = data[0]?.bsuId;
    let bsu_city=JSON.parse(localStorage.getItem("schoolDetails"))?.bsu_city
    let provider =JSON.parse(localStorage.getItem("schoolDetails"))?.provider
   const header = {Authorization:`Bearer ${token?.access_token}`,bsu_city:bsu_city,bsu_id: bsuId,provider:provider};
   return this.http.get(`${this.configService.baseUrl}/api/Transport/GetTransportTermsConditions`,{headers:header});
  }
  public getSchoolList(location: any = ''): Observable<any> {
    let token: any
    token = localStorage.getItem('currentToken');
    const header = { Authorization: `Bearer ${token.access_token}`, schoolName: '', location: location };
    return this.http.get(`${this.configService.baseUrl}/api/transport/GET_SCHOOL_TRANSPORTAION_INFO`, { headers: header });
  }

  public getIdCardTypes(): Observable<any> {
    let token: any;
    token = localStorage.getItem('currentToken');
    let bsuId:any;
    bsuId= localStorage.getItem('bsU_ID')
    const usernameHeader = {Authorization:`Bearer ${token}`, bsuid:bsuId };
    return this.http.get<any>(`${this.configService.baseUrl}/api/StudentInfo/GET_STUDEN_ID_TYPES`, {headers:usernameHeader});
  }

  public saveIdCardRequest(data) {

    let token: any;
    token = localStorage.getItem('currentToken');
    const header =
    {
      "Authorization": `Bearer ${token.access_token}`,
    };
    return this.http.post(`${this.configService.baseUrl}/api/StudentInfo/SaveStudentIdRequest`, data, { headers: header });
  }

  postFeeDetails(data) {
    const header = {
      language: this.configService.language, paymentTo: this.configService.source,
      apdId: this.configService.AppId.toString(),
      activityFeeCollType: this.configService.client,
      redirectURL: this.configService.redirectionURL,
      paymentOrigin: this.configService.IDCardSourcePortal
    };
    console.log(header);
    
    return this.http.post(`${this.configService.baseUrl}/api/Payment/POST_FEE_DETAILS`, data, { headers: header });

  }
  public getFeeDetails(obj: any) {


    //return
    let res = this.http.get<any>(`${this.configService.baseUrl}/${environment.api.getFeeDetails}`, {
      headers: {
        "stuid": obj,
        "isPaySibling": "1",
        "providerTypeID": "2",
        "Paymentto": this.configService.paymentTo

      }
    });
    return res;
  }
  public getPaymentType(obj: any) {


    let res = this.http.get<any>(`${this.configService.baseUrl}/${environment.api.getPaymentType}`, obj);
    return res;
  }

  public getAllServiceRequests(): Observable<any> {
    let token: any;
    token = localStorage.getItem('currentToken');
    let selectedStudentNo:any;
    selectedStudentNo= localStorage.getItem('selectedstudentid')
    const header = {Authorization:`Bearer ${token}`, STU_NO:selectedStudentNo };
    return this.http.get<any>(`${this.configService.baseUrl}/api/transport/GetStudentServiceRequests`, {headers:header});
  }
}
