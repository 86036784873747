import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TokenService } from 'src/app/service/token.service';
import { ServiceinfoService } from 'src/app/service';
import { RegistrationService } from 'src/app/service/registration.service';
import { DatePipe } from '@angular/common';
import { ChatService } from 'src/app/service/chat.service';

@Component({
  selector: 'app-service-availability-form',
  templateUrl: './service-availability-form.component.html',
  styleUrls: ['./service-availability-form.component.scss']
})
export class ServiceAvailabilityFormComponent implements OnInit {
  @Input() residentialData: any;
  @Input() serviceFormSubmitted = false;
  @Input() student:any;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  pickupAreaList: any[] = [];
  dropOffAreaList: any[] = [];
  transportTypes: any[] = [];
  emirates: any[] = [];
  residentialsDetails: FormGroup= new FormGroup({
    SCHOOL: new FormControl('', Validators.required),
    ACD_ID: new FormControl(''),
    PROVIDER_ID: new FormControl(''),
    EMIRATE: new FormControl('', Validators.required),
    TPT_TYPE: new FormControl(null, Validators.required),
    PICKUPAREA: new FormControl(null),
    DROPOFFAREA: new FormControl(null)
  });
  isPickupOnly: boolean = false;
  isDropOnly: boolean = false;
  token: any;
  schools: any[] = [];
  tptType: any;
  tempDropOffAreaList: any;
  tempPickupAreaList: any;
  academicYearId: any;
  disableSubmit: boolean = false;
  selectedSchool:any;
  selectedEmirate:any;
  selectedTransportType:any;
  selectedPickupArea:any;
  selectedDropOffArea:any;
  tempSchools: any;
  tempTransportTypes: any;
  tempEmirates: any;
  showSchoolPopup: boolean=false;
  showTransportTypePopup: boolean=false;
  showEmiratePopup: boolean=false;
  showPickupPopup: boolean=false;
  showDropOffPopup: boolean=false;
  showSchool: boolean=true;

  constructor(private tokenService: TokenService, private serviceinfoService: ServiceinfoService,
    public registerService: RegistrationService, private datePipe: DatePipe,private chatService:ChatService) { }

  ngOnInit(): void {
    if (!this.serviceFormSubmitted) {
      this.getToken();
    }
    else {
      this.disableForms();
    }
  }

  disableForms() {
    if (this.residentialData?.TPT_TYPE == 0 || this.residentialData?.TPT_TYPE == 1) {
      this.isPickupOnly = true;
    }

    if (this.residentialData?.TPT_TYPE == 0 || this.residentialData?.TPT_TYPE == 2) {
      this.isDropOnly = true;
    }
    this.residentialsDetails.disable();
    this.disableSubmit = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    const serviceFormSubmitted = changes['serviceFormSubmitted'];
    if (serviceFormSubmitted?.currentValue == true) {
      this.disableForms();
    }
  }

  getToken() {
    if(this.chatService.phoenixToken !='' && !this.chatService.phoenixTokenExpired){
      this.token=this.chatService.phoenixToken;
      if(!this.isPostLogin()){
        this.getSchoolList();
      }
    }
    else{
      this.chatService.phoenixToken='';
      this.tokenService.GetToken(null,true).subscribe(
        res => {
          this.token = res?.access_token;
          this.chatService.setPhoenixToken(res);
          if(!this.isPostLogin()){
            this.getSchoolList();
          }
        }
      );
    }
  }

  isPostLogin(){
    let postLogin=false;
    if(this.student !=null){
      this.selectedSchool=this.student?.bsuId;
      let schoolDetails=JSON.parse(localStorage.getItem('schoolList') ?? '');
      this.schools=schoolDetails;
      this.tempSchools=schoolDetails;
      let school=schoolDetails.find(x=>x.bsU_ID==this.selectedSchool)
      this.residentialsDetails.patchValue({
        SCHOOL:this.student?.bsuId,
        PROVIDER_ID:school?.provider,
        ACD_ID: this.student?.acdId
      });
      if(this.residentialData!=null){
        this.residentialData.schoolName=school?.schoolName;
        this.residentialData.emirateName=null;
        this.residentialData.transportType=null;
        this.residentialData.pickupAreaName=null;
        this.residentialData.dropOffAreaName=null;
      }
      else{
        this.residentialData={
          schoolName:school?.schoolName
        }
      }
      postLogin=true;
      this.academicYearId = this.student?.acdId;
      this.getMasters();
    }
    return postLogin;
  }
  getSchoolList() {
    this.registerService.getSchoolList(this.token).subscribe(res => {
      this.schools = res;
      this.tempSchools = res;
    });
  }

  onSchoolOpen() {
    if (!this.serviceFormSubmitted) {
      this.schools = this.tempSchools;
      this.showSchoolPopup=true;
      if (this.residentialsDetails.value.SCHOOL) {
        this.selectedSchool = this.residentialsDetails.value.SCHOOL;
      }
      else {
        this.selectedSchool = this.schools[0].bsU_ID;
      }
    }
  }

  onEmiratesOpen() {
    
    if (!this.serviceFormSubmitted) {
      this.emirates = this.tempEmirates;
      this.showEmiratePopup=true;
      if (this.residentialsDetails.value.EMIRATE) {
        this.selectedEmirate = this.residentialsDetails.value.EMIRATE;
      }
      else {
        this.selectedEmirate = this.emirates[0].id;
      }
    }
  }

  onTransportTypesOpen() {
    if (!this.serviceFormSubmitted) {
      this.transportTypes = this.tempTransportTypes;
      this.showTransportTypePopup=true;
      if (this.residentialsDetails.value.TPT_TYPE) {
        this.selectedTransportType = this.residentialsDetails.value.TPT_TYPE;
      }
      else {
        this.selectedTransportType = this.transportTypes[0].id;
      }
    }
  }

  onPickupAreaOpen() {
    
    if (!this.serviceFormSubmitted) {
      this.pickupAreaList = this.tempPickupAreaList;
      this.showPickupPopup=true;
      if (this.residentialsDetails.value.PICKUPAREA) {
        this.selectedPickupArea = this.residentialsDetails.value.PICKUPAREA;
      }
      else {
        this.selectedPickupArea = this.pickupAreaList[0].id;
      }
    }
  }

  onDropOffAreaOpen() {
    
    if (!this.serviceFormSubmitted) {
      this.dropOffAreaList = this.tempPickupAreaList;
      this.showDropOffPopup=true;
      if (this.residentialsDetails.value.DROPOFFAREA) {
        this.selectedDropOffArea = this.residentialsDetails.value.DROPOFFAREA;
      }
      else {
        this.selectedDropOffArea = this.dropOffAreaList[0].id;
      }
    }
  }

  onSchoolChange() {
    this.showSchoolPopup=false;
    this.residentialsDetails.patchValue({
      SCHOOL:this.selectedSchool
    });
    this.emirates = [];
    this.transportTypes = [];
    this.pickupAreaList = [];
    this.dropOffAreaList = [];
    this.isDropOnly = false;
    this.isPickupOnly = false;
    this.selectedEmirate=null;
    this.selectedTransportType=null;
    this.selectedPickupArea=null;
    this.selectedDropOffArea=null;
    let school = this.schools.find((x: any) => x.bsU_ID == this.residentialsDetails.value.SCHOOL);
    if(this.residentialData!=null){
      this.residentialData.schoolName=school?.schoolName;
      this.residentialData.emirateName=null;
      this.residentialData.transportType=null;
      this.residentialData.pickupAreaName=null;
      this.residentialData.dropOffAreaName=null;
    }
    else{
      this.residentialData={
        schoolName:school?.schoolName
      }
    }
    this.residentialsDetails.patchValue({
      ACD_ID: null,
      PROVIDER_ID: school?.provider,
      EMIRATE: null,
      TPT_TYPE: null,
      PICKUPAREA: null,
      DROPOFFAREA: null
    });
    this.academicYearId = 0;
    this.getMasters();
    this.getAcademicyear();
  }

  getAcademicyear() {
    let bsuId = this.residentialsDetails.value.SCHOOL;
    let serviceDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.registerService.getAcademicYrs(this.token, serviceDate, bsuId,true).subscribe(res => {
      if (res?.data != null) {
        let academicYrId = res?.data;
        this.academicYearId = academicYrId[0]?.id;
        this.residentialsDetails.patchValue({
          ACD_ID: this.academicYearId
        });
      }
    });
  }

  getMasters() {
    let bsuId = this.residentialsDetails.value.SCHOOL;
    let obj1: any = {
      headers: {
        "master": "TPT_TYPE",
        "bsuid": bsuId.toString(),
        "Authorization": "Bearer " + this.token,
        "HideSpinner":"true"
      }
    }
    let obj2: any = {
      headers: {
        "master": "TPT_CITY_M",
        "param1": "172",
        "bsuid": bsuId,
        "Authorization": "Bearer " + this.token,
        "HideSpinner":"true"
      }
    }
    this.serviceinfoService.getMaster(obj1).subscribe(res => {
      this.transportTypes = res["data"]?.filter(x => x.id != -1).map(x => ({ descr: x.descr, id: x.id.toString() }));
      this.tempTransportTypes = res["data"]?.filter(x => x.id != -1).map(x => ({ descr: x.descr, id: x.id.toString() }));
    });
    this.serviceinfoService.getMaster(obj2).subscribe(res => {
      this.emirates = res["data"];
      this.tempEmirates = res["data"];
    });
  }
  checkTPTType() {
    this.showTransportTypePopup=false;
    let data: any[];
    data = this.transportTypes.filter(x => x.id == this.selectedTransportType);

    this.residentialsDetails.patchValue({
      TPT_TYPE:this.selectedTransportType
    });
    if(this.residentialData!=null){
      this.residentialData.transportType=data[0]?.descr;
      this.residentialData.pickupAreaName=null;
      this.residentialData.dropOffAreaName=null;
    }
    else{
      this.residentialData={
        transportType:data[0]?.descr
      }
    }
    this.tptType = data[0].id
    if (data[0].id == 0) {
      this.isPickupOnly = true;
      this.isDropOnly = true;
      this.residentialsDetails.controls['PICKUPAREA'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFAREA'].setValidators([Validators.required]);
    }
    if (data[0].id == 1) {
      this.isPickupOnly = true;
      this.isDropOnly = false;
      this.residentialsDetails.controls['PICKUPAREA'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFAREA'].clearValidators();
    }
    if (data[0].id == 2) {
      this.isPickupOnly = false;
      this.isDropOnly = true;
      this.residentialsDetails.controls['DROPOFFAREA'].setValidators([Validators.required]);
      this.residentialsDetails.controls['PICKUPAREA'].clearValidators();
    }
    this.residentialsDetails.controls['DROPOFFAREA'].markAsUntouched();
    this.residentialsDetails.controls['DROPOFFAREA'].markAsPristine();
    this.residentialsDetails.controls['PICKUPAREA'].markAsUntouched();
    this.residentialsDetails.controls['PICKUPAREA'].markAsPristine();
    this.residentialsDetails.patchValue({
      PICKUPAREA: '',
      DROPOFFAREA: ''
    });
    this.selectedPickupArea=null;
    this.selectedDropOffArea=null;
  }
  onEmiratesChange() {
    this.showEmiratePopup=false;
    this.residentialsDetails.patchValue({
      EMIRATE:this.selectedEmirate
    });
    let emirate=this.emirates.find((x:any)=>x.id==this.selectedEmirate)
    if(this.residentialData!=null){
      this.residentialData.emirateName=emirate?.descr;
      this.residentialData.pickupAreaName=null;
      this.residentialData.dropOffAreaName=null;
    }
    else{
      this.residentialData={
        emirateName:emirate?.descr
      }
    }
    this.getAreaBasedOnCity();
  }
  getAreaBasedOnCity() {
    this.pickupAreaList = [];
    this.dropOffAreaList = [];
    this.selectedPickupArea=null;
    this.selectedDropOffArea=null;
    this.residentialsDetails.patchValue({
      PICKUPAREA: null,
      DROPOFFAREA: null
    });
    if (this.residentialsDetails.value.EMIRATE != "") {
      let bsuId = this.residentialsDetails.value.SCHOOL;
      let obj: any = {
        headers: {
          "master": "AREA_M_TPT_R",
          "param1": this.residentialsDetails.value.EMIRATE,
          "bsuid": bsuId,
          "acdid": `${this.academicYearId}` ?? '0',
          "Authorization": "Bearer " + this.token,
          "HideSpinner":"true"
        }
      }
      this.serviceinfoService.getMaster(obj).subscribe(res => {
        if (res['data'] != null) {
          let areaList = res['data']?.filter((x: any) => x.id != -1);
          this.pickupAreaList = JSON.parse(JSON.stringify(areaList));
          this.dropOffAreaList = JSON.parse(JSON.stringify(areaList));
          this.tempPickupAreaList = JSON.parse(JSON.stringify(areaList));
          this.tempDropOffAreaList = JSON.parse(JSON.stringify(areaList));
        }
      });
    }
  }
  clearAreaSearch(type: any) {
    if (type == 'pickupArea') {
      this.pickupAreaList = this.tempPickupAreaList;
    } else if (type == 'dropoffArea') {
      this.dropOffAreaList = this.tempDropOffAreaList;
    }
  }
  clearSearchValue(event: any) {
    event.target.value = '';
  }

  searchSchool(value:any){
    let filter = value?.toLowerCase();
    if (filter != '') {
      this.schools = this.tempSchools.filter(option => option.schoolName.toLowerCase().includes(filter));
    } else {
      this.schools = this.tempSchools
    }
  }

  searchEmirates(value:any){
    let filter = value?.toLowerCase();
    if (filter != '') {
      this.emirates = this.tempEmirates.filter(option => option.descr.toLowerCase().includes(filter));
    } else {
      this.emirates = this.tempEmirates
    }
  }

  searchTransportTypes(value:any){
    let filter = value?.toLowerCase();
    if (filter != '') {
      this.transportTypes = this.tempTransportTypes.filter(option => option.descr.toLowerCase().includes(filter));
    } else {
      this.transportTypes = this.tempTransportTypes
    }
  }
  onKey(value) {
    this.pickupAreaList = this.search(value);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    if (filter != '') {
      return this.tempPickupAreaList.filter(option => option.descr.toLowerCase().includes(filter));
    } else {
      return this.pickupAreaList = this.tempPickupAreaList
    }
  }
  onKeyPoint(value) {
    this.dropOffAreaList = this.searchPoint(value);
  }
  searchPoint(value: string) {
    let filter = value.toLowerCase();
    if (filter != '') {
      return this.tempDropOffAreaList.filter(option => option.descr.toLowerCase().includes(filter));
    } else {
      return this.dropOffAreaList = this.tempDropOffAreaList
    }
  }
  submit() {
    this.residentialsDetails.disable();
    this.disableSubmit = true;
    let residentialData = this.residentialsDetails.value;

    let school = this.schools.find((x: any) => x.bsU_ID == residentialData.SCHOOL);
    residentialData.schoolName = school?.schoolName ?? '';

    let transportType = this.transportTypes.find((x: any) => x.id == residentialData.TPT_TYPE);
    residentialData.transportType = transportType?.descr ?? '';

    let emirate = this.emirates.find((x: any) => x.id == residentialData.EMIRATE);
    residentialData.emirateName = emirate?.descr ?? '';

    if (residentialData.TPT_TYPE == 0 || residentialData.TPT_TYPE == 1) {
      let pickupArea = this.pickupAreaList.find((x: any) => x.id == residentialData.PICKUPAREA);
      residentialData.pickupAreaName = pickupArea?.descr ?? '';
    }

    if (residentialData.TPT_TYPE == 0 || residentialData.TPT_TYPE == 2) {
      let dropOffArea = this.dropOffAreaList.find((x: any) => x.id == residentialData.DROPOFFAREA);
      residentialData.dropOffAreaName = dropOffArea?.descr ?? '';
    }

    this.submitEvent.emit(residentialData);
  }

  onAreaChange(type:string){
    if(type=='pickup'){
      this.showPickupPopup=false;
      this.residentialsDetails.patchValue({
        PICKUPAREA:this.selectedPickupArea
      });
      let area=this.pickupAreaList.find((x:any)=>x.id==this.selectedPickupArea);
      if(this.residentialData!=null){
        this.residentialData.pickupAreaName=area?.descr;
      }
      else{
        this.residentialData={
          pickupAreaName:area?.descr
        }
      }
    }
    else{
      this.showDropOffPopup=false;
      this.residentialsDetails.patchValue({
        DROPOFFAREA:this.selectedDropOffArea
      });
      let area=this.dropOffAreaList.find((x:any)=>x.id==this.selectedDropOffArea);
      if(this.residentialData!=null){
        this.residentialData.dropOffAreaName=area?.descr;
      }
      else{
        this.residentialData={
          dropOffAreaName:area?.descr
        }
      }
    }
  }

  clearForm(){
    this.residentialData=null;
    this.residentialsDetails.reset();
    this.selectedSchool=null;
    this.selectedEmirate=null;
    this.selectedTransportType=null;
    this.selectedPickupArea=null;
    this.selectedDropOffArea=null;
    this.isDropOnly=false;
    this.isPickupOnly=false;
  }
}
