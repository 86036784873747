import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, NumberValueAccessor, Validators } from '@angular/forms';
import {
  MatDialog, MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TransportserviceService } from 'src/app/transportservice.service';

@Component({
  selector: 'app-transport-discontinuation',
  templateUrl: './transport-discontinuation.component.html',
  styleUrls: ['./transport-discontinuation.component.scss']
})
export class TransportDiscontinuationComponent implements OnInit {
  digopen: any;
  allTransportDetails: any[] = [];
  transportDetails: any;
  radiobtn: any;
  DiscontinuationMaxDate: any;
  form = new FormGroup<any>({
    school: new FormControl<any>(''),
    name: new FormControl<any>(''),
    studentId: new FormControl<any>(''),
    grade: new FormControl<any>(''),
    section: new FormControl<any>(''),
    busNo: new FormControl<any>(''),
    address: new FormControl<any>(''),
    reason: new FormControl<any>('', [Validators.required]),
    otherDesc: new FormControl<any>(''),
    requestedDate: new FormControl<any>('', [Validators.required]),
    // serviceNo:new FormControl<any>('',[Validators.required]),
    description: new FormControl<any>('')
  })
  isOtherTextbox: boolean = false;
  otherCount: number = 0;
  minOtherDescErr: boolean = false;
  Count: number = 0;
  minDescErr: boolean = false;
  resonForDiscontinuation: any[] = [];
  referenceNumber: any;
 
  minDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
    postSuccess: boolean = false
  errmsg: any = '';
  isDisableDate: boolean = false;
  discontinuationDates: any[] = [];

  constructor(private dialog: MatDialog, public transportService: TransportserviceService, private datePipe: DatePipe,
    public router: Router) { }

  ngOnInit(): void {
    this.getTransportDetails();
    this.getReasonForDiscontinuation();
    this.getDiscontinuationDateRange();
  }

  getReasonForDiscontinuation() {
    this.transportService.getReasonForDiscontinuation().subscribe(res => {
      this.resonForDiscontinuation = [...res?.data];
    })
  }

  getDiscontinuationDateRange(){
    this.transportService.getDiscontinuationDateRange().subscribe((res:any) => {
      if(res.data != null){
        if(res.data.length > 0){
          this.discontinuationDates = res.data;
        }
        // this.minDate = new Date(res.data.diS_FROMDATE);
        // this.DiscontinuationMaxDate = new Date(res.data.diS_TODATE);

        // this.isDisableDate =(!(new Date(res.data.diS_TODATE) >= new Date()));
      }
    })
  }

  getTransportDetails() {
    this.transportService.getTransportInfoOfChildren().subscribe(res => {
      this.allTransportDetails = [...res?.data];
      let student = JSON.parse(localStorage.getItem("parentChildrens"))
      let transDetails = this.allTransportDetails.find(x => x.stU_ID == student[0].stuId);
      this.transportDetails = transDetails;
      this.bindingDetails();
    });
  }

  onChangeRadio() {
    this.form.controls.description.markAsUntouched();
    this.form.patchValue({ description: '' })
    if (this.radiobtn == '2') {
      // setTimeout(()=>{
      //   this.form.controls.description.setValidators([Validators.required]);
      // },100)
      this.minDescErr = true;
    } else this.minDescErr = false;
  }

  onDescChange() {
    if (this.form.value.description && this.form.value.description.trim() != "") {
      this.Count = this.form.value.description.length;
      if (this.Count <= 9)
        this.minDescErr = true;
      else this.minDescErr = false;
    }
    else {
      this.Count = 0;
      // this.minDescErr=false;
      this.form.value.description = this.form.value.description?.trim() == "" ? "" : this.form.value.description
    }
  }


  onOtherDescChange() {
    if (this.form.value.otherDesc && this.form.value.otherDesc.trim() != "") {
      this.otherCount = this.form.value.otherDesc.length;
      if (this.otherCount <= 9)
        this.minOtherDescErr = true;
      else this.minOtherDescErr = false;
    }
    else {
      this.otherCount = 0;
      this.minOtherDescErr = false;
      this.form.value.otherDesc = this.form.value.otherDesc?.trim() == "" ? "" : this.form.value.otherDesc
    }
  }


  bindingDetails() {
    if (this.transportDetails) {
      let grade = this.transportDetails?.grade.split('/');
      this.transportDetails.grade = grade[0];

      this.form.patchValue({
        school: this.transportDetails?.school,
        name: this.transportDetails?.studentName,
        studentId: this.transportDetails?.stU_SCHOOL_ID,
        grade: this.transportDetails?.grade,
        section: this.transportDetails?.section,
        busNo: this.transportDetails?.pickupBusNo,
        address: this.transportDetails?.pickuppoint + ' / ' + this.transportDetails?.dropoffpoint,
      })
      this.radiobtn = "1";
    }
  }

  ChangeReason() {
    this.form.patchValue({ otherDesc: '' })
    let value = this.resonForDiscontinuation.find(x => x.id == this.form.value.reason)
    if (value) {
      if (value?.description == "Others") {
        this.isOtherTextbox = true;
        this.form.controls.otherDesc.setValidators([Validators.required]);
      }
      else {
        this.isOtherTextbox = false;
        this.form.controls.otherDesc.setErrors(null);
      }
      // if(value?.description == "TC/SO/Changing School")
      // {
      //   this.minDate=new Date();
      // }else this.minDate= new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
    }
  }
  saveDiscontinuation(template: any) {
    if (!this.form.invalid && !this.minDescErr && !this.minOtherDescErr) {
      let formattedDate: any
      if (this.form.value.requestedDate != null) {
        const stringDate = new Date(this.form.value.requestedDate);
        formattedDate = this.datePipe.transform(stringDate, 'yyyy-MM-dd')
      }
      let body = {
        StuId: this.transportDetails?.studentNo,
        DiscontinuationTypeId: "P",
        DiscontinuationReasonId: this.form.value.reason,
        DiscontinuationReasonRemarks: this.form.value.otherDesc,
        FromDate: formattedDate + "T00:00:00",
        Comments: this.form.value.description,
      }
      this.transportService.postDiscontinuation(body).subscribe(res => {
        if (res?.success == "true") {
          this.postSuccess = true;
          this.referenceNumber = res?.data
          this.successDialog(template);
        } else {
          this.postSuccess = false;
          this.errmsg = res?.data;
          this.successDialog(template);
        }
      })
    }
  }
  ok() {
    this.digopen.close();
    if (this.postSuccess)
      this.router.navigate(['/transport-information']);
  }
  cancel() {
    this.router.navigate(['/transport-information']);
  }
  // addSiblingDialog(templateRef: TemplateRef<any>) {
  //   this.digopen = this.dialog.open(templateRef, {
  //     width: '500px',
  //     panelClass: 'custom-modalbox'
  //   });
  // }
  successDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      width: '550px',
      panelClass: 'custom-modalbox'
    });
  }

}
