import { Component, OnInit } from '@angular/core';
import { SupportService } from 'src/app/service/support.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqDetails: any[]=[];

  constructor(private supportService:SupportService) { }

  ngOnInit(): void {
    this.faqDetails=[];
    this.supportService.getFAQs().subscribe(
      (res:any)=>{
         this.faqDetails = res?.data;
      }
    )
  }

}
