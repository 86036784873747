import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from './../environments/environment';
import { TokenService } from './service/token.service';
import { Token } from './models/token';
import { switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from './service';
import { formatDate } from '@angular/common';
import { ConfigService } from './service/config.service';
@Injectable({
  providedIn: 'root'
})
export class TransportserviceService {
  private currentTokenSubject = new BehaviorSubject<Token>(undefined);
  public editSchedule = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient, private tokenService: TokenService,public authService:AuthenticationService,
    private configService:ConfigService) { }
  public GetNonGemsSchoolsListByCity(obj: any) {

    // this.tokenService.GetToken("").subscribe(tknresult => {
    //   alert(tknresult)
    //   let tnk = tknresult
    // }

    // );
    // .pipe(
    //   tap((token: Token) => {
    //     this.currentTokenSubject.next(token);
    //     localStorage.setItem('currentToken', JSON.stringify(token));
    //   }),
    //   switchMap(() => this.http.get<any>(`${this.configService.localapi}/${this.configService.api.transportrequest}`, obj)),
    // tap((user: User) => {
    //   if (!user.errorCode) {
    //     this.currentUserSubject.next(user);
    //     localStorage.setItem('currentUser', JSON.stringify(user));
    //   }
    // })
    // )
    return this.http.get<any>(`${this.configService.baseUrl}/${environment.api.transportrequest}`, obj);
  }
  getTransportInfoOfChildren()
  {
    let user = localStorage.getItem("parent")
    let headers = new HttpHeaders();
    headers=headers.set('oluName',user)
    .set('Language',"En")
    .set('Authorization','Bearer '+this.authService.currentToken?.access_token)
    return this.http.get<any>(`${this.configService.baseUrl}/${environment.api.transportInfoOfChildren}`,{headers:headers});
  }

  public RequestSchedulechange(fordata:any): Observable<any> {

   // const paymentheaders = { "rpdID": fordata.rpdID, "rpdStuID": JSON.parse(localStorage.parentChildrens)[0].studentId,"rpdFromDate":fordata.rpdFromDate,"rpdToDate":fordata.rpdToDate,"rpdRemarks":fordata.rpdRemarks,"editable":fordata.editable,"delete": fordata.deleted,"rpdJourney":fordata.rpdJourney};

    let res = this.http.post<any>(`${this.configService.baseUrl}/${environment.api.requestSchedulechange}`,fordata, {});
    return res;
  }

  public GetScheduleList(token)
  {
   let Header = {
      Authorization:`Bearer ${token}`,
      LanguageId:'en',
      stuid: JSON.parse(localStorage.parentChildrens)[0].studentId
     //stuid:"50099999000126"
     };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GET_PERSONAL_PICKUP_DROP`,{headers:Header});
  }

  // public getMaster(obj) {
  //   return this.http.get<any>(`${this.configService.baseUrl}/${this.configService.api.getMasters}`, obj);
  // }

  getMaster():Observable<any>
  {
    let user = localStorage.getItem("parent")
    let child =JSON.parse(localStorage.getItem("parentChildrens"))
    let headers = new HttpHeaders();
    headers=headers.set('master',"TRIP_TYPE")
    .set('bsuid',child[0]?.bsuId)
    .set('Authorization','Bearer '+this.authService.currentToken?.access_token)
    return this.http.get<any>(`${this.configService.baseUrl}/${environment.api.getMasters}`,{headers:headers});
  }

  getReasonForDiscontinuation()
  {
    let headers = new HttpHeaders();
    let child =JSON.parse(localStorage.getItem("parentChildrens"))
    headers=headers.set('master',"TRIP_TYPE")
    .set('BSU_ID',child[0]?.bsuId)
    .set('Authorization','Bearer '+this.authService.currentToken?.access_token)
   .set('MASTER',"DiscontinuationReason") 
   .set('SOURCE',"WEB") 
    return this.http.get<any>(`${this.configService.baseUrl}/api/Transport/GetMastersDiscontinuation`,{headers:headers});
  }

  postDiscontinuation(body:any)
  {
    let Header = {
      Authorization:`Bearer ${this.authService.currentToken?.access_token}`,
     };
     let reqBody={
      StuId: Number(body.StuId),
      DiscontinuationTypeId:body.DiscontinuationTypeId,
      DiscontinuationReasonId: Number(body.DiscontinuationReasonId),
      DiscontinuationReasonRemarks:body.DiscontinuationReasonRemarks,
      FromDate: body.FromDate,
      Comments: body.Comments,
      source:this.configService.applicationSource
    }
    return this.http.post<any>(`${this.configService.baseUrl}/api/Transport/SaveDiscontinuationRquest`,reqBody,{headers:Header});
  }
  getStsPaymentDue(){
    //
    let bsuid:any;
    bsuid = JSON.parse(localStorage.getItem('currentUser'))

    const header={
      StudentNo: localStorage.getItem('selectedstudentid')?.toString(),
      BSUID: bsuid.schooId,
      OLUNAME: localStorage.getItem('loginuser')
    }

    return this.http.get<any>(`${this.configService.baseUrl}/api/payment/GetSTSPaymentDue`,{headers:header});

  }
  getReason()
  {
    let Header = {
      SOURCE: "RR",
      Authorization:`Bearer ${this.authService.currentToken?.access_token}`
     };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetNotReEnrollmentReasons`,{headers:Header});
  }

  getTransportTypes():Observable<any>
  {
    let child =JSON.parse(localStorage.getItem("parentChildrens"))
    let headers = new HttpHeaders();
    headers=headers.set('master',"TRASPORT_TYPE")
    .set('bsuid',child[0]?.bsuId)
    .set('Authorization','Bearer '+this.authService.currentToken?.access_token)
    return this.http.get<any>(`${this.configService.baseUrl}/${environment.api.getMasters}`,{headers:headers});
  }

  getDiscontinuationDateRange()
  {
    let headers = new HttpHeaders();
    let studentId =localStorage.getItem("selectedstudentid")
    headers=headers.set('STU_NO',studentId)
    .set('Authorization','Bearer '+this.authService.currentToken?.access_token)
    
    return this.http.get<any>(`${this.configService.baseUrl}/api/transport/Get_discontinuation_dates`,{headers:headers});
  }

  getBusTrackingDetails()
{
  const Header = {
    Authorization: `Bearer ${this.authService.currentToken?.access_token}`,
    apiId:"1",
    StuNo: localStorage.getItem("selectedstudentid"),
    Minutes:'',
    HideSpinner:"true"
  };
  return this.http.get(`${this.configService.baseUrl}/api/Transport/GETSTUDENTETAMAPDETAILS`, { headers: Header });
}
}
