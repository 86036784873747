import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MessageComponent } from 'src/app/message/message.component';
import { DashboardService } from 'src/app/service/dashboard.service';
import { FileService } from 'src/app/service/file.service';

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.scss']
})
export class MyaccountComponent implements OnInit {

  accountDetails:any;
  file: File;
  imageUrl: any;
  base64Conversion: any;
  fileType: any;
  base64String: any;
  fileErr:boolean=false;
  constructor(public dashboardService:DashboardService,private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAccount();
    this.getuserProfileImg();
    //  setTimeout(() => {
    //   this.dialog.closeAll()
    // }, 5000);
    this.dialog.closeAll();
  }

  getAccount()
  {
    this.dashboardService.getAccountDetails().subscribe((res:any)=>{
     if(res?.data.primaryContactNumber)
     {
      let number = res.data.primaryContactNumber;
      //  if(res?.data.primaryContactNumber.length == 12)
      //  {
      //     let result="+"+number.substr(0, 3)+" "+number.substr(3, 2)+" "+number.substr(5, 3)+" "+number.substr(8)
      //     res.data.primaryContactNumber=result;
      //  }else if(res?.data.primaryContactNumber.length == 11)
      //  {
        number=number.replace("+",'');
        let result="+"+number.substr(0, 3)+" "+number.substr(3, 2)+" "+number.substr(5, 3)+" "+number.substr(8)
        res.data.primaryContactNumber=result;
      //  }
     }
     if(res?.data.secondaryContactNumber)
     {
      let number = res.data.secondaryContactNumber;
        number=number.replace("+",'');
        let result="+"+number.substr(0, 3)+" "+number.substr(3, 2)+" "+number.substr(5, 3)+" "+number.substr(8)
        res.data.secondaryContactNumber=result;
     }
     this.accountDetails=res?.data;
    })
  }

  selectedImgFile(event:any)
  {
     this.file= event.target.files[0];
     let splits=this.file.name.split(".");
     this.fileType=splits[1];
    if (this.file) {
      if( this.fileType=="jpeg" || this.fileType=="jpg")
     {
       this.fileErr=false;
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (e: any) => {
        this.base64String=e.target.result;
        this.base64String= this.fileType == "png" ? this.base64String.replace("data:image/png;base64,",""): this.fileType=="jpg"? this.base64String.replace("data:image/jpeg;base64,","") : this.base64String.replace("data:image/jpeg;base64,","");
        this.imageUrl = e.target.result;
        // this.compressImage(this.base64String,  200, 200).subscribe(compressed => {
        //   this.base64String = compressed;
        // });
        this.updateProfile();
      };
    }else this.fileErr=true;
    }
  }
getuserProfileImg()
{
  this.dashboardService.getuserProfileImg().subscribe((res:MyResponse)=>
    {

      this.imageUrl=res?.data.profileImageURL;

    });

}
  updateProfile()
  {
    let body = {
      imageFormat:'.'+this.fileType,
      imageBase64:this.base64String
    }
    this.dashboardService.updateProfilePhoto(body).subscribe(res=>{
      this.openMessageDialog("Profile updated successfully.",false,'')
    })
  }
  compressImage(src, newX, newY) {
    return  Observable.create(observer => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            const elem = document.createElement('canvas');
            elem.width = newX;
            elem.height = newY;
            const ctx = elem.getContext('2d');
            ctx.drawImage(img, 0, 0, newX, newY);
            const data = ctx.canvas.toDataURL();
            observer.next(data);
        }
       // img.onerror = error => rej(error);
    })
}
  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
interface MyResponse {
  data: {
    profileImageURL: string;
    // other properties...
  };
  // other properties...
}