
import { SupportService } from 'src/app/service/support.service';
import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild,Inject,Renderer2, Pipe, PipeTransform } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  MatDialog, MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import { HttpClient } from '@angular/common/http';
import { RegistrationService } from 'src/app/service/registration.service';
import { TokenService } from 'src/app/service/token.service';
import { PdfserviceService } from 'src/app/service/pdfservice.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  pdfName: string="Terms and Conditions.pdf";
  defaultToken: any;
  index: number;
  pdfHtml: any;
  pdfContent: SafeHtml;
  pdfType: string;
  @ViewChild('pdf') pdf: ElementRef;
  @ViewChild('header') header: ElementRef;
  isGrade: boolean;
  studentDetails: any;
  city: any;
  base64String: string | ArrayBuffer;
  istnc:boolean =false;
  isBBC: boolean = false;
  noDataAvailable: boolean = false;
  constructor(private dialog: MatDialog,private router: Router,private renderer: Renderer2,
     @Inject(DOCUMENT) private document: Document,private http: HttpClient,
  public supportservice:SupportService,private tokenService: TokenService,
  private pdfservice:PdfserviceService,private sanitizer: DomSanitizer,public regservice:RegistrationService, private activatedRoute: ActivatedRoute) { }
  pdfs:any[]=[];
  @ViewChild('downloadContent') downloadContent: ElementRef;
  pdfSrc="";
  pdfSrc_s="";
  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'rm-body-height');
    var root = document.getElementsByTagName( 'html' )[0];
    this.renderer.addClass(root, 'rm-body-height');
    this.studentDetails= JSON.parse(localStorage.getItem("parentChildrens"))[0];
    this.city = JSON.parse(localStorage.getItem("schoolDetails"))?.city;
   // let transportData=JSON.parse(localStorage.getItem("transportChildInfo"));
   // this.studentDetails= transportData.find(x=>x.stU_ID == data.stuId);
    // this.isGrade= (this.studentDetails?.grade.includes('1') && !this.studentDetails?.grade.includes('10') && !this.studentDetails?.grade.includes('11') && !this.studentDetails?.grade.includes('12') ) || (this.studentDetails?.grade.includes('2') && !this.studentDetails?.grade.includes('12')) || this.studentDetails?.grade.includes('3') || this.studentDetails?.grade.includes('KG')? true : false

    this.activatedRoute.queryParams.subscribe(params=>{
     if(params?.isBBC){
      this.isBBC=params.isBBC;
     }
    })

    this.getSchoolsList();

  }

  getTermsConditions()
  {
    this.supportservice.getTermsandConditions().subscribe(res=>{
       // this.pdfs = res?.data
       let pdfData = [];
       let parentPdfCount= res?.data.filter(y=>y.ttcuL_DOC_TYPE == 'PUT')?.length;
       res?.data.forEach(x=>{
       if(x.ttcuL_DOC_TYPE == 'PUT' && parentPdfCount > 1)
       {
          this.isGrade=x.ttcuL_bShowSiblingInput;
         let gradeCheck= (x?.ttcuL_TYPE?.includes('1') && !x?.ttcuL_TYPE?.includes('10') && !x?.ttcuL_TYPE?.includes('11') && !x?.ttcuL_TYPE?.includes('12')) || (x?.ttcuL_TYPE?.includes('2') && !x?.ttcuL_TYPE?.includes('12')) || x?.ttcuL_TYPE?.includes('3') || x?.ttcuL_TYPE?.includes('KG') ? true : false;
         if((gradeCheck && this.isGrade) || (!gradeCheck && !this.isGrade)) 
         pdfData.push(x)
       }else pdfData.push(x)
       })

       if(this.isBBC){
        this.pdfs = pdfData.filter(x=>x.ttcuL_DOC_TYPE == 'BBC');
       }else{
        this.pdfs = pdfData.filter(x=>x.ttcuL_DOC_TYPE == 'TNC') //To show only t&c
        this.istnc=true;
       }
         
       if(this.pdfs.length == 0){
        this.noDataAvailable = true;
       }

        this.pdfs.forEach(x=>x.isAgree=false);
        this.loadPdf(0);
    })
  }
  loadPdf(index:number)
  {
    this.pdfSrc=this.pdfs[index].ttcul_Url_Path
    this.pdfSrc_s=this.pdfSrc.replace("http://","https://");
    this.pdfName=this.pdfs[index].ttcul_Url_Text
    this.pdfHtml=this.pdfs[index].ttcuL_PDF_HTML;
    this.index=index;
  }
  agree()
  {
    this.pdfs[this.index].isAgree =true;
    this.index++;
    if(this.index <= (this.pdfs.length - 1))
    {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' 
    });
      this.loadPdf(this.index)
    }
    else{
     let falseIndex = this.pdfs.findIndex(x=>x.isAgree == false)
      if(falseIndex == -1)
      this.router.navigate(['/support']);
      else  this.router.navigate(['/support']);
    }
  }
  
  goToPage()
  {
    this.router.navigate(['/support'])
  }

  // convertToBase64(filePath: string) {
  //   fetch(filePath)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         this.base64String = reader.result;
  //       };
  //       reader.readAsDataURL(blob);
  //     })
  // }
  pdfDownload()
  {
    var domain=this.regservice.appUrl
    var pdfLogo = (this.city == "Dubai" || this.city == "Qatar")? domain+"/assets/images/sts-logo.png": domain+"/assets/images/bbt-logo.png";
    var content:any;
      var headerOption= {
        tableAutoSize:true,
        imagesByReference:true
      }
      var pdftitle = (this.city == "Dubai" || this.city == "Qatar") ? "School Transport Services LLC":"Bright Bus Transport"
      var headerContent= `<table style="width: 100%">
      <tr>
        <td style="width:80px;height:40px;border-right:1px solid black;text-align:center"><img style="width:40px;height:40px" src=${pdfLogo}></td>
        <td style="font-weight:bold;font-size: 18px;text-align:center;margin-top:10px">${pdftitle}</td>
      </tr>
      <tr>
        <td style="border-right:1px solid #fff"></td>
      <td style="font-weight:bold;font-size: 18px;text-align:center;margin-top:5px;margin-bottom:5px;">${this.pdfName}</td>
      </tr>
      </table>`
     if(this.header && this.header.nativeElement && this.pdfType=='PUT')
     {
      content =headerContent+this.removeMetadata(this.header.nativeElement.innerHTML)+this.removeMetadata(this.pdf.nativeElement.innerHTML);
      
     }
     else
     {
       content =headerContent+this.removeMetadata(this.pdf.nativeElement.innerHTML);
     }
      
   var html = htmlToPdfmake(content,headerOption);  
   // else var html = htmlToPdfmake(this.pdf.nativeElement.innerHTML);  
    const documentDefinition = { content:html.content,images:html.images };
    pdfMake.createPdf(documentDefinition).download(this.pdfName); 
  }

  removeMetadata(html: string): string {
    const metadataRegex = /<\s*(meta|title|link|style|script)[^>]*>.*?<\/\s*\1\s*>/gi;
    const nonContentRegex = /<\s*(head|script|style)[^>]*>.*?<\/\s*\1\s*>/gi;

    html = html.replace(metadataRegex, '');
    html = html.replace(nonContentRegex, '');
    return html;
  }
   downloadPdf()
   {
    this.pdfservice.downloadPdf(this.defaultToken,this.pdfSrc,this.pdfName);
  //   //this.http.get(this.pdfSrc, { responseType: 'blob' }).subscribe((blob: Blob) => {
  //     const blob = new Blob([this.pdfSrc], { type: 'application/pdf' });
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = this.pdfName;
  //     link.click();
  //  // });

  }
  get sanitizedPdfHtml(): SafeHtml { 
    this.pdfContent=this.sanitizer.bypassSecurityTrustHtml(this.pdfHtml ?? '');
    return this.pdfContent }
  getSchoolsList(){
    this.supportservice.getSchoolList().subscribe(
      res=>{
        
        let schooldetails:any;
        let currentUser:any;
        currentUser = JSON.parse(localStorage.getItem('currentUser'));

schooldetails= res?.filter(x=>x.bsU_ID == currentUser.schooId)
      
        localStorage.setItem('schoolList',JSON.stringify(schooldetails));
        this.getTermsConditions();

      }
    )
   }
}
