import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceinfoModule } from './serviceinfo.module';
import {
  AuthenticationService,
  CommonService,
  ServiceinfoService
} from 'src/app/service';

import { FormGroup, FormControl } from '@angular/forms';
@Component({
  selector: 'app-serviceinfo',
  templateUrl: './serviceinfo.component.html',
  styleUrls: ['./serviceinfo.component.scss']
})
export class ServiceinfoComponent implements OnInit {
  digopen: any;
  school: string = "";
  childerns: any[] = [];
  pickuppoints: [];
  selectedchild: any[];
  otherDropoffArea: boolean = false;
  otherPickupArea: boolean = false;
  @ViewChild('tptRequest', { static: false }) tptRequest: TemplateRef<any>;
  constructor(
    private serviceinfoservice: ServiceinfoService, private dialog: MatDialog
  ) { }
  public ServiceInfo$ = new Observable<any>(null);
  ngOnInit(): void {
    this.ServiceInfo$ = this.serviceinfoservice.getServiceInfo().pipe(map((x) =>

      x.data));

  }
  OpenTransportRequest(item) {
    this.childerns = JSON.parse(localStorage.parentChildrens);
    this.selectedchild = this.childerns.filter(function (child) {
      return child.studentId == item.studentNo;
    });
    this.school = this.selectedchild[0].school;
    this.getMasers();
    this.openDialog(this.tptRequest);
  }
  openDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      disableClose: true,
      width: '800px'
    });

  }
  closedialog() {
    this.digopen.close();
  }
  getMasers() {
    let obj: any = {
      headers: {
        "master": "AREA_M_TPT",
        "param1": this.selectedchild[0].acdId.toString(),
        "acdid":localStorage.getItem("currentAcademicYearId"),
        "bsuid": this.selectedchild[0].bsuId,


      }

    }
    this.serviceinfoservice.getMaster(obj).subscribe(res => {
      this.pickuppoints = res["data"];
    });
  }
  PickupAreaChange(val) {

    if (val.value == "0") {
      this.otherPickupArea = true;
    }
    else {
      this.otherPickupArea = false;
    }
  }
  DropoffAreaChange(val) {

    if (val.value == "0") {
      this.otherDropoffArea = true;
    }
    else {
      this.otherDropoffArea = false;
    }
  }

  SaveTransportRequest() {
    // let obj: {
    //     "ServiceStartDate": "2022/09/04",
    //     "AcdId": this.


    // };
    let obj: any = {

      "StudentNo": this.selectedchild[0].studentId.toString(),
      "ServiceStartDate": "2022/09/04",
      "AcdId": this.selectedchild[0].acdId.toString(),
      "TripTypeId": "1"

    }


    this.serviceinfoservice.SaveTransportRequest(obj).subscribe(res => {
      let r = res;
    });
  }
}
