import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';
import { AuthenticationService } from '.';  

import { Token } from '../models';
import { TokenService } from './token.service';

import { switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class UaepassService {
  appUrl: any;
  constructor(public tokenservice:TokenService, private http: HttpClient,private configService:ConfigService,public authService:AuthenticationService) { 
    this.appUrl=this.configService.appUrl;
  }
  
getUserDetails(up_access_code: any): Observable<any> {
  return this.tokenservice.GetToken("").pipe(
    switchMap(tokenResult => {
      const token = tokenResult.access_token;
      let headers = new HttpHeaders()
        .set('up_access_code', up_access_code)
        .set('Language', "En")
        .set('Authorization', 'Bearer ' + token)
        .set('redirecturi',this.configService.uaepasscallbackurl)
        .set('source',this.configService.uaepass_source);

      return this.http.get<any>(`${this.configService.baseUrl}/${environment.api.getuaepassuserdetails}`, { headers: headers });
    })
  );
}

  
initateSigning(): Observable<any> {

  return this.tokenservice.GetToken("").pipe(
    switchMap(tokenResult => {
      const token = tokenResult.access_token;
      let headers = new HttpHeaders()
        
        .set('Authorization', 'Bearer ' + token)
        
        .set('source',this.configService.uaepass_source);

      return this.http.post<any>(`${this.configService.baseUrl}/${environment.api.uaepassinitiatesigning}`,"", { headers: headers });
    })
  );
}

processDocumnetSigning(signingurl:any,files:File[]): Observable<any> {

  return this.tokenservice.GetToken("").pipe(
    switchMap(tokenResult => {
      const token = tokenResult.access_token;
      let headers = new HttpHeaders()
        
        .set('Authorization', 'Bearer ' + token)
        .set('signingurl', signingurl)
        .set('source',this.configService.uaepass_source);
        const formData: FormData = new FormData();
        files.forEach(file => {
          formData.append('files', file, file.name);
        });
      return this.http.post<any>(`${this.configService.baseUrl}/${environment.api.processdocumentsigning}`,formData, { headers: headers });
    })
  );
}
DownloadSignedDoc(ret_signed_url:any,signIdentityId:any,txId:any,studentNo:any,schoolID:any,parentUniqueIdentifier:any): Observable<any> {

  return this.tokenservice.GetToken("").pipe(
    switchMap(tokenResult => {
      const token = tokenResult.access_token;
      let headers = new HttpHeaders()
        
        .set('Authorization', 'Bearer ' + token)
        .set('ret_signed_url', ret_signed_url)
        .set('signIdentityId', signIdentityId)
        .set('txId', txId)
        .set('studentNo', studentNo)
        .set('schoolID', schoolID)
        .set('parentUniqueIdentifier', parentUniqueIdentifier)
        .set('source',this.configService.uaepass_source);
        
        
      return this.http.post<any>(`${this.configService.baseUrl}/${environment.api.downloadsigneddoc}`,"", { headers: headers });
    })
  );
}


}
