import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SupportService } from 'src/app/service/support.service';

@Component({
  selector: 'app-view-service-request',
  templateUrl: './view-service-request.component.html',
  styleUrls: ['./view-service-request.component.scss']
})
export class ViewServiceRequestComponent implements OnInit {
  allServiceRequests: any[] = [];
  isDataNotAvailable: boolean = false;

  constructor(public supportService: SupportService) { }

  ngOnInit(): void {
    this.getDetails();
  }

  getDetails() {
    this.isDataNotAvailable = false;
    this.supportService.getAllServiceRequests().subscribe((res: any) => {

      if (res?.data == null || res?.data?.length == 0) {
        this.isDataNotAvailable = true;
      }

      this.allServiceRequests = res?.data
    }, (err: HttpErrorResponse) => {
      this.isDataNotAvailable = true;
    })
  }
}
