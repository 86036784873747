import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageComponent } from 'src/app/message/message.component';
import { areaChangeService } from 'src/app/service/areaChange.service';

@Component({
  selector: 'app-request-area-change',
  templateUrl: './request-area-change.component.html',
  styleUrls: ['./request-area-change.component.scss']
})
export class RequestAreaChangeComponent implements OnInit {
  getAreaChangeDetails:any[]=[];
  isPending: boolean=false;
  token: any;
  isDataNotAvailable: boolean=false;

  constructor(public areaChangeService:areaChangeService,public router:Router,private dialog: MatDialog) { }
  
  ngOnInit(): void {
   this.getDetails();
  }

  getDetails()
  {
    this.isDataNotAvailable = false;
    this.token = JSON.parse(localStorage.getItem('currentToken'))?.access_token
    this.areaChangeService.getAreaChange(this.token).subscribe((res:any)=>{

      if(res?.data == null || res?.data?.length == 0){
        this.isDataNotAvailable = true;
      }

      res?.data?.forEach(x=>{
        x.date = x.tcH_REQDATE.replaceAll('/',' ')
        x.effDate=x.appR_STATUS=='Approved'?x.tcH_APRDATE?.replaceAll('/',' '): x.tcH_STARTDATE?.replaceAll('/',' ')
        
      })
    this.getAreaChangeDetails=res?.data
    if(this.getAreaChangeDetails)
    {
    let index=this.getAreaChangeDetails.findIndex(x=>x?.appR_STATUS == 'PENDING');
    this.isPending= index == -1 ? false :true
    }else this.isPending=false;
    }, (err: HttpErrorResponse) => {
      this.isDataNotAvailable = true;
    })
  }

  navigate()
  {
    this.router.navigate(['/request-area-change-info'])
  }
  
  cancel(id:any)
  {
    this.areaChangeService.deleteAreaChange(id,this.token).subscribe(res=>{
      this.openMessageDialog("Deleted successfully.",false,'')
    })
  }

  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDetails();
    });
  }
}
