import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class contactChangeService {
    constructor(private http: HttpClient,private configService:ConfigService) { }

    postContactChange(body:any,token:any)
  {
    const Header = {
      Authorization: `Bearer ${token}`,
    };
    return this.http.post(`${this.configService.baseUrl}/api/Transport/ContactChangeRequest`,body,{ headers: Header })
  }
}