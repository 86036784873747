import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ServiceinfoService } from 'src/app/service';
import { TokenService } from 'src/app/service/token.service';
import { MapsAPILoader } from '@agm/core'
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { FormGroup, Validators } from '@angular/forms';
import { FormControl } from 'ngx-typesafe-forms';
import { RegistrationService } from 'src/app/service/registration.service';
import { MessageComponent } from 'src/app/message/message.component';
import {
  MatDialog
} from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { GoogleMapsAutocompleteService } from 'src/app/service/google-maps-autocomplete-service.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-residential-information',
  templateUrl: './residential-information.component.html',
  styleUrls: ['./residential-information.component.scss'],
  providers: [DatePipe]
})
export class ResidentialInformationComponent implements OnInit {
  pickuppoints: any[] = [];
  pickupareapoints: any[] = [];
  dropoffareapints: any[] = [];
  selectedpickuparea1: any = '';
  selecteddropofarea2: any = '';
  tptFee: any[] = [];
  selectedpickuparea: string = "";
  selecteddropoffarea: string = "";
  transportTypes: any[] = [];
  emirates: any[] = [];
  isPickupOnly: boolean = false;
  isDropOnly: boolean = false;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  mapZoom = 12;
  mapCenter: google.maps.LatLng;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 4,
  };

  markerInfoContent = '';
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    animation: google.maps.Animation.DROP,
  };
  residentialsDetails!: FormGroup;
  registerationdetails: any = {};
  token: any = '';
  @Output() onTabChange: EventEmitter<any> = new EventEmitter();
  date: any;
  mapOpenedFrom: string = '';
  tptType: any;
  IsCloseFee: boolean = false;
  pickupAreaList: any[] = [];
  pickupPointList: any[] = [];
  dropoffpoints: any[] = [];
  dropoffareapoints: any[] = [];
  geoCoder: google.maps.Geocoder;
  pickuplocation: string = '';
  dropofflocation: string = '';
  markers1: any;
  searchInput: string = '';   predictions: google.maps.places.AutocompletePrediction[] = [];
  countryDetails: any;
  ishibrid:string="false";
  isLeased:string="false";
  dropoffAreaErrMsg: boolean=false;
  @Input() isReactivate: boolean = false;
  constructor(private tokenService: TokenService, private serviceinfoService: ServiceinfoService, private registrationService: RegistrationService, private dialog: MatDialog, private datePipe: DatePipe,private autocompleteService:GoogleMapsAutocompleteService) { }

  ngOnInit(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    this.getMasers();

    let data: any;
    data = JSON.parse(localStorage.getItem('parentDetails'));
    this.registerationdetails = data;
    this.countryDetails = JSON.parse(localStorage.getItem('schoolDetails'));
    this.ishibrid = localStorage.getItem('ishybrid');
    this.isLeased = localStorage.getItem('isLeased');
    this.residentialsDetails = new FormGroup({
      EMIRATE: new FormControl('', Validators.required),
      HOUSE_NO: new FormControl('', Validators.required),
      ADDRESS: new FormControl('', Validators.required),
      TPT_TYPE: new FormControl(null, Validators.required),
      PICKUPAREA: new FormControl(null),
      DROPOFFAREA: new FormControl(null),
      PICKUPPOINT: new FormControl(null),
      DROPOFFPOINT: new FormControl(null),
      PICKUPLOCATION: new FormControl(''),
      DROPOFFLOCATION: new FormControl(''),
      PICKUPPOINTLANDMARK: new FormControl(''),
      DROPOFFPOINTLANDMARK: new FormControl(''),
    });
    this.geoCoder = new google.maps.Geocoder;

    if (this.isReactivate) {
      this.ishibrid = JSON.parse(localStorage.getItem('IsHybrid')) == 0 ? "false" : "true";
      this.isLeased = JSON.parse(localStorage.getItem('leasemodelschool')) == 0 ? "false" : "true";
    }

  }
  getPickupPoints() {

    this.pickupareapoints = [];
    this.dropoffareapoints = [];
    this.pickupAreaList = [];
    let obj3: any = {
      headers: {
        "master": "PICKUP_TPT_R",
        "bsuid": localStorage.getItem('bsU_ID'),
        "param1": "172",
        "param2": this.selectedpickuparea1?.toString(),
        "Authorization": "Bearer " + this.token
      }
    }
    this.serviceinfoService.getMaster(obj3).subscribe(res => {
      this.pickupareapoints = res["data"];
      this.dropoffareapoints = res["data"];
      this.pickupAreaList = res["data"];
      //alert(this.pickupareapoints);
    })
  }
  getDropOffPoints() {
    this.dropoffareapints = [];
    let obj3: any = {
      headers: {
        "master": "DROP_TPT_R",
        "bsuid": localStorage.getItem('bsU_ID'),
        "param1": "172",
        "param2": this.selecteddropofarea2?.toString(),
        "Authorization": "Bearer " + this.token
      }
    }
    this.serviceinfoService.getMaster(obj3).subscribe(res => {
      this.dropoffareapints = res["data"];
      //alert(this.pickupareapoints);
    })
  }

  getMasers() {
    let tkn = "";
    this.tokenService.GetToken("").subscribe(tknresult => {
      this.token = tknresult.access_token;
      tkn = tknresult.access_token;
      if (this.registerationdetails[0]?.EMIRATE != '') {

        this.selecteddropofarea2 = this.registerationdetails[0]?.dropoffArea
        this.selectedpickuparea1 = this.registerationdetails[0]?.pickupArea
      }

      let obj1: any = {
        headers: {
          "master": "TPT_TYPE",
          "bsuid": localStorage.getItem('bsU_ID').toString(),
          "Authorization": "Bearer " + tkn
        }
      }
      let obj2: any = {
        headers: {
          "master": "TPT_CITY_M",
          "param1": "172",
          "bsuid": localStorage.getItem('bsU_ID'),
          "Authorization": "Bearer " + tkn
        }
      }









        this.serviceinfoService.getMaster(obj1).subscribe(res => {

          this.transportTypes = res["data"]?.filter(x => x.id != -1).map(x => ({ descr: x.descr, id: x.id.toString() }));
          this.transportTypes.unshift({
            descr: 'Select',
            id: null
          })


          this.serviceinfoService.getMaster(obj2).subscribe(res => {

            this.emirates = res["data"];
            this.emirates.unshift({
              descr: 'Select',
              id: null
            })
            if (this.registerationdetails[0]?.EMIRATE != '') {

              this.getAreaBasedOnCity(this.registerationdetails[0]?.city);

              this.residentialsDetails.patchValue({
                EMIRATE: this.registerationdetails[0]?.city,
                HOUSE_NO: this.registerationdetails[0]?.house,
                ADDRESS: this.registerationdetails[0]?.street,
                TPT_TYPE: this.registerationdetails[0]?.transportType,
                PICKUPAREA: this.registerationdetails[0]?.pickupArea == "-1" ? -1 : Number(this.registerationdetails[0]?.pickupArea),
                DROPOFFAREA: this.registerationdetails[0]?.dropoffArea == "-1" ? -1 : Number(this.registerationdetails[0]?.dropoffArea),
                PICKUPPOINT: Number(this.registerationdetails[0]?.pickupPoint),
                DROPOFFPOINT: Number(this.registerationdetails[0]?.dropoffPoint),
                PICKUPLOCATION: this.registerationdetails[0]?.PICKUPLOCATION,
                DROPOFFLOCATION: this.registerationdetails[0]?.DROPOFFLOCATION,
                PICKUPPOINTLANDMARK: this.registerationdetails[0]?.pickupPointLandMark,
                DROPOFFPOINTLANDMARK: this.registerationdetails[0]?.dropoffPointLandMark,
              });

              this.latitude = this.registerationdetails[0]?.picklat
              this.longitude = this.registerationdetails[0]?.picklog
              this.latitudeDrop = this.registerationdetails[0]?.droplat
              this.longitudeDrop = this.registerationdetails[0]?.droplog
              if(this.latitude!=0 || this.longitude!=0){
                this.PickupgetAddress(this.latitude,this.longitude)
              }else{
                this.getCurrentLocation('pick')
              }
              if(this.latitudeDrop!=0 || this.longitudeDrop!=0){
                this.DropoffgetAddress(this.latitudeDrop,this.longitudeDrop)
              }else{
                this.getCurrentLocation('drop')

              }
              if (this.registerationdetails[0]?.pickupArea != "") {
                this.selectedpickuparea = this.pickupPointList.filter(x => x.id == this.registerationdetails[0]?.pickupArea)[0]?.descr;

              }
              if (this.registerationdetails[0]?.dropoffArea != "") {
                this.selecteddropoffarea = this.pickupPointList.filter(x => x.id == this.registerationdetails[0]?.dropoffArea)[0]?.descr;

              }
              this.getFeeStructure()
              let tptdata = [];
              tptdata = this.transportTypes.filter(x => x.id == this.registerationdetails[0]?.transportType)
              this.tptType=tptdata[0]?.id
              if (tptdata.length > 0) {
                if (tptdata[0]?.id == 0) {
                  this.isPickupOnly = true;
                  this.isDropOnly = true;
                }
                else if (tptdata[0]?.id == 1) {
                  this.isPickupOnly = true;
                  this.isDropOnly = false;
                }
                else if (tptdata[0]?.id == 2) {
                  this.isPickupOnly = false;
                  this.isDropOnly = true;
                }
                else {
                  this.isPickupOnly = true;
                  this.isDropOnly = true;
                }
              }

              //this.getOthersDetails();
              this.getPickupPoints();
              this.getDropOffPoints();
              console.log(this.residentialsDetails.value);

            } else {
              this.residentialsDetails = new FormGroup({
                EMIRATE: new FormControl('', Validators.required),
                HOUSE_NO: new FormControl('', Validators.required),
                ADDRESS: new FormControl('', Validators.required),
                TPT_TYPE: new FormControl(null, Validators.required),
                PICKUPAREA: new FormControl(null),
                DROPOFFAREA: new FormControl(null),
                PICKUPPOINT: new FormControl(null),
                DROPOFFPOINT: new FormControl(null),
                PICKUPLOCATION: new FormControl(''),
                DROPOFFLOCATION: new FormControl(''),
                PICKUPPOINTLANDMARK: new FormControl(''),
                DROPOFFPOINTLANDMARK: new FormControl(''),
              });
              this.isPickupOnly = true;
              this.isDropOnly = true;
            }
          });
        });
    });
  }
  next() {
    let data: any;
    data = JSON.parse(localStorage.getItem('parentDetails'))
    let formData: any[] = [];
    data.forEach(ele => {
      formData.push({
        "stU_ID": ele?.stU_ID ?? 0,
        "stU_FIRSTNAME": ele?.stU_FIRSTNAME ?? '',
        "stU_MIDNAME": ele?.stU_MIDNAME ?? '',
        "stU_LASTNAME": ele?.stU_LASTNAME ?? '',
        "stU_GENDER": ele?.stU_GENDER ?? '',
        "stU_GRD_ID": ele?.stU_GRD_ID ?? 0,
        "stU_GRM_ID": ele?.stU_GRM_ID ?? 0,
        "stU_DOB": ele?.stU_DOB ?? '',
        "stU_SCHOOL_ID": ele?.stU_SCHOOL_ID ?? '',
        "stU_PHOTOPATH": ele?.stU_PHOTOPATH ?? '',
        "stS_MED": ele?.STS_MED ?? 0,
        "stU_MED_CONDITION": ele?.STU_MED_CONDITION ?? '',
        "stS_MED_STAFF_REQUIRED": ele?.STS_MED_STAFF_REQUIRED ?? 0,
        "stS_MED_STAFF_REQUIRED_DESC": ele?.STS_MED_STAFF_REQUIRED_DESC ?? '',
        "stU_PRIMARYCONTACT": ele?.stU_PRIMARYCONTACT ?? '',
        "stS_FFIRSTNAME": ele?.stS_FFIRSTNAME ?? '',
        "stS_FMIDNAME": ele?.stS_FMIDNAME ?? '',
        "stS_FLASTNAME": ele?.stS_FLASTNAME ?? '',
        "stS_FMOBILE": ele?.stS_FMOBILE ?? '',
        "stS_FEMAIL": ele?.stS_FEMAIL ?? '',
        "stS_MFIRSTNAME": ele?.stS_MFIRSTNAME ?? '',
        "stS_MMIDNAME": ele?.stS_MMIDNAME ?? '',
        "stS_MLASTNAME": ele?.stS_MLASTNAME ?? '',
        "stS_MMOBILE": ele?.stS_MMOBILE ?? '',
        "stS_MEMAIL": ele?.stS_MEMAIL ?? '',
        "stS_GFULLNAME": ele?.stS_GFULLNAME ?? '',
        "stS_GMOBILE": ele?.stS_GMOBILE ?? '',
        "stS_GEMAIL": ele?.stS_GEMAIL ?? '',
        "stU_SECONDARY_CONTACT": ele?.stU_SECONDARY_CONTACT ?? '',
        "disableRegistration": 0,
        "city": this.residentialsDetails.value.EMIRATE,
        "house": this.residentialsDetails.value.HOUSE_NO,
        "street": this.residentialsDetails.value.ADDRESS,
        "transportType": this.residentialsDetails.value.TPT_TYPE,
        "pickupArea": this.residentialsDetails.value.PICKUPAREA,
        "pickupPoint": this.residentialsDetails.value.PICKUPPOINT,
        "pickupPointLandMark": this.residentialsDetails.value.PICKUPPOINTLANDMARK,
        "dropoffArea": this.residentialsDetails.value.DROPOFFAREA,
        "SBL_ID_DROPOFF": this.residentialsDetails.value.DROPOFFAREA ?? 0,
        "PNT_ID_DROPOFFPOINT": this.residentialsDetails.value.DROPOFFPOINT ?? 0,
        "dropoffPoint": this.residentialsDetails.value.DROPOFFPOINT,
        "dropoffPointLandMark": this.residentialsDetails.value.DROPOFFPOINTLANDMARK,
        "busServiceRequestedDate": ele?.busServiceRequestedDate ?? '',
        "picklat": this.latitude,
        "picklog": this.longitude,
        "droplat": this.latitudeDrop,
        "droplog": this.longitudeDrop,
        "stU_BSU_ID": ele?.stU_BSU_ID ?? '',
      })
    })

    let jsonString = JSON.stringify(formData);
    localStorage.setItem("parentDetails", jsonString);
    this.onTabChange.emit(3);
  }
  checkTPTType(value) {
    let data: any[];
    data = this.transportTypes.filter(x => x.id == value);
    this.tptType = data[0].id
    if (data[0].id == 0) {
      this.isPickupOnly = true;
      this.isDropOnly = true;
      this.residentialsDetails.controls['PICKUPAREA'].setValidators([Validators.required]);
      this.residentialsDetails.controls['PICKUPPOINT'].setValidators([Validators.required]);
      this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFAREA'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFPOINT'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].setValidators([Validators.required]);
      this.residentialsDetails.patchValue({
        PICKUPPOINT: '',
        PICKUPAREA: '',
        PICKUPPOINTLANDMARK: '',
        DROPOFFAREA: '',
        DROPOFFPOINTLANDMARK: '',
        DROPOFFPOINT: ''
      })
    }
    if (data[0].id == 1) {
      this.isPickupOnly = true;
      this.isDropOnly = false;
      this.residentialsDetails.controls['PICKUPAREA'].setValidators([Validators.required]);
      this.residentialsDetails.controls['PICKUPPOINT'].setValidators([Validators.required]);
      this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFAREA'].clearValidators();
      this.residentialsDetails.controls['DROPOFFPOINT'].clearValidators();
      this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].clearValidators();
    }
    if (data[0].id == 2) {
      this.isPickupOnly = false;
      this.isDropOnly = true;
      this.residentialsDetails.controls['DROPOFFAREA'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFPOINT'].setValidators([Validators.required]);
      this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].setValidators([Validators.required]);
      this.residentialsDetails.controls['PICKUPAREA'].clearValidators();
      this.residentialsDetails.controls['PICKUPPOINT'].clearValidators();
      this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].clearValidators();
    }
    this.residentialsDetails.controls['DROPOFFAREA'].markAsUntouched();
    this.residentialsDetails.controls['DROPOFFAREA'].markAsPristine();
    this.residentialsDetails.controls['DROPOFFPOINT'].markAsUntouched();
    this.residentialsDetails.controls['DROPOFFPOINT'].markAsPristine();
    this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].markAsUntouched();
    this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].markAsPristine();
    this.residentialsDetails.controls['PICKUPAREA'].markAsUntouched();
    this.residentialsDetails.controls['PICKUPAREA'].markAsPristine();
    this.residentialsDetails.controls['PICKUPPOINT'].markAsUntouched();
    this.residentialsDetails.controls['PICKUPPOINT'].markAsPristine();
    this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].markAsUntouched();
    this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].markAsPristine();
    this.residentialsDetails.patchValue({
      PICKUPPOINT: '',
      PICKUPAREA: '',
      PICKUPPOINTLANDMARK: '',
      DROPOFFAREA: '',
      DROPOFFPOINTLANDMARK: '',
      DROPOFFPOINT: ''
    })
    this.selectedpickuparea1 = null
    this.selecteddropofarea2 = null
    this.selectedpickuparea = '';
    this.selecteddropoffarea = '';
    this.getOthersDetails()

  }
  openInfoWindow(marker: MapMarker) {
    // this is called when the marker is clicked.
    this.infoWindow.open(marker);
  }

  getCurrentLocation(value) {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {


        const point: google.maps.LatLngLiteral = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        if(value == 'pick'){
          this.center1 = point;
          // this.dropofflocation= data[0].descr
            this.markers = [];
            // Add a marker for the selected location
            this.markers.push({position: this.center1,label: '',title: '',options: { animation: google.maps.Animation.DROP
           }
           });
           this.PickupgetAddress(position.coords.latitude,position.coords.longitude)
        }else{
          this.center2 = point;
          // this.dropofflocation= data[0].descr
            this.markers1 = [];
            // Add a marker for the selected location
            this.markers1.push({position: this.center2,label: '',title: '',options: { animation: google.maps.Animation.DROP
           }
           });
           this.DropoffgetAddress(position.coords.latitude,position.coords.longitude)
        }
        console.log(point);
        this.mapCenter = new google.maps.LatLng(point);
        this.map.panTo(point);

        this.markerInfoContent = "I'm here!";

        this.markerOptions = {
          draggable: false,
          animation: google.maps.Animation.DROP,
        };
      },
      (error) => {


        if (error.PERMISSION_DENIED) {
          // alert("Couldn't get your location Permission denied");
        } else if (error.POSITION_UNAVAILABLE) {
          // alert(
          //   "Couldn't get your location Position unavailable"
          // );
        } else if (error.TIMEOUT) {
          // alert("Couldn't get your location 'Timed out'");
        } else {
          // alert(error.message);
        }
      },
      { enableHighAccuracy: true }
    );
  }
  checkPickupArea(name,event)
  {
    if (this.tptType == 0) {
      if(this.residentialsDetails.controls['PICKUPAREA'].value !=''){
        this.residentialsDetails.patchValue({
          'DROPOFFAREA': this.residentialsDetails.controls['PICKUPAREA'].value
        })
        this.getGeoLocation(this.residentialsDetails.controls['PICKUPAREA'].value,name,event);
      }
      else {
        this.residentialsDetails.patchValue({
          'DROPOFFAREA': ''
        })
      }
    }
  }
  saveAsDraft() {

    let data: any;
    data = JSON.parse(localStorage.getItem('parentDetails'))
    let formData: any[] = [];
    data.forEach(ele => {
      formData.push({
        "stU_ID": ele?.stU_ID ?? 0,
        "stU_FIRSTNAME": ele?.stU_FIRSTNAME ?? '',
        "stU_MIDNAME": ele?.stU_MIDNAME ?? '',
        "stU_LASTNAME": ele?.stU_LASTNAME ?? '',
        "stU_GENDER": ele?.stU_GENDER ?? '',
        "stU_GRD_ID": ele?.stU_GRD_ID ?? 0,
        "stU_GRM_ID": ele?.stU_GRM_ID ?? 0,
        "stU_DOB": ele?.stU_DOB ?? '',
        "stU_SCHOOL_ID": ele?.stU_SCHOOL_ID ?? '',
        "stU_PHOTOPATH": ele?.stU_PHOTOPATH ?? '',
        "stS_MED": ele?.STS_MED ?? 0,
        "stU_MED_CONDITION": ele?.STU_MED_CONDITION ?? '',
        "stS_MED_STAFF_REQUIRED": ele?.STS_MED_STAFF_REQUIRED ?? 0,
        "stS_MED_STAFF_REQUIRED_DESC": ele?.STS_MED_STAFF_REQUIRED_DESC ?? '',
        "stU_PRIMARYCONTACT": ele?.stU_PRIMARYCONTACT ?? '',
        "stS_FFIRSTNAME": ele?.stS_FFIRSTNAME ?? '',
        "stS_FMIDNAME": ele?.stS_FMIDNAME ?? '',
        "stS_FLASTNAME": ele?.stS_FLASTNAME ?? '',
        "stS_FMOBILE": ele?.stS_FMOBILE ?? '',
        "stS_FEMAIL": ele?.stS_FEMAIL ?? '',
        "stS_MFIRSTNAME": ele?.stS_MFIRSTNAME ?? '',
        "stS_MMIDNAME": ele?.stS_MMIDNAME ?? '',
        "stS_MLASTNAME": ele?.stS_MLASTNAME ?? '',
        "stS_MMOBILE": ele?.stS_MMOBILE ?? '',
        "stS_MEMAIL": ele?.stS_MEMAIL ?? '',
        "stS_GFULLNAME": ele?.stS_GFULLNAME ?? '',
        "stS_GMOBILE": ele?.stS_GMOBILE ?? '',
        "stS_GEMAIL": ele?.stS_GEMAIL ?? '',
        "stU_SECONDARY_CONTACT": ele?.stU_SECONDARY_CONTACT ?? '',
        "disableRegistration": 0,
        "city": this.residentialsDetails.value.EMIRATE,
        "house": this.residentialsDetails.value.HOUSE_NO,
        "street": this.residentialsDetails.value.ADDRESS,
        "transportType": this.residentialsDetails.value.TPT_TYPE,
        "pickupArea": this.residentialsDetails.value.PICKUPAREA,
        "pickupPoint": this.residentialsDetails.value.PICKUPPOINT,
        "pickupPointLandMark": this.residentialsDetails.value.PICKUPPOINTLANDMARK,
        "dropoffArea": this.residentialsDetails.value.DROPOFFAREA,
        "SBL_ID_DROPOFF": this.residentialsDetails.value.DROPOFFAREA,
        "PNT_ID_DROPOFFPOINT": this.residentialsDetails.value.DROPOFFPOINT,
        "dropoffPoint": this.residentialsDetails.value.DROPOFFPOINT,
        "dropoffPointLandMark": this.residentialsDetails.value.DROPOFFPOINTLANDMARK,
        "busServiceRequestedDate": ele?.busServiceRequestedDate ?? '',
        "picklat": this.latitude,
        "picklog": this.longitude,
        "droplat": this.latitudeDrop,
        "droplog": this.longitudeDrop,
        "stU_BSU_ID": ele?.stU_BSU_ID ?? '',

      })
    })
    let jsonString = JSON.stringify(formData);
    localStorage.setItem("parentDetails", jsonString);
    let senddata: any = {};
    senddata = {
      "MobileNumber": JSON.parse(localStorage.studentDetails).stS_FMOBILE,
      "StuDetails": jsonString
    }
    this.registrationService.studentSaveAsDraft(senddata, this.token).subscribe(
      res => { this.openMessageDialog("Request saved successfully.", false, ""); }
    )
  }
  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.goToLogin()
    });
  }
  previous() {
    let data: any;
    data = JSON.parse(localStorage.getItem('parentDetails'))
    let formData: any[] = [];
    data.forEach(ele => {
      formData.push({
        "stU_ID": ele?.stU_ID ?? 0,
        "stU_FIRSTNAME": ele?.stU_FIRSTNAME ?? '',
        "stU_MIDNAME": ele?.stU_MIDNAME ?? '',
        "stU_LASTNAME": ele?.stU_LASTNAME ?? '',
        "stU_GENDER": ele?.stU_GENDER ?? '',
        "stU_GRD_ID": ele?.stU_GRD_ID ?? 0,
        "stU_GRM_ID": ele?.stU_GRM_ID ?? 0,
        "stU_DOB": ele?.stU_DOB ?? '',
        "stU_SCHOOL_ID": ele?.stU_SCHOOL_ID ?? '',
        "stU_PHOTOPATH": ele?.stU_PHOTOPATH ?? '',
        "stS_MED": ele?.STS_MED ?? 0,
        "stU_MED_CONDITION": ele?.STU_MED_CONDITION ?? '',
        "stS_MED_STAFF_REQUIRED": ele?.STS_MED_STAFF_REQUIRED ?? 0,
        "stS_MED_STAFF_REQUIRED_DESC": ele?.STS_MED_STAFF_REQUIRED_DESC ?? '',
        "stU_PRIMARYCONTACT": ele?.stU_PRIMARYCONTACT ?? '',
        "stS_FFIRSTNAME": ele?.stS_FFIRSTNAME ?? '',
        "stS_FMIDNAME": ele?.stS_FMIDNAME ?? '',
        "stS_FLASTNAME": ele?.stS_FLASTNAME ?? '',
        "stS_FMOBILE": ele?.stS_FMOBILE ?? '',
        "stS_FEMAIL": ele?.stS_FEMAIL ?? '',
        "stS_MFIRSTNAME": ele?.stS_MFIRSTNAME ?? '',
        "stS_MMIDNAME": ele?.stS_MMIDNAME ?? '',
        "stS_MLASTNAME": ele?.stS_MLASTNAME ?? '',
        "stS_MMOBILE": ele?.stS_MMOBILE ?? '',
        "stS_MEMAIL": ele?.stS_MEMAIL ?? '',
        "stS_GFULLNAME": ele?.stS_GFULLNAME ?? '',
        "stS_GMOBILE": ele?.stS_GMOBILE ?? '',
        "stS_GEMAIL": ele?.stS_GEMAIL ?? '',
        "stU_SECONDARY_CONTACT": ele?.stU_SECONDARY_CONTACT ?? '',
        "disableRegistration": 0,
        "city": this.residentialsDetails.value.EMIRATE,
        "house": this.residentialsDetails.value.HOUSE_NO,
        "street": this.residentialsDetails.value.ADDRESS,
        "transportType": this.residentialsDetails.value.TPT_TYPE,
        "pickupArea": this.residentialsDetails.value.PICKUPAREA,
        "pickupPoint": this.residentialsDetails.value.PICKUPPOINT,
        "pickupPointLandMark": this.residentialsDetails.value.PICKUPPOINTLANDMARK,
        "dropoffArea": this.residentialsDetails.value.DROPOFFAREA,
        "SBL_ID_DROPOFF": this.residentialsDetails.value.DROPOFFAREA ?? 0,
        "PNT_ID_DROPOFFPOINT": this.residentialsDetails.value.DROPOFFPOINT ?? 0,
        "dropoffPoint": this.residentialsDetails.value.DROPOFFPOINT,
        "dropoffPointLandMark": this.residentialsDetails.value.DROPOFFPOINTLANDMARK,
        "busServiceRequestedDate": ele?.busServiceRequestedDate ?? '',
        "picklat": this.latitude,
        "picklog": this.longitude,
        "droplat": this.latitudeDrop,
        "droplog": this.longitudeDrop,
        "stU_BSU_ID": ele?.stU_BSU_ID ?? '',
      })
    })

    let jsonString = JSON.stringify(formData);
    localStorage.setItem("parentDetails", jsonString);
    this.onTabChange.emit(1);
  }

  eventHandler(event: any, name: string) {

    // Add marker on double click event
    if (name === 'mapDblclick' || name === 'mapClick') {
      this.dropMarker(event)
    }
  }
  @ViewChild('myGoogleMap', { static: false })
  map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false })
  info!: MapInfoWindow;
  zoom = 20;
  maxZoom = 20;
  minZoom = 3;
  latitude: number = 0;
  longitude: number = 0;
  latitudeDrop: number = 0;
  longitudeDrop: number = 0;
  center!: google.maps.LatLngLiteral;
  center1!: google.maps.LatLngLiteral;
  center2!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'hybrid',
    maxZoom: this.maxZoom,
    minZoom: this.minZoom,
  }
  markers = [] as any;
  infoContent = ''
  dropMarker(event: any) {
    if (this.mapOpenedFrom == 'pick') {

      this.latitude = event.latLng.lat();
      this.longitude = event.latLng.lng();
      this.PickupgetAddress(this.latitude, this.longitude)
      if (this.tptType == 0) {
        this.latitudeDrop = this.latitude;
        this.longitudeDrop = this.longitude;

      }
      this.markers = [];
      this.markers.push({
        position: {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        },
        label: {
          color: 'blue',
          text: 'Marker label ' + (this.markers.length + 1),
        },
        title: 'Marker title ' + (this.markers.length + 1),
        info: 'Marker info ' + (this.markers.length + 1),
        options: {
          animation: google.maps.Animation.DROP,
        },
      })
      this.center1={ lat: this.latitude, lng: this.longitude };
    } else {
      this.latitudeDrop = event.latLng.lat();
      this.longitudeDrop = event.latLng.lng();
      this.markers1 = [];
      this.markers1.push({
        position: {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        },
        label: {
          color: 'blue',
          text: 'Marker label ' + (this.markers1.length + 1),
        },
        title: 'Marker title ' + (this.markers1.length + 1),
        info: 'Marker info ' + (this.markers1.length + 1),
        options: {
          animation: google.maps.Animation.DROP,
        },
      })
      this.center2={ lat: this.latitudeDrop, lng: this.longitudeDrop };

      this.DropoffgetAddress(this.latitudeDrop, this.longitudeDrop)
    }




  }
  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker)
  }
  zoomIn() {
    if (this.zoom < this.maxZoom) this.zoom++;
  }

  zoomOut() {
    if (this.zoom > this.minZoom) this.zoom--;
  }

  getGeoLocation(value, name, event) {
    console.log(value, event);

    let data: any = [];
    data.push({
      SBLID: this.residentialsDetails.value.PICKUPAREA,
    });
    // if(this.tptType == "Round Trip"){

    //   this.residentialsDetails.patchValue({
    //     'DROPOFFAREA':this.residentialsDetails.controls['PICKUPAREA'].value
    //   })
    // }

    // else{
    //   this.residentialsDetails.patchValue({
    //     'DROPOFFAREA':''
    //   })
    // }
    if (name == 'parea') {
      this.selectedpickuparea = "Pick-up Area: " + event.source.triggerValue;
      this.selectedpickuparea1 = value;
      let data :any[]=[];
      data = this.pickuppoints.filter(x=>x.id == value);
      console.log(data);
      if(value == -1){
        if((data[0].lat == 0||data[0].lat == null ) && (data[0].long == 0 || data[0].long== null )){
          this.getCurrentLocation('pick');
        }else{
          this.center1 = { lat: Number(data[0].lat), lng: Number(data[0].long) };
        }
  
        this.pickuplocation= data[0].descr
              this.markers = [];
              // Add a marker for the selected location
              this.markers.push({position: this.center1,label: '',title: data[0].descr,options: { animation: google.maps.Animation.DROP
             }
             });
      }
     
      this.getOthersDetails()
      this.getPickupPoints();
    }
    if (name == 'darea') {
      if(this.tptType == 0)
      {
        if(this.residentialsDetails.controls['PICKUPAREA'].value == this.residentialsDetails.controls['DROPOFFAREA'].value)
        this.dropoffAreaErrMsg=false
        else this.dropoffAreaErrMsg=true
      }
      this.selecteddropoffarea = "Drop-off Area: " + event.source.triggerValue;

      this.selecteddropofarea2 = value;
      let data :any[]=[];
      data = this.pickuppoints.filter(x=>x.id == value);
      console.log(data);
      if(value == -1){
        if((data[0].lat == 0||data[0].lat == null ) && (data[0].long == 0 || data[0].long== null )){
          this.getCurrentLocation('drop');
        }else{
        this.center2 = { lat: Number(data[0].lat), lng: Number(data[0].long) };
        this.dropofflocation= data[0].descr
              this.markers1 = [];
              // Add a marker for the selected location
              this.markers1.push({position: this.center2,label: '',title: data[0].descr,options: { animation: google.maps.Animation.DROP
             }
             });
        }
      }
     
      this.getOthersDetails()
      this.getDropOffPoints();
    }
    if (value != null && value != -1)
      this.registrationService.getGeoLocation(this.token, data).subscribe(res => {
        if (name == 'parea') {

          this.residentialsDetails.controls['PICKUPLOCATION'].setValue(`${res?.data?.sbL_LAT} , ${res?.data?.sbL_LONG}`)
        }
        else {
          this.selecteddropofarea2 = value;
          this.residentialsDetails.controls['DROPOFFLOCATION'].setValue(`${res?.data?.sbL_LAT} , ${res?.data?.sbL_LONG}`)

        }

      })
    if (this.residentialsDetails.value.PICKUPAREA! = this.residentialsDetails.value.DROPOFFAREA) {
      this.residentialsDetails.patchValue({
        'DROPOFFPOINT': ''
      });
      this.residentialsDetails.controls['DROPOFFPOINT'].setValidators([Validators.required]);
    } else {
      this.residentialsDetails.patchValue({
        'DROPOFFPOINT': this.residentialsDetails.controls['PICKUPPOINT'].value
      })
    }
  }
  getFeeStructure() {
    let provider: any;
    provider = JSON.parse(localStorage.getItem('schoolDetails'))
    let data: any = [];
    data.push({
      TRIPTYPE: this.residentialsDetails.value.TPT_TYPE ?? 0,
      SBLID: Number(this.residentialsDetails.value.PICKUPAREA ?? 0),
      DSBLID: Number(this.residentialsDetails.value.DROPOFFAREA ?? 0),
      STU_ID: 0,
      PROVIDER_BSU_ID: provider.provider,
      TRM_STARTDT: '',
      SSV_STARTDT: '',
    })
    this.registrationService.getFeeAmounts(this.token, data).subscribe(res => {
      //alert(JSON.stringify(res));
      this.tptFee = res.data;

      this.tptFee = this.tptFee.filter((x:any) => x.type == 2);

      if (this.tptFee.length > 0) {
        this.IsCloseFee = true
      } else {
        this.IsCloseFee = false

      }
      console.log(res);

    })
  }
  openMap(template: TemplateRef<any>, value: any) {
    this.mapOpenedFrom = value;
    // if(value == 'pick'){
    //   this.markers = [];
    //   this.markers.push({
    //     position: {
    //       lat: this.latitude,
    //       lng: this.longitude,
    //     },
    //     label: {
    //       color: 'blue',
    //       text: 'Marker label ' + (this.markers.length + 1),
    //     },
    //     title: 'Marker title ' + (this.markers.length + 1),
    //     info: 'Marker info ' + (this.markers.length + 1),
    //     options: {
    //       animation: google.maps.Animation.DROP,
    //     },
    //   })
    // }else{
    //   this.markers1 = [];
    //   this.markers1.push({
    //     position: {
    //       lat: this.latitudeDrop,
    //       lng: this.latitudeDrop,
    //     },
    //     label: {
    //       color: 'blue',
    //       text: 'Marker label ' + (this.markers.length + 1),
    //     },
    //     title: 'Marker title ' + (this.markers.length + 1),
    //     info: 'Marker info ' + (this.markers.length + 1),
    //     options: {
    //       animation: google.maps.Animation.DROP,
    //     },
    //   })
    // }
    this.dialog.open(template, {
      disableClose: true,
      width: '1040px',
      panelClass: ['custom-modalbox', 'mapPopup']
    });
  }

  close() {

    this.dialog.closeAll();
    this.searchInput = '',
    this.predictions=[];
  }
  checkPickupPoint(event:MatSelectChange) {
    if (this.tptType == 0) {
      localStorage.setItem("regselecteddroparea", event.source.triggerValue);
      this.residentialsDetails.patchValue({
        'DROPOFFPOINT': this.residentialsDetails.controls['PICKUPPOINT'].value
      })
      if (this.residentialsDetails.value.PICKUPAREA == this.residentialsDetails.value.DROPOFFAREA) {
        this.residentialsDetails.patchValue({
          'DROPOFFPOINT': this.residentialsDetails.controls['PICKUPPOINT'].value
        })

      } else {
        this.residentialsDetails.patchValue({
          'DROPOFFPOINT': ''
        });
        this.residentialsDetails.controls['DROPOFFPOINT'].setValidators([Validators.required]);

      }
    } else {
      this.residentialsDetails.patchValue({
        'DROPOFFPOINT': ''
      })
    }


  }
  closeFee() {
    this.IsCloseFee = false
  }

  clearAreaSearch(type: any){
    if(type == 'pickupArea'){
      this.pickuppoints = this.pickupPointList;
    }else if(type == 'dropoffArea'){
      this.dropoffpoints = this.pickupPointList;
    }
  }

  clearPointSearch(type:any){
    if(type == 'pickupPoint'){
      this.pickupareapoints = this.pickupAreaList;
    }else if(type == 'dropoffPoint'){
      this.dropoffareapints = this.pickupAreaList;
    }
  }

  clearSearchValue(event:any){
    event.target.value = '';
  }

  onKey(value) {
    this.pickuppoints = this.search(value);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    if (filter != '') {
      return this.pickuppoints.filter(option => option.descr.toLowerCase().startsWith(filter));
    } else {
      return this.pickuppoints = this.pickupPointList
    }
    console.log(this.pickuppoints.filter(option => option.descr.toLowerCase().startsWith(filter)));

  }
  onKeyPoint(value) {
    this.dropoffpoints = this.searchPoint(value);
  }
  searchPoint(value: string) {
    let filter = value.toLowerCase();
    if (filter != '') {
      return this.dropoffpoints.filter(option => option.descr.toLowerCase().startsWith(filter));
    } else {
      return this.dropoffpoints = this.pickupPointList
    }
    console.log(this.pickuppoints.filter(option => option.descr.toLowerCase().startsWith(filter)));

  }
  onkeypickarea(value) {
    this.pickupareapoints = this.searchpickarea(value);
  }
  searchpickarea(value: string) {
    let filter = value.toLowerCase();
    if (filter != '') {
      return this.pickupareapoints.filter(option => option.descr.toLowerCase().startsWith(filter));
    } else {
      return this.pickupareapoints = this.pickupAreaList
    }
    console.log(this.pickuppoints.filter(option => option.descr.toLowerCase().startsWith(filter)));

  }
  onKeyArea(value) {
    this.dropoffpoints = this.searchArea(value);
  }
  onchangedropoff(event:MatSelectChange)
  {
   localStorage.setItem("regselecteddroparea", event.source.triggerValue);
  }
  searchArea(value: string) {
    let filter = value.toLowerCase();
    if (filter != '') {
      return this.dropoffpoints.filter(option => option.descr.toLowerCase().startsWith(filter));
    } else {
      return this.dropoffpoints = this.pickupAreaList
    }
    console.log(this.pickuppoints.filter(option => option.descr.toLowerCase().startsWith(filter)));

  }

  onkeyDropOffPoint(value) {
    this.dropoffareapints = this.searchDropOffPoints(value);
  }
  searchDropOffPoints(value: string) {
    let filter = value.toLowerCase();
    if (filter != '') {
      return this.dropoffareapints.filter(option => option.descr.toLowerCase().startsWith(filter));
    } else {
      return this.dropoffareapints = this.pickupAreaList
    }
  }

  getOthersDetails() {
    if (!this.isDropOnly || (this.isPickupOnly && this.isDropOnly)) {
      if (this.selectedpickuparea1 == -1) {
        this.residentialsDetails.controls['PICKUPPOINT'].clearValidators();
        this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].setValidators([Validators.required]);
        this.latitude = 0;
        this.longitude = 0;
        this.residentialsDetails.patchValue({
          PICKUPPOINT: ''
        })
      } else {
        this.residentialsDetails.controls['PICKUPPOINT'].setValidators([Validators.required]);
        this.residentialsDetails.controls['PICKUPPOINTLANDMARK'].clearValidators();
        this.residentialsDetails.patchValue({
          PICKUPPOINTLANDMARK: ''
        })
        this.latitude = 0;
        this.longitude = 0;
      }
    }
    if (!this.isPickupOnly || (this.isPickupOnly && this.isDropOnly)) {
      if (this.selecteddropofarea2 == -1) {
        this.residentialsDetails.controls['DROPOFFPOINT'].clearValidators();
        this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].setValidators([Validators.required]);
        this.latitudeDrop = 0;
        this.longitudeDrop = 0;
        this.residentialsDetails.patchValue({
          DROPOFFPOINT: ''
        })
      } else {
        this.residentialsDetails.controls['DROPOFFPOINT'].setValidators([Validators.required]);
        this.residentialsDetails.controls['DROPOFFPOINTLANDMARK'].clearValidators();
        this.residentialsDetails.patchValue({
          DROPOFFPOINTLANDMARK: ''
        })
        this.latitudeDrop = 0;
        this.longitudeDrop = 0;
      }
    }
  }
  handlePanelClick(event: Event) {
    event.stopPropagation();
  }

  onEmiratesChange() {
    let cityId = this.residentialsDetails.value.EMIRATE;

    this.residentialsDetails.patchValue({
      PICKUPAREA: '',
      DROPOFFAREA: '',
      PICKUPPOINT: '',
      DROPOFFPOINT: '',
      PICKUPLOCATION: '',
      DROPOFFLOCATION: '',
      PICKUPPOINTLANDMARK: '',
      DROPOFFPOINTLANDMARK: ''
    });
    this.selectedpickuparea1 = null;
    this.selecteddropofarea2 = null;
    this.latitude = 0;
    this.longitude = 0;
    this.latitudeDrop = 0;
    this.longitudeDrop = 0;
    this.getAreaBasedOnCity(cityId);
  }

  getAreaBasedOnCity(cityId:any){

    if(cityId != ""){
      let obj: any = {
        headers: {
          "master": "AREA_M_TPT_R",
          "param1": cityId.toString(),
          "bsuid": localStorage.getItem('bsU_ID'),
          "acdid": localStorage.getItem("currentAcademicYearId") ?? '0',
          "Authorization": "Bearer " + this.token
        }
      }
      this.serviceinfoService.getMaster(obj).subscribe(res => {
  
        this.pickuppoints = res["data"];
        this.dropoffpoints = res["data"];
        this.pickupPointList = res["data"];
        this.pickuppoints?.unshift({
          descr: 'Select',
          id: null
        })
      });
    }
  }

  PickupgetAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 20;

          this.pickuplocation = results[0].formatted_address
          this.center1 = {lat:latitude,lng:longitude};
          // this.dropofflocation= data[0].descr
            this.markers = [];
            // Add a marker for the selected location
            this.markers.push({position: this.center1,label: '',title: '',options: { animation: google.maps.Animation.DROP
           }
           });
          // window.alert(results[0].formatted_address);
        } else {
          //  window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });

  }
  DropoffgetAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 20;

          this.dropofflocation = results[0].formatted_address
          localStorage.setItem("regselecteddroparea", this.dropofflocation);
          this.center2 = {lat:latitude,lng:longitude};
          // this.dropofflocation= data[0].descr
            this.markers1 = [];
            // Add a marker for the selected location
            this.markers1.push({position: this.center2,label: '',title: '',options: { animation: google.maps.Animation.DROP
           }
           });
          //  window.alert(results[0].formatted_address);
        } else {
          //window.alert('No results found');
        }
      } else {
        //window.alert('Geocoder failed due to: ' + status);
      }

    });

  }
  onInputChange() {
    if (this.searchInput.length >= 3) {
      this.autocompleteService.getPlacePredictions(this.searchInput).subscribe(predictions => {
        this.predictions = predictions;
      });
    } else {
      this.predictions = [];
    }
  }
  onPredictionClick(prediction: google.maps.places.AutocompletePrediction) {
   const geocoder = new google.maps.Geocoder();
   if(this.mapOpenedFrom=='pick'){
    this.pickuplocation = prediction.description
   } else{
    this.dropofflocation = prediction.description
    localStorage.setItem("regselecteddroparea", this.dropofflocation);
   }
   geocoder.geocode({ placeId: prediction.place_id }, (results, status) => {
           if (status === google.maps.GeocoderStatus.OK) {
            // Update the map center and clear existing markers
            if(this.mapOpenedFrom == 'pick'){
              this.center1 = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
              this.markers = [];
              // Add a marker for the selected location
              this.markers.push({position: this.center1,label: '',title: prediction.description,options: { animation: google.maps.Animation.DROP
             }
             });
             this.latitude = results[0].geometry.location.lat(),
             this.longitude = results[0].geometry.location.lng()
             this.searchInput =  prediction.description

             this.predictions=[];
            } else{
              this.center2 = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
              this.markers1 = [];
              // Add a marker for the selected location
              this.markers1.push({position: this.center2,label: '',title: prediction.description,options: { animation: google.maps.Animation.DROP
             }
             });
             this.latitudeDrop = results[0].geometry.location.lat(),
             this.longitudeDrop = results[0].geometry.location.lng()
             this.searchInput =  prediction.description
             this.predictions=[];

            }

        }
      });
    }
}

