import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { MessageComponent } from 'src/app/message/message.component';
import { ErrorSupportForm } from 'src/app/models/landing.model';
import { HomeService } from 'src/app/service/home.service';
import { SupportService } from 'src/app/service/support.service';
@Component({
  selector: 'app-add-feedback',
  templateUrl: './add-feedback.component.html',
  styleUrls: ['./add-feedback.component.scss']
})
export class AddFeedbackComponent implements OnInit {
  private rating: number = 0;
  private starCount: number = 5;
  private color: string = 'accent';
  public serviceRequest: Request = new Request();
  errorModel: ErrorSupportForm = new ErrorSupportForm()
  submitform:boolean=false;
  categoryList: any[] = [];
  imgdata: string;
  date: any = new Date();
  invalidFileSize: boolean = false;
  invalidFile: boolean = false;
  public ratingArr = []
  validRating: boolean = false;
  constructor(private route:Router,private supportService: SupportService, private homeService: HomeService, private datePipe: DatePipe, private dialog: MatDialog) { }

  ngOnInit(): void {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
    this.getCategories();
    let email: any;
    email = localStorage.getItem('primaryEmail')
    let studentNo: any;
    studentNo = localStorage.getItem('selectedstudentid')
    this.serviceRequest.email = email;
    this.serviceRequest.studentNo = studentNo;

  }
  showIcon(index:number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
  rate(index: number): void {
    this.rating = index + 1;
    this.checkForRating();
  }
  isStarBorder(index: number): boolean {
    return index >= this.rating;
  }
  optionChange(){
  this.rating=0;
  this.checkForRating();
  }
  cancel() {
    this.serviceRequest = new Request();
    this.route.navigate(['/feedback']);
    this.invalidFileSize=false;
    this.serviceRequest = new Request();
  }
  getCategories() {
    this.supportService.getCategoriesData().subscribe(
      res => {
        console.log(res);
        let data: any;
        data = res?.data.filter(x => x.category == "FEEDBACK");

        let array = data[0]?.datavalues.split(';')
        console.log(array);
        this.categoryList = []
        array.forEach(ele => {
          let data: any;
          data = ele.split(',');
          this.categoryList.push({
            id: data[1],
            value: data[2]
          })
        })
        console.log(this.categoryList);

      }
    )
  }
  handleFileInputChange(files: any) {
    //alert(files.target.files[0]);
    console.log(files.target.files[0]);

    let fileData: any;

    fileData = files.target.files[0];
    let type: any = '';
    type = fileData?.type.split('/')[1];
    let extName: any = '';
    extName = fileData?.name.split('.')[1];
    let filesize: any = '';
    filesize = fileData?.size;
    this.serviceRequest.imgname = fileData?.name;
    if ((type == 'png' || type == 'jpg' || type == 'jpeg') || (extName == 'doc' || extName == 'docx' || extName == 'pdf')) {
      this.invalidFile = false;

    }
    else {
      this.invalidFile = true;
      this.serviceRequest.imgname = '';
      this.serviceRequest.imgname = '';
      this.serviceRequest.imgtype = '';
    }
    if (filesize <= 5242880) {
      this.invalidFileSize = false;
    } else {
      this.invalidFileSize = true;
      this.serviceRequest.imgname = '';
      this.serviceRequest.imgname = '';
      this.serviceRequest.imgtype = '';
    }


    this.serviceRequest.imgname = fileData?.name;
    this.serviceRequest.imgtype = fileData?.type;

    this.convertFile(files.target.files[0]).subscribe(base64 => {
      this.serviceRequest.imgdata = base64;
    });


  }
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }
  submitSupport() {
    this.submitform=true;

    if (this.serviceRequest.details == null || this.serviceRequest.details == '') {
      this.errorModel.IsDescription = true
    } else {
      this.errorModel.IsDescription = false

    }
    if (this.serviceRequest.email == null || this.serviceRequest.email == '') {
      this.errorModel.IsEmail = true
    } else {
      this.errorModel.IsEmail = false

    }
    this.serviceRequest.dtime = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    console.log(this.serviceRequest);
    this.serviceRequest.FeedbackStars=this.rating;
    if (!this.errorModel.IsTitleID && !this.errorModel.IsDescription && !this.errorModel.IsEmail && this.serviceRequest.details.trim() != '' && this.validRating) {
      let token: any;
      token = JSON.parse(localStorage.getItem('currentToken'));
      this.homeService.postComplaint(this.serviceRequest, token?.access_token).subscribe(
        res => {
          if (res.Success == "true") {
            // this._snackBar.open('Request send successfully.', '', {
            //   horizontalPosition: this.horizontalPosition,
            //   verticalPosition: this.verticalPosition,
            //   duration: 2000
            // });

            //this.imageName = '';
            // this.invalidFile = false;
            // this.invalidFileSize = false;
            this.openMessageDialog("Request submitted successfully.", false, "");
          } else {
            // this._snackBar.open('Something went wrong.', '', {
            //   horizontalPosition: this.horizontalPosition,
            //   verticalPosition: this.verticalPosition,
            //   duration: 2000
            // });
            this.openMessageDialog(res?.Message, false, "");

          }

        }
      )
    }

  }
  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      minWidth: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.goToLogin()
      this.cancel();
      this.gotoFeedbackList()
    });
  }
  gotoFeedbackList(){
    this.route.navigateByUrl('/feedback')
  }

  checkForRating(){
    if(this.serviceRequest.titleID == 19 || this.serviceRequest.titleID ==20 || this.serviceRequest.titleID == 48 || this.serviceRequest.titleID == 52){
      this.validRating = this.rating > 0;
    }else{
      this.validRating = true;
    }
  }
}


export class Request {
  constructor(
    public titleID: any = '',
    public details: any = '',
    public imgdata: any = '',
    public imgname: any = '',
    public imgtype: any = '',
    public dtime: any = '',
    public email: any = '',
    public studentNo: string = "0",
    public refID: any = 1,
    public comments: any = null,
    public contactID: any = 2,
    public feedbackID: any = null,
    public smiley: any = null,
    public FeedbackStars:any=0
  ) { }
}