import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchoolsListComponent } from './schools-list/schools-list.component';
import { SchoolBusRegistrationRoutingModule } from './school-bus-registration-routing.module';
import {MatIconModule} from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SchoolbusRegistrationComponent } from './schoolbus-registration/schoolbus-registration.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import { ParentInformationComponent } from './schoolbus-registration/parent-information/parent-information.component';
import { StudentInformationComponent } from './schoolbus-registration/student-information/student-information.component';
import { ResidentialInformationComponent } from './schoolbus-registration/residential-information/residential-information.component';
import { TermsAndConditionsComponent } from './schoolbus-registration/terms-and-conditions/terms-and-conditions.component';
import { ViewTermsAndConditionsComponent } from './schoolbus-registration/terms-and-conditions/view-terms-and-conditions/view-terms-and-conditions.component';
import {MatRadioModule} from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { GoogleMapsModule } from '@angular/google-maps';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { MatDialogModule } from '@angular/material/dialog';
import { AgmCoreModule } from '@agm/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { MatTooltipModule} from '@angular/material/tooltip';
import { SafePipe } from 'src/app/core/enctypt-decrytp.pipe';
import { ReactivateStudentInformationComponent } from './schoolbus-registration/reactivate-student-information/reactivate-student-information.component';
import { CommonModulesModule } from 'src/app/common-modules/common-modules.module';
import { UaepassCallbackComponent } from './schoolbus-registration/terms-and-conditions/uaepass-callback/uaepass-callback.component';
import { PreventArabicDirective } from 'src/app/models/prevent-arabic.directive';

@NgModule({
  declarations: [
    SchoolsListComponent,
    SchoolbusRegistrationComponent,
    ParentInformationComponent,
    StudentInformationComponent,
    ReactivateStudentInformationComponent,
    ResidentialInformationComponent,
    TermsAndConditionsComponent,
    ViewTermsAndConditionsComponent,
    SafePipe,
    UaepassCallbackComponent,
    PreventArabicDirective
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule, MatCardModule,MatTabsModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    GoogleMapsModule,Ng2TelInputModule,
    MatDialogModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA1iYoEmhopACwr8vAOLOO3KSOHvMERD-E',
      libraries: ['places']
    }),
    PdfViewerModule,
    NgxMatIntlTelInputModule,
    CommonModulesModule
  ],
  exports: [
    SchoolBusRegistrationRoutingModule
  ]
})
export class SchoolBusRegistrationModule { }
