import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sts-offers',
  templateUrl: './sts-offers.component.html',
  styleUrls: ['./sts-offers.component.scss']
})
export class StsOffersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
