export class ParentRegistration {
    constructor(
        public STU_ID: number = 0,
        public STU_FIRSTNAME: string = '',
        public STU_MIDNAME: string = '',
        public STU_LASTNAME: string = '',
        public STU_GENDER: string = '',
        public STU_GRD_ID: string = '',
        public STU_DOB: string = '',
        public STU_SCHOOL_ID: string = '',
        public STU_PHOTOPATH: string = '',
        public STU_PRIMARYCONTACT: string = '',
        public STS_FFIRSTNAME: string = '',
        public STS_FMIDNAME: string = '',
        public STS_FLASTNAME: string = '',
        public STS_FMOBILE: string = '',
        public STS_FEMAIL: string = '',
        public STS_MFIRSTNAME: string = '',
        public STS_MMIDNAME: string = '',
        public STS_MLASTNAME: string = '',
        public STS_MMOBILE: string = '',
        public STS_MEMAIL: string = '',
        public STS_GFULLNAME: string = '',       
        public STS_GMOBILE: string = '',
        public STS_GEMAIL: string = '',
        public DisableRegistration: number = 0,
        public City: string = '',
        public House: string = '',
        public Street: string = '',
        public TransportType: string = '',
        public PickupArea: string = '',
        public PickupPoint: string = '',
        public PickupPointLandMark: string = '',
        public DoopoffArea: string = '',
        public DropoffPoint: string = '',
        public DropoffPointLandMark: string = '',
        public BusServiceRequestedDate: string = '',
    ) { }
}

export class PrimaryDetails{
    constructor(
        public primaryRelationShip:any='',
        public primaryFname:string='',
        public primaryMname:string='',
        public primaryLname:string='',
        public primaryMobile:string='',
        public primaryEmail:string='',
        public primaryReEmail:string='',
        public secondaryRelationShip:any='',
        public secondaryFname:string='',
        public secondaryMname:string='',
        public secondaryLname:string='',
        public secondaryMobile:string='',
        public otherRelationShip:any='',
        public otherFullName:string='',
        public otherMobile:string='',
    ){}
}

export class SecondaryDetails{
    constructor(
        public secondaryRelationShip:any='',
  public secondaryFname:string='',
  public secondaryMname:string='',
  public secondaryLname:string='',
  public secondaryMobile:string='',
  public otherRelationShip:any='',
  public otherFullName:string='',
  public otherMobile:string='',
    ){}
}


export class OtherDetails{
    constructor(
  public otherRelationShip:any='',
  public otherFullName:string='',
  public otherMobile:string='',
    ){}
}


export class studentDetail
{
    public firstName:string;
    public lastName:string;
    public middleName:string;
    public gender:string;
    public grade:string;
    public dob:string;
    public photopath:string;
    public medicalRadiobtn:boolean;
    public medicalDesc:string;
    public medicalRadiobtn2:boolean;
    public medicalDesc2:string;
    public schoolId:string;
    public studentId:string;
    public academicYr:string;
    public serviceDate:string
}

export class termsAgreed {
    constructor(
        public checkboxOne: boolean = false,
        public checkboxTwo: boolean = false,
        public checkboxThree: boolean = false
    ) { }
}