import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TransportserviceService } from '../../transportservice.service'
import { TransportrequestModule } from './transportrequest.module'
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from 'src/app/service/token.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { ConfigService } from 'src/app/service/config.service';
@Component({
  selector: 'app-transportrequest',
  templateUrl: './transportrequest.component.html',
  styleUrls: ['./transportrequest.component.scss']
})


export class TransportrequestComponent implements OnInit {
  portalShortCode:string =this.configService.portalShortCode;
  logoimage:string=this.configService.logoimageUrl;
  schools: any;
  city: string = ""
  digopen: any;
  messagetext: string = "Do you currently use our school bus services?";
  constructor(private trnsrequest: TransportserviceService, private trn: MatListModule, private route: ActivatedRoute,
    private tokenService: TokenService, private router: Router, private dialog: MatDialog
    , private snackBar: MatSnackBar,private configService:ConfigService) { }
  @ViewChild('confirmtemplet', { static: false }) confirmtemplet: TemplateRef<any>;
  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {

        this.city = params.city;

      }
      );
    let tkn = "";
    this.tokenService.GetToken("").subscribe(tknresult => {

      tkn = tknresult.access_token;
      let obj: any = {
        headers: {
          "city": this.city == undefined ? "" : this.city,
          "Language": "En",
          "Authorization": "Bearer " + tkn
        }

      }
      this.trnsrequest.GetNonGemsSchoolsListByCity(obj).subscribe(bsulist => {
        this.schools = bsulist['data'];
      });
    }

    );

  }
  OpenTransportRequest(sName: string, bsuid: string) {

    localStorage.setItem("NGSBsuid", bsuid);
    localStorage.setItem("NGSchoolName", sName);
    this.openDialog(this.confirmtemplet);
    //this.router.navigate(['/onlineregistration']);
  }
  openDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      disableClose: true,
      width: '800px'
    });

  }
  OpenRegForm() {
    this.closedialog();
    this.router.navigate(['/onlineregistration']);
  }
  closedialog() {
    this.digopen.close();
  }
  confirmclosedialog() {
    this.closedialog();
    this.snackBar.open('Thank you for using ' + this.portalShortCode+ ' Transport System! Re-registration is not required', 'Ok', {

      panelClass: ['nt', 'nt_success'],
      horizontalPosition: "center",
      verticalPosition: "top",

    });
  }
}
