import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[reRegRestrictArabic]'
})
export class ReRegPreventArabicDirective {

  // Define a regular expression to match allowed characters including English letters, numerics, space, and special characters
  private allowedRange = /^[a-zA-Z0-9\s<,.'";@~!#%^&()*\-_\|[\]=+{}]*$/;

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement | HTMLTextAreaElement;
    // Remove disallowed characters
    if (!this.allowedRange.test(input.value)) {
      input.value = input.value.replace(/[^a-zA-Z0-9\s<,.'";@~!#%^&()*\-_\|[\]=+{}]/g, '');
      (input as any).dispatchEvent(new Event('input'));
    }
    // Check if the input value is only spaces
    if (/^\s*$/.test(input.value)) {
      // If input is only spaces, clear it
      input.value = '';
      (input as any).dispatchEvent(new Event('input'));
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow backspace, delete, arrow keys, etc.
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter'];
    if (allowedKeys.includes(event.key) || this.allowedRange.test(event.key)) {
      return; // Allow these keys
    }
    event.preventDefault(); // Block other keys
  }
}
