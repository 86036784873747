import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/service/payment.service';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-soa-summary',
  templateUrl: './soa-summary.component.html',
  styleUrls: ['./soa-summary.component.scss']
})
export class SoaSummaryComponent implements OnInit,OnChanges {
  displayedColumns = ['feeDescription', 'debit', 'credit', 'balance'];

 @Input()fromdate: string;
 @Input()todate: string;
 @Output() dates:EventEmitter<any>= new EventEmitter()
  paymentList: any;
  studentId: any;
  digopen: any;
  dataSource: any;
  isOnChange: boolean=false;
  schoolCurrency: string = '';
  summaryPDFSub: Subscription;
  schoolName: string = '';
  studentName: string = '';
  studentGrade: string = '';

  constructor(private datePipe: DatePipe, private paymentService:PaymentService, private router:ActivatedRoute, private dialog:MatDialog) {
   }
  ngOnChanges(): void {
   this.isOnChange=true;
   this.router.queryParams.subscribe(params=>{
    this.studentId = params?.id
  })
   this.getSummaryDetails();
  }

  ngOnInit(): void {
    this.schoolName = localStorage.getItem('schoolName');
    this.schoolCurrency= localStorage.getItem('bsu_currency');
    if(!this.isOnChange)
    {
    this.router.queryParams.subscribe(params=>{
      this.studentId = params?.id
    })
    this.getSummaryDetails()
  }

    let transportChildInfo = JSON.parse(localStorage.getItem("transportChildInfo"));

    let childInfo = transportChildInfo.filter((x: any) => x.studentNo == this.studentId);
    this.studentName = childInfo[0]?.studentName;
    this.studentGrade = childInfo[0]?.grade;

  this.summaryPDFSub = this.paymentService.downloadSummaryPDF.subscribe((res: any) => {
      if (res == "1") {
        this.downloadAsPDF();
      }
    });
  }
  changeServiceDate(event, value) {
    let serviceDate = event.value._d;
    let data: any;
    if (value == 'fdate') {
      this.fromdate = this.datePipe.transform(serviceDate, 'yyyy-MM-dd');
    } else {
      this.todate = this.datePipe.transform(serviceDate, 'yyyy-MM-dd');
    }
  }

  ngOnDestroy(){
    this.summaryPDFSub?.unsubscribe();
  }

  clear() {
    this.todate = '';
    this.fromdate = '';
  }
  getSummaryDetails(){
    let data:any;
    data={
     stuid:this.studentId,
     fromDate:this.fromdate,
     toDate:this.todate
    }
    this.dates.emit(data);
     this.paymentService.getPaymentSummary(data).subscribe(
      res=>{
        this.paymentList = res;
        this.dataSource = this.paymentList;
        console.log('dataSource', this.dataSource)
      }
     )
   }
   getEmailStatement(template: TemplateRef<any>){
    let data:any;
    data={
     stuid:this.studentId,
     fromDate:this.fromdate,
     toDate:this.todate
    }
    this.paymentService.getEmailSummary(data).subscribe(res=>{
      this.successDialog(template)
    })
   }
   successDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      width: '500px',
      panelClass: 'custom-modalbox'
    });

  }

  downloadAsPDF() {
    let data: any;
    data = {
      stuid: this.studentId,
      fromDate: this.fromdate,
      toDate: this.todate,
      studentName: this.studentName,
      studentGrade: this.studentGrade,
      schoolName: this.schoolName
    }
    this.paymentService.downloadStatementOfAccountsSummaryPdf(data).subscribe(
      (res:any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        saveAs(blob, "Summary.pdf");
        this.paymentService.downloadSummaryPDF.next("0");
      }
    )
  }
}
