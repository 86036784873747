import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserprofileupdateService {
  private userProfileImageSource = new BehaviorSubject<string>('/default-profile-image.jpg');
  userProfileImage$ = this.userProfileImageSource.asObservable();
  constructor() { }
  updateUserProfileImage(imageUrl: string) {
    this.userProfileImageSource.next(imageUrl);
  }
  getProfileImageUrl():Observable<any>
  {
    return this.userProfileImageSource.asObservable();
  }
}
