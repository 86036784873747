import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutTopNavModule } from './core/layout-top-nav/layout-top-nav.module';
import { LayoutComponent } from './shared/layout/layout.component';
import { SchoolBusRegistrationModule } from './features/school-bus-registration/school-bus-registration.module';
import { PreloginLayoutComponent } from './shared/prelogin-layout/prelogin-layout.component';
import { BrowserModule } from '@angular/platform-browser';

const modules = [
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  LayoutTopNavModule,
  TranslateModule,
  SchoolBusRegistrationModule
];

@NgModule({
  imports: [...modules,BrowserModule],
  exports: [...modules,PreloginLayoutComponent],
  declarations: [PreloginLayoutComponent],
})

export class SharedModule { }
