import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class areaChangeService {
    constructor(private http: HttpClient,private configService:ConfigService) { }

    getAreaChange(token:any)
    {
      const Header = {
        Authorization: `Bearer ${token}`,
        STUNO: localStorage.getItem("selectedstudentid")
      };
      return this.http.get(`${this.configService.baseUrl}/api/transport/GetStudentRequestAreaChange`,{ headers: Header })
    }

    postAreaChange(body:any,token:any)
  {
    const Header = {
      Authorization: `Bearer ${token}`,
    };
    return this.http.post(`${this.configService.baseUrl}/api/Transport/SaveStudentRequestAreaChange`,body,{ headers: Header })
  }
  deleteAreaChange(body:any,token:any)
  {
    const Header = {
      Authorization: `Bearer ${token}`,
      TCHID:body.toString()
    };
    return this.http.post(`${this.configService.baseUrl}/api/Transport/CancelChangeAreaRequest`,'',{ headers: Header })
  }

  public getFeeAmounts(token, data: any): Observable<any> {
    let bsuId = localStorage.getItem("bsU_ID");
    const header = {
      "Authorization": `Bearer ${token}`,
      "BSU_ID": bsuId.toString(),
      "ACD_ID": data[0].ACD_ID.toString(),
      "TRIPTYPE": data[0].TRIPTYPE.toString(),
      "SBL_ID": data[0].SBLID.toString(),
      "DSBLID": data[0].DSBLID.toString(),
      "STU_ID": data[0].STU_ID.toString(),
      "PROVIDER_BSU_ID": data[0].PROVIDER_BSU_ID.toString(),
      "TRM_STARTDT": data[0].TRM_STARTDT,
      "SSV_STARTDT": data[0].SSV_STARTDT
    }

    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetSTSFeeAmounts`, { headers: header });
  }
  
}