import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HomeComponent } from '../home/home.component';
import { HomeRoutingModule } from './home-routing.module';
import { DashboardWidgetsComponent } from './dashboard-widgets/dashboard-widgets.component';
import { FeePaymentWidgetComponent } from './dashboard-widgets/fee-payment-widget/fee-payment-widget.component';
import { TransportScheduleChangeWidgetComponent } from './dashboard-widgets/transport-schedule-change-widget/transport-schedule-change-widget.component';
import { NewsWidgetComponent } from './dashboard-widgets/news-widget/news-widget.component';
import { ProfileWidgetComponent } from './dashboard-widgets/profile-widget/profile-widget.component';
import { MatCardModule } from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { TextFieldModule} from '@angular/cdk/text-field';
import { ContactsWidgetComponent } from './dashboard-widgets/contacts-widget/contacts-widget.component';
//import { TransportInformationComponent } from './transport-information/transport-information.component';
import { MatButtonModule } from '@angular/material/button';
import { RegisterSiblingWidgetComponent } from './dashboard-widgets/register-sibling-widget/register-sibling-widget.component';
import { GraphWidgetComponent } from './dashboard-widgets/graph-widget/graph-widget.component';
import { TransportDiscontinuationComponent } from './transport-information/transport-discontinuation/transport-discontinuation.component';
import {MatExpansionModule} from '@angular/material/expansion';

import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ReRegistrationComponent } from './transport-information/re-registration/re-registration.component';
import { PaymentsComponent } from './transport-information/payments/payments.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReRegisterTermsAndConditionComponent } from './transport-information/re-registration/re-register-terms-and-condition/re-register-terms-and-condition.component';
import {MatTableModule} from '@angular/material/table';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from 'src/app/core/enctypt-decrytp.pipe';
import { PDFpipe } from './transport-information/pdfpipe.pipe';
import { MatTooltipModule} from '@angular/material/tooltip';
import { ScheduleChangeComponent } from './schedule-change/schedule-change.component';
import { RequestForContactChangeComponent } from './request-for-contact-change/request-for-contact-change.component';
import { GoogleMapsModule } from '@angular/google-maps';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ConfigService } from 'src/app/service/config.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { AutoPromotionComponent } from './auto-promotion/auto-promotion.component';
import { LocalizationService, RTL } from '@progress/kendo-angular-l10n';
import { ReRegPreventArabicDirective } from 'src/app/models/rereg-prevent-arabic.directive';
@NgModule({
  declarations: [HomeComponent, DashboardWidgetsComponent,
    FeePaymentWidgetComponent,
    TransportScheduleChangeWidgetComponent,
    NewsWidgetComponent,
    ProfileWidgetComponent,
    ContactsWidgetComponent,
    RegisterSiblingWidgetComponent,
    GraphWidgetComponent,
    TransportDiscontinuationComponent,
    ReRegistrationComponent,
    PaymentsComponent,
    ReRegisterTermsAndConditionComponent,
    PDFpipe,
    ScheduleChangeComponent,
    RequestForContactChangeComponent,
    AutoPromotionComponent,
    ReRegPreventArabicDirective
   // TransportInformationComponent
  ],

  exports: [HomeRoutingModule],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatDatepickerModule,
    TextFieldModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatTableModule,
    CarouselModule,
    MatTooltipModule,
    GoogleMapsModule,
    NgxMaterialTimepickerModule,
    NgxMatIntlTelInputModule
  ],
  providers:[DatePipe,ConfigService,LocalizationService]
})
export class HomeModule { }
