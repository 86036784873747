import { Component,Inject, OnInit, Renderer2} from '@angular/core';
import { DOCUMENT, formatDate } from '@angular/common';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TransportserviceService } from 'src/app/transportservice.service';
import { FormGroup, FormsModule,FormBuilder, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MessageComponent } from 'src/app/message/message.component';
import * as _moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
const moment = _moment;



@Component({
  selector: 'app-request-schedule-change',
  templateUrl: './request-schedule-change.component.html',
  styleUrls: ['./request-schedule-change.component.scss'],
  providers: [DatePipe]
})
export class RequestScheduleChangeComponent implements OnInit {
  studentId: any;
  schoolId: any;
  grade: any;
  minDate: Date;
  maxDate: Date;
  tominDate: Date;
  enableTodate:boolean=true;
  editData: any;
  section: any;
  constructor(public transportService:TransportserviceService,private _snackBar: MatSnackBar,private fb: FormBuilder,private datePipe: DatePipe, private dialog: MatDialog,private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,public router:Router,
  public activatedRoute:ActivatedRoute) { }

  form: FormGroup;
  fromDt:Date=new Date();
  toDt:Date=new Date();
  fromdate=new FormControl(moment());
  todate=new FormControl(moment());
  remarks:string;
  count=0;
  pickUpOption:string;
  options:any[];
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  sname:string;
  school:string;
  imagePath:string;
  isEdit:boolean=false;
  isInvalidToTime: boolean = false;
  oneWayUser: boolean = true;
  // sname:string=JSON.parse(localStorage.parentChildrens)[0].studentName;
  // school:string=JSON.parse(localStorage.currentUser)[0].schoolName;
  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'rm-body-height');
    var root = document.getElementsByTagName('html')[0];
    this.renderer.addClass(root, 'rm-body-height');

    setTimeout(() => {
      this.minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
      console.log(this.minDate);

      this.fromDt = new Date('');
      this.sname = JSON.parse(localStorage.parentChildrens)[0].studentName;
      this.school = JSON.parse(localStorage.parentChildrens)[0].school;
      this.imagePath = JSON.parse(localStorage.parentChildrens)[0].photoPath;
      this.studentId = JSON.parse(localStorage.parentChildrens)[0].studentId;

      let studentTransportInfo = JSON.parse(localStorage.getItem('transportChildInfo'));
      let selectedStudentId = localStorage.getItem('selectedstudentid');
      let selectedStudentData = studentTransportInfo?.filter((x: any) => x.studentNo == selectedStudentId);

      this.schoolId = selectedStudentData[0]?.stU_SCHOOL_ID;
      this.grade = JSON.parse(localStorage.parentChildrens)[0].grade;
      this.section = JSON.parse(localStorage.parentChildrens)[0].section;

      if (selectedStudentData[0]?.roundTrip?.toLowerCase() == 'roundtrip' || selectedStudentData[0]?.roundTrip?.toLowerCase() == 'two-way') {
        this.oneWayUser = false;
      }
      
      //alert(this.imagePath);
      this.form = this.fb.group({
        fromDt: [this.fromDt, Validators.required],
        remarks: [''],
        pickUpOption: [this.pickUpOption, Validators.required]
      });
      this.getPickupDropOff();
    }, 500);
  }
  getPickupDropOff() {
    this.transportService.getTransportTypes().subscribe(res => {
      this.options = res["data"];

      if (this.oneWayUser) {
        this.options = this.options.filter((x: any) => x.id == 3);
      }

      this.transportService.editSchedule.subscribe(res => {
        if (res) {
          this.isEdit = true;
          this.editData = res;
          let area = this.options.find(x => x.id == this.editData?.rpdJourney)
          this.form.patchValue({
            fromDt: new Date(this.editData?.rpdFromDate),
            remarks: this.editData?.rpdRemarks,
            pickUpOption: area ? area.id : null
          })
        } else {
          this.isEdit = false;
          this.editData = null;
        }
      })
    });
  }

  submitRequestScheduleChange() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      let formattedTime = "T00:00:00";
      let fromDate = this.datePipe.transform(this.fromDt, 'yyyy-MM-dd');
      
      let formData = {
        rpdID: this.isEdit ? this.editData?.rpdID : 0,
        rpdStuID: JSON.parse(localStorage.parentChildrens)[0].studentId,
        rpdFromDate: `${fromDate}${formattedTime}`,
        rpdToDate: `${fromDate}${formattedTime}`,
        rpdRemarks: this.remarks,
        editable: this.isEdit ? "Yes" : "No",
        delete: "No",
        rpdJourney: this.pickUpOption
      }

      this.transportService.RequestSchedulechange(formData).subscribe(res => {
        let res1 = res.data;
        if (res.Success == "true") {
          // this._snackBar.open(res.Message, 'Ok', {
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          //   duration: 5000
          // });

          if (!this.isEdit)
            this.openMessageDialog("Request submitted successfully.", false, "");
          else this.openMessageDialog("Request updated successfully.", false, "");
        }
        else {
          this.openMessageDialog(res?.Message, true, "");
        }
      }

      )

    }
  }

  cancel() {
    this.form.reset();
    this.fromDt = new Date('');
    this.remarks = '',
      this.pickUpOption = '';
    this.count = 0;
    this.minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
    this.enableTodate = true;
    this.fromdate = new FormControl(moment());
    this.router.navigateByUrl('/schedule-change')
  }

  onDescChange() {
    if (this.form.controls.remarks.value && this.form.controls.remarks.value.trim() != "") {
      this.count = this.form.controls.remarks.value.length;
    }
    else {
      this.count = 0
      this.form.patchValue({ remarks: this.form.controls.remarks.value.trim() == "" ? "" : this.form.controls.remarks.value })
    }
  }

  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
      panelClass: 'custom-modalbox',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.goToLogin()
      this.cancel();
    });
  }

  onDateInputed(event, type) {
    if (type == 1) {

      this.tominDate = event.value._d;
      this.fromDt = event.value._d;
      console.log(this.tominDate);
      this.enableTodate = false;
      console.log(this.fromDt, this.toDt)

    } else {
      this.toDt = event.value._d;
    }

    this.onChangeToTime();

    console.log(this.fromDt, this.toDt)
  }

  onOptionChange(){

  }
  
  onChangeToTime() {
    this.isInvalidToTime = false;
    //fromTime
    if (this.form.controls.fromTime.value && this.form.controls.toTime.value) {
      let splitTime = this.form.controls.fromTime.value.split(' ');
      let time = splitTime[0].split(':');
      let hours = parseInt(time[0]);
      let minutes = parseInt(time[1]);
      if (splitTime[1].toUpperCase() == 'PM') {
        hours = (hours == 12) ? 12 : hours + 12;
      } else if (splitTime[1].toUpperCase() == 'AM' && hours == 12) {
        hours = 0;
      }
      let formattedTime = `T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
      let fromDate = this.datePipe.transform(this.fromDt, 'yyyy-MM-dd');

      //ToTime
      let splitToTime = this.form.controls.toTime.value.split(' ');
      let Totime = splitToTime[0].split(':');
      let Tohours = parseInt(Totime[0]);
      let Tominutes = parseInt(Totime[1]);
      if (splitToTime[1].toUpperCase() == 'PM') {
        Tohours = (Tohours == 12) ? 12 : Tohours + 12;
      } else if (splitToTime[1].toUpperCase() == 'AM' && Tohours == 12) {
        Tohours = 0;
      }
      let formattedToTime = `T${Tohours.toString().padStart(2, '0')}:${Tominutes.toString().padStart(2, '0')}:00`;
      let ToDate = this.datePipe.transform(this.toDt, 'yyyy-MM-dd');

      let formattedStartTime = new Date(`${fromDate}${formattedTime}`);
      let formattedEndTime = new Date(`${ToDate}${formattedToTime}`);
      if (formattedEndTime <= formattedStartTime) {
        this.isInvalidToTime = true;
      }
    }
  }

  weekendFilter = (d: Date | null): boolean => {
    if(d != undefined)
    {
      // alert(new Date(d).getDate());
      const day = new Date(d).getDay();
      return day !== 0 && day !== 6;
    }
  }
}
